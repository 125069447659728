import React from 'react';
import { currency } from "../../../utils/currency.utils";

export function ValueColumnFormatter(cellContent, row) {
    const brazilianFormat = () => {
        return currency(row.value);
    };
    return (
        <span className="font-weight-bolder">
            {brazilianFormat(row.value)}
        </span>
    );
}