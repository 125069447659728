import React, { useState } from "react";
import { connect } from "react-redux";
import { PhysicalDocumentForm } from "../PhysicalPerson";
import { LegalDocumentForm } from "../LegalPerson";
import DocumentSuccess from "./DocumentSuccess";

function DocumentFail(props) {
	const { documents, user } = props;
	const [isSend, setBoolean] = useState(false);
		
	return (<>
		{(() => {
			if(isSend) {
				return <DocumentSuccess />
			}

			return (
				<div className="card border-0">
					<div className="card-header text-center border-0">
						<h5>Alguns documentos não foram aprovados</h5>
						<p className="text-muted">Envie novamente</p>
					</div>
					<div className="card-body border-0 ml-14 pt-5">
						{(() => {
							if(user.person_type === 'physical_person') {
								return (<PhysicalDocumentForm setBoolean={setBoolean} documents={documents} />)
							}
							return (<LegalDocumentForm setBoolean={setBoolean} documents={documents} />)
						})()}
					</div>
				</div>
			)
		})()}
	</>);
}

export default connect(
	({ auth, register }) => ({
		user: auth.user
}), null)(DocumentFail)