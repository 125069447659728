import { ENDPOINT } from "../../../../../services/enums/EndPoint";
import ApiServices from "../../../../../services/ApiServices";

const api = ApiServices;

/**
 * Retorna o resumo da movimentação de conta
 * por paginação
 * 
 * @param {number} page 
 */
export function getAllResume(page) {
  return api.get(ENDPOINT.STATEMENT, {
    params: {
      page: page
    }
  });
}
