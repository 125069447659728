import React from 'react';
import { ResumePage } from '../MyAccount/Resume/ResumePage';

export function DashBoard() {
  return (
    <div>
      <ResumePage />
    </div>
  );
}
