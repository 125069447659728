export const ENDPOINT = Object.freeze({
  LOGIN: '/login',

  USER_PROFILE: '/users/profile',
  USER_ADDRESS: '/users/address',
  USER_ADDRESSES: '/users/addresses',
  USER_EMAIL: '/users/email',
  USER_ACCOUNT: '/users/data?include=phone,financialSecurity,limits',
  USER_PASSWORD_RESET: '/users/password/reset',
  USER_DEBITS: '/users/scheduled-debits?include=dates',
  USER_DEBITS_OPERATION: '/users/scheduled-debits',
  USER_DEBITS_INCLUDES: '?include=dates,operation',
  USER_PHONE: '/users/phone',
  USER_ACCOUNT_PROVIDER: '/users/account-on-provider',
  USER_NOTIFICATIONS: '/users/notifications',

  OPERATION_SEND: '/operations',
  OPERATION_RESEND: '/resendCode',

  REGISTER_PERSON: '/users/register',
  REGISTER_LEGAL_PERSON: '/users/register',
  REGISTER_DOCUMENTS: '/financial-securities',
  UPDATE_DOCUMENTS: '/financial-securities/update',

  PHONE_REGISTER: '/phones',
  PHONE_VERIFICATION: '/verify', // phones/:phoneId/verify
  PHONE_RECHARGE: '/phone_recharges',
  PHONE_RESEND_CODE: '/resend-code', // phones/:phoneId/resend-code

  ZIPCODE: '/ceps',

  TENANT_SETTINGS: '/tenant-settings',
  TENANT_MODULES: '/tenant-modules',

  TRANSACTION_SUMMARY: '/users/transactions-summary',
  ACCOUNT_BALANCE: '/accounts/balance',
  DEPOSIT: '/deposits',
  STATEMENT: 'users/statement',
  CARRIERS: '/carriers',

  BILL_PAYMENTS: '/bill_payments',
  BILL_INFO: '/bills',

  BANKS: '/banks',
  FAVOREDS: '/saved_favoreds',

  INTERNAL_TRANSFER: '/internal_transfers',
  EXTERNAL_TRANSFER: '/bank_transfers',
  AUTH_TRANSACTION: '/operations',

  PIX_QR_CODE: '/pix/qrcodes',
  PIX_TRANSFERS: '/pix/transfers',
  PIX_FETCH_KEY: '/pix/keys/fetch-key',
});
