import React from 'react';
import { cnpjMask, cpfMask } from '../../../../../utils/mask_cpf_cnpj.utils';

export function DocumentColumnFormatter(cellContent, row) {
    const formatDocument = (text) => {
        let value = '';
        if (text.length > 11) {
            value = cnpjMask(text);
        } else {
            value = cpfMask(text);
        }

        return value;
    };
    return (
        <span className="font-weight-normal">
            {formatDocument(row.document)}
        </span>
    );
}