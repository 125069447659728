
export const dateFormatted = (value) => {
    var date = new Date(value);
    return date.toLocaleString('pt-BR', { timeZone: 'UTC' }).split(' ')[0];
}

export const dateStringFormatted = (value) => {
    var date = new Date(value);
    return date.getDate()+' '+date.toLocaleString('pt-BR', {
        month: 'short'
    })+' '+date.getFullYear();
}

export const dateTimeFormatted = (value) => {
    var date = new Date(value);
    return date.toLocaleDateString('pt-BR', {
        timeZone: 'UTC',
        hour: '2-digit',
        minute:'2-digit'
    });
}

/**
 * Retorna a data no formato YYYY-MM-DD.
 * 
 * @param { Date } date 
 * 
 * @returns { String }
 */
export const dateByTimestampFormatted = (date) => {
    return [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getDate().toString().padStart(2, '0')
    ].join('-')
}

export const now = () => {
    let date = new Date();
    return [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getDate().toString().padStart(2, '0')
    ].join('-')
}