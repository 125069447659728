import { ENDPOINT } from "../../services/enums/EndPoint";
import ApiServices from "../../services/ApiServices";

const api = ApiServices;

/**
 * Retorna as configurações do layout
 * de acordo com o cliente.
 */
export const findSettings = () => {
    return api.get(ENDPOINT.TENANT_SETTINGS);
}

/**
 * Retorna as configurações do layout
 * de acordo com o cliente.
 */
export const findModules = () => {
    return api.get(ENDPOINT.TENANT_MODULES);
}