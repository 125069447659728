/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { ButtonCustom } from '../../../../../components/button/ButtonCustom';
import { PasswordInput } from '../../../../../components/inputs/PasswordInput';
import { useHtmlClassService } from '../../../../../components';

import * as fromState from './_redux/actions';
import * as fromAuth from '../../../Auth/_redux/authRedux';
// import UserServices from '../../../../../../services/UserServices';
import * as fromRegister from '../../_redux/actions';
import * as fromBanks from '../BankAccount/_redux/actions';
import ErrorFields from '../../../../../components/alert/ErrorFields';

const initialValues = {
  password: '',
  verifyPassword: '',
};

function PasswordVerification(props) {
  const { intl, settings, actions, error_fields, registerUser } = props;
  const history = useHistory();
  // const [loading, setLoading] = useState(false);
  props.setPercent(90);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const RegistrationSchema = Yup.object().shape({
    password: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .matches(
        /^.*(?=.{6,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        intl.formatMessage({
          id: 'AUTH.REGISTER.PASSWORD.VALIDATION',
        })
      ),
    verifyPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.INPUT.CONFIRM_PASSWORD',
        })
      )
      .when('password', {
        is: password => (password && password.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          intl.formatMessage({
            id: 'AUTH.REGISTER.CONFIRM_PASSWORD',
          })
        ),
      }),
  });

  const nextStep = () => {
    history.push('/dashboard');
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  if (props.isRegister) {
    props.actions.loadSend();
    setTimeout(() => {
      //setLoading(true);
      //UserServices.login(
      //registerUser.person.basic.email,
      //props.password.password
      //)
      //.then(({ data: { access_token } }) => {
      //setLoading(false);
      //props.actions.loginForRegister(access_token);
      nextStep();
      //})
      //.catch(err => {
      //console.log(err);
      //setLoading(false);
      //});
    }, 2000);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: values => {
      actions.registerPassword(values);
      registerUser.password = values;
      setTimeout(() => {
        actions.register(registerUser);
      }, 1000);
    },
  });

  return (
    <>
      {/** begin::ModalError */}
      <ErrorFields
        error_fields={error_fields}
        show={error_fields !== '' ? true : false}
        onHide={() => {
          actions.fetchErrors('');
          // setLoading(false);
        }}
      />
      {/** end::ModalError */}

      <div className="login-form login-signin" style={{ display: 'block' }}>
        {/* begin: Message */}
        <div className="form-group text-center font-weight-bold fv-plugins-icon-container">
          <Alert
            style={{
              color: '#fff',
              backgroundColor: layoutProps.colors.primary,
            }}
          >
            <p>
              <FormattedMessage id="AUTH.REGISTER.PASSWORD" />
            </p>
          </Alert>
        </div>
        {/* end: Message */}

        <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated 
				animate__animated animate__backInUp"
          onSubmit={formik.handleSubmit}
        >
          {/* begin: Senha */}
          <div className="form-group fv-plugins-icon-container">
            <label className="text-muted">Sua senha de acesso:</label>
            <PasswordInput
              type="password"
              classes={`form-control h-auto py-5 px-6 
						${getInputClasses('password')}`}
              name="password"
              formik={formik.getFieldProps('password')}
            />

            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          {/* end: Senha */}

          {/* begin: Fullname */}
          <div className="form-group fv-plugins-icon-container">
            <label className="text-muted">Repita a senha:</label>
            <PasswordInput
              type="password"
              classes={`form-control h-auto py-5 px-6 
						${getInputClasses('verifyPassword')}`}
              name="verifyPassword"
              formik={formik.getFieldProps('verifyPassword')}
            />

            {formik.touched.verifyPassword && formik.errors.verifyPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.verifyPassword}
                </div>
              </div>
            ) : null}
          </div>
          {/* end: Fullname */}

          {/* begin: Termos */}
          <div className="form-group fv-plugins-icon-container">
            <input type="checkbox" defaultChecked />
            <span className="text-muted font-weight-bold">
              &nbsp;&nbsp;Declaro que eu li e aceito os{' '}
            </span>
            <span
              style={{
                color: layoutProps.colors.primary,
              }}
            >
              <a
                className="text-dark"
                target="_blank"
                rel="noopener noreferrer"
                href={settings.urls.terms}
              >
                Termos de Adesão e Condições de uso,{' '}
              </a>
              <a
                className="text-dark"
                target="_blank"
                rel="noopener noreferrer"
                href={settings.urls.ethics_and_conduct_guidelines}
              >
                Diretivas de Ética e Conduta,
              </a>
              <a
                className="text-dark"
                target="_blank"
                rel="noopener noreferrer"
                href={settings.urls.privacy_policy}
              >
                Política de Privacidade,
              </a>
              <a
                className="text-dark"
                target="_blank"
                rel="noopener noreferrer"
                href={settings.urls.account_opening_proposal}
              >
                Proposta de abertura de Conta Digital e&nbsp;
              </a>
              <a
                className="text-dark"
                target="_blank"
                rel="noopener noreferrer"
                href={settings.urls.essential_information}
              >
                Informações Essenciais.
              </a>
            </span>
          </div>
          {/* end: Termos */}

          <div className="form-group d-flex flex-wrap flex-center">
            <div className="col-md-10">
              <ButtonCustom
                layoutProps={layoutProps}
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-6 py-4 my-3 mx-4"
                size="sm"
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="AUTH.REGISTER.NEXT" />
                {formik.isSubmitting && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </ButtonCustom>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    ({
      settings,
      physicalPerson,
      legalPerson,
      phone,
      zipcode,
      password,
      register,
      allBanks,
    }) => ({
      settings: settings.settings,
      register: register.register,
      isRegister: register.send,
      password: password.password,
      error_fields: register.error_fields,
      registerUser: {
        person: physicalPerson.basic.cpf > 0 ? physicalPerson : legalPerson,
        password: password,
        phone: phone.phone,
        zipcode: zipcode.zipcode,
        allBanks: allBanks,
      },
    }),
    dispatch => {
      return {
        actions: bindActionCreators(
          Object.assign(
            {},
            fromState.actions,
            fromRegister.actions,
            fromAuth.actions,
            fromBanks.actions
          ),
          dispatch
        ),
      };
    }
  )(PasswordVerification)
);
