/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as actions from "../../../../Transfer/_redux/external/actions";
import * as fromActions from "./_redux/actions";
import { FormattedMessage } from "react-intl";
import { ButtonCustom } from "../../../../../components/button/ButtonCustom";
import { useHtmlClassService } from "../../../../../components";
import { useHistory } from "react-router-dom";
import { ButtonSelector } from "../../../../../components/button/ButtonSelector";
import { NewBank } from "./NewBank";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from 'react-select';

const initialValues = {
    bank_code: "",
};

function BankAccountVerification(props) {
    const { listBanks, objBanks } = props;
	const history = useHistory();
    const [bank, hasBank] = useState(false);
    const [values, setBanks] = useState({});
    const [canAddBank, setCanAddBank] = useState(false);
    const bankAlreadyAdded = listBanks.some(el => el.name === values.name && el.id === values.id);
    const bankIsEmpty = isEmpty(values);
    const bankIsAvailable = !bankIsEmpty && !bankAlreadyAdded;

    props.setPercent(70);

    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            colors: uiService.getColors()
        };
    }, [uiService]);

    const RegistrationSchema = Yup.object().shape({
        bank_code: Yup.number()
    });

     useEffect(() => {
        while (listBanks.length > 0) {
            listBanks.pop();
        }
        if(listBanks.length === 0) props.actions.loadBank(listBanks);
    }, []);

    useEffect(() => {
        props.actions.findBanks();
    }, [props.actions.findBanks]);

    useEffect(() => {
        if(canAddBank)
            addBank()
    }, [canAddBank]);

    const setBank = (value) => {
        setBanks(value);
    };

	const removeBank = async (id) => {
        let arr = await props.listBanks.filter((v, i) => v.id !== id);
        props.actions.loadBank(arr)
    }
    
    const addBank = async () => {
      
        if(bankIsAvailable){
            listBanks.push(values);
            props.actions.loadBank(listBanks);
        }
        
        setBank({});
    }

    const nextStep = () => {
		history.push(`/cadastro/senha`)
	}

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            let arr = Object.keys(listBanks).map(function (key) { return listBanks[key].id; });
            props.actions.registerBank(arr)
            
            setTimeout(() => {
                nextStep();
            }, 1000);
        },
    });

    return (
        <div className="login-form login-signin" style={{ display: "block" }}>
            <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated 
				animate__animated animate__backInUp"
                onSubmit={formik.handleSubmit}
            >

                {/* begin: Banco Boolean */}
                <div className="form-group fv-plugins-icon-container">
                    <label className="text-muted">
                        Possui conta em outro banco?
                    </label>

                    <div className="ml-5 row">
                        <ButtonSelector
                            layoutProps={layoutProps}
                            type="button"
                            onClick={(() => {
                                hasBank(false)
                            })}
                            hover={(bank) ? (false) : (true)}
                            className="btn font-weight-bold px-6 py-2 my-2"
                        >
                            Não
                        </ButtonSelector>
                        <ButtonSelector
                            layoutProps={layoutProps}
                            type="button"
                            onClick={(() => {
                                hasBank(true)
                            })}
                            hover={bank}
                            className="btn mr-5 font-weight-bold px-6 py-2 my-2"
                        >
                            Sim
                        </ButtonSelector>
                    </div>
                </div>
                {/* end: Banco Boolean */}
                {(() => {
                    if(bank){
                        if(listBanks.length > 0) {
                            return <NewBank banks={listBanks} removeBank={removeBank} />
                        }
                    }
                })()}

                {/* begin: Bancos */}
                {(() => {
                    if(bank){
                        return (
                            <div className="form-group fv-plugins-icon-container">
                                <label className="text-muted">Banco:</label>
                                <Select
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                        ...theme.colors,
                                        primary: 'none',
                                        },
                                    })}
                                    name="bank_code"
                                    placeholder="Selecione um banco"
                                    options={objBanks}
                                    onChange={(e) => {
                                        setBank({
                                            id: e.value,
                                            name: e.label
                                        })
                                    }}
                                />
                                <button 
                                    disabled={bankIsEmpty || bankAlreadyAdded}
                                    className="btn pt-3"
                                    type="button"
                                    style={{
                                        color: layoutProps.colors.primary
                                    }}
                                    onClick={() => {
                                        addBank()
                                    }}
                                >
                                    <i className="fa fa-plus"    
                                    style={{
                                        color: layoutProps.colors.primary
                                    }}></i> 
                                    &nbsp;Adicionar outro banco
                                </button>
                            </div>
                        )
                    }
                })()}
                {/* end: Bancos */}

                <div className="mt-30 form-group d-flex flex-wrap flex-center">

                    <div className="col-md-10">
                        <ButtonCustom
                            onClick={() => setCanAddBank(true)}
                            layoutProps={layoutProps}
                            id="kt_login_forgot_submit"
                            type="submit"
                            className="btn btn-primary font-weight-bold px-6 py-4 my-3 mx-4"
                            size="sm"
                            disabled={bank && !bankIsAvailable}
                        >
                            <FormattedMessage id="AUTH.REGISTER.NEXT" />
                        </ButtonCustom>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default connect(
	({ allBanks, physicalPerson, external }) => ({
        listBanks: allBanks.listBanks,
        objBanks: external.banks, 
    }), 
    (dispatch) => {
        return {
            actions: bindActionCreators(
                Object.assign({}, 
                    fromActions.actions, 
                    actions.actions,
                ), 
        dispatch)
    }
})(BankAccountVerification)
