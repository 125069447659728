import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ButtonCustom } from "../../../../components/button/ButtonCustom";
import { useHtmlClassService } from "../../../../components";
import { declaredValues } from "../helpers/RegisterHelpers";
import * as fromState from "./_redux/actions";

const initialValues = {
  occupation: "",
  monthly_income: 0,
  declared_equity: 0,
  politically_exposed_person: false,
};

function FinancialInformation(props) {
  const { registerFinancialInformation } = props;
  const history = useHistory();
  const intl = useIntl();
  props.setPercent(50);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors()
    };
  }, [uiService]);

  const RegistrationSchema = Yup.object().shape({
    occupation: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    monthly_income: Yup.number()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    declared_equity: Yup.number()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    politically_exposed_person: Yup.boolean()
  });

  const nextStep = () => {
    history.push("/cadastro/endereco")
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values) => {
      registerFinancialInformation(values)
      nextStep();
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >

        {/* begin: occupation */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">
            <FormattedMessage id="REGISTER.LABEL.PROFESSION" />
          </label>
          <input
            type="text"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              "occupation"
            )}`}
            name="occupation"
            {...formik.getFieldProps("occupation")}
          />
          {formik.touched.occupation && formik.errors.occupation ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.occupation}</div>
            </div>
          ) : null}
        </div>
        {/* end: occupation */}

        {/* begin: monthly_income */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">
            <FormattedMessage id="REGISTER.LABEL.MONTHLY_INCOME" />
          </label>
          <select
            type="text"
            className="form-control h-auto py-4 px-6"
            name="monthly_income"
            {...formik.getFieldProps("monthly_income")}
            onChange={(e) => {
              formik.setFieldValue("monthly_income", e.target.value);
            }}
          >
            {declaredValues.map(v => {
              return <option key={v.id} value={v.id}>{v.text}</option>
            })}
          </select>
          {formik.touched.monthly_income && formik.errors.monthly_income ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.monthly_income}</div>
            </div>
          ) : null}
        </div>
        {/* end: monthly_income */}

        {/* begin: declared_equity */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">
            <FormattedMessage id="REGISTER.LABEL.DECLARED_EQUITY" />
          </label>
          <select
            type="text"
            className="form-control h-auto py-4 px-6"
            name="declared_equity"
            {...formik.getFieldProps("declared_equity")}
            onChange={(e) => {
              formik.setFieldValue("declared_equity", e.target.value);
            }}
          >
            {declaredValues.map(v => {
              return <option key={v.id} value={v.id}>{v.text}</option>
            })}
          </select>
          {formik.touched.declared_equity && formik.errors.declared_equity ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.declared_equity}</div>
            </div>
          ) : null}
        </div>
        {/* end: declared_equity */}

        {/* begin: politically_exposed_person	 */}
        <div className="form-group fv-plugins-icon-container">
          <input
            type="checkbox"
            name="politically_exposed_person"
            {...formik.getFieldProps("politically_exposed_person")}
          />
          <span className="text-muted">&nbsp;&nbsp;
            <FormattedMessage id="REGISTER.LABEL.POLITICALLY_EXPOSED" />
          </span>
        </div>
        {/* end: politically_exposed_person	 */}

        <div className="form-group d-flex flex-wrap flex-center">
          <ButtonCustom
            layoutProps={layoutProps}
            id="kt_login_forgot_submit"
            type="submit"
            className="btn btn-primary font-weight-bold px-6 py-4 my-3 mx-4"
            size="sm"
          >
            <FormattedMessage id="AUTH.REGISTER.NEXT" />
          </ButtonCustom>
        </div>
      </form>
    </div>
  );
}

export default connect(
  ({ physicalPerson }) => ({
  }), fromState.actions)(FinancialInformation)
