import { ENDPOINT } from "../../../../../../../services/enums/EndPoint";
import ApiServices from "../../../../../../../services/ApiServices";

const api = ApiServices;

/**
 * Verifica telefone cadastrado
 */
export const verifyZipCode = ({ zipcode }) => {
    return api.get(`${ENDPOINT.ZIPCODE}/${zipcode}`);
}