import PaymentServices from '../../../../../services/PaymentServices';
import { billSlice, callTypes } from "./billSlice";
import { HTTPCodes } from '../../../../../services/enums/HTTPCodes';

const { actions } = billSlice;

export const fetchBillTransfer = page => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));

    return PaymentServices
        .getAllBills(page)
        .then(res => {
            const { meta: { pagination }, results } = res.data;

            dispatch(actions.billTransferFetched({ pagination, entities: results }));
        })
        .catch(err => {
            const { data: { message } } = err.response;
            err.clientMessage = message;
            dispatch(actions.catchError({ err, callType: callTypes.list }));
        });
};


export const payBills = params => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));

    return PaymentServices
        .payBill(params)
        .then(res => {
            const { operation } = res.data;
            dispatch(actions.fetchTransfer(operation));
            fetchBillTransfer(1);
        })
        .catch(err => {
            let message_error = '';

            switch (err.response.status) {
                case HTTPCodes.UNKNOWN_SERVER_ERROR:
                    message_error = `[${err.response.status}] Serviço indisponível`;
                    dispatch(actions.fetchError([{ message: message_error }]));
                    break;

                case HTTPCodes.FORBIDDEN:
                    const { data: { message } } = err.response;
                    message_error = `[${err.response.status}] ${message}`;
                    dispatch(actions.fetchError([{ message: message_error }]));

                    break;

                default:
                    const { data: { field_errors } } = err.response;
                    dispatch(actions.fetchError(Object.values(field_errors)[0]));
                    break;
            }
        });
}


export const infoBill = params => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));

    return PaymentServices
        .infoBill(params)
        .then(res => {
            const rtr = res.data;
            const obj = {
                writable_line: rtr.writable_line,
                bar_code: rtr.barcode,
                value_origin: rtr.original_value < 1 ? params.value : rtr.original_value,
                value: params.value,
                due_date: rtr.due_date || params.due_date,
                addition: params.addition,
                discount: params.discount,
            };
            dispatch(payBills(obj));
        })
        .catch(err => {
            if (err.response.status === HTTPCodes.UNKNOWN_SERVER_ERROR) {
                const { message } = "Serviço indisponível";
                dispatch(actions.fetchError([{ message }]));
            } else if(err.response.status === HTTPCodes.UNPROCESSABLE_ENTITY) {
                const { data: { message } } = err.response;
                dispatch(actions.fetchError([{ message }]));
            }else{
                const { data: { field_errors } } = err.response;
                dispatch(actions.fetchError(Object.values(field_errors)[0]));
            }
        });
}

export const fetchError = params => dispatch => {
    dispatch(actions.fetchError(params));
}