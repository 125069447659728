/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../app/components";
import AuthPage from "./Auth/pages/AuthPage";
import RegisterPage from "./Register/RegisterPage";

export default function UserPage() {

  return (
    <Switch>
        <ContentRoute path="/auth" component={AuthPage} />
        <ContentRoute path="/alterar" component={AuthPage} />
        <ContentRoute path="/cadastro" component={RegisterPage} />
        <Redirect from="/auth" exact={true} to="/auth/login" />
        <Redirect to="/auth/login" />
    </Switch>
  );
}