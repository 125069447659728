
import React, { useEffect, useMemo } from "react";
import ApexCharts from "apexcharts";
import { injectIntl } from 'react-intl';
import { Card } from "../../../partials/controls";
import { connect } from "react-redux";
import * as actions from "../../../redux/balance/actions";
import { useHtmlClassService } from "../../_core/MetronicLayout";

const Balance = (props) => {
    const { intl, requestSummary, requestBalance, 
        balance, summary } = props;
    
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colors: uiService.getColors()
        };
    }, [uiService]);
        
    useEffect(() => {
        requestSummary()
        requestBalance()
        setTimeout(() => {
            let element = document.getElementById("kt_balance_chart");
            if(typeof(element) != 'undefined' && element != null) {

                summary.colors = [layoutProps.colors.primary, layoutProps.colors.secondary];

                const chart = new ApexCharts(element, summary);
                chart.render();

                return function cleanUp() {
                    chart.destroy();
                };
            }
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestSummary, requestBalance, summary]);

    return (
        <Card className="column col-xs-12 col-xl-12 col-md-12">
            {/* Header */}
            <div className="card-header border-0 pt-5">
                <h3 className="card-title font-weight-bolder ">
                    {intl.formatMessage({ id: "BALANCE.TITLE" })}
                </h3>
                <p className="text-muted font-weight-bold font-size-lg mt-1">
                    Saldo disponível para uso em sua conta
                </p>
                <h3>{balance.balance}</h3>
            </div>

            {/* Body */}
            <div className="card-body d-flex flex-column">
                <div className="flex-grow-1">
                    <div id="kt_balance_chart" style={{ height: "200px" }}>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default injectIntl(connect(
    (state) => ({ 
        credit: state.summary.credit, 
        debit: state.summary.debit,
        summary: state.summary.summary,  
        balance: state.summary.balance,
        settings: state.settings
    }),
    actions.actions
)(Balance));
