import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import store, { persistor } from './redux/store';
import App from './app/App';
import './index.scss'; // Standard version

import './assets/plugins/keenthemes-icons/font/ki.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/plugins/flaticon/flaticon.css';
import './assets/plugins/flaticon2/flaticon.css';
import 'react-datepicker/dist/react-datepicker.css';

import * as Sentry from '@sentry/react';

import {
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from './app/components';
import MetronicLayoutProvider from './app/_core/MetronicLayout';
import { MetronicI18nProvider } from './i18n';
import ApiServices from './services/ApiServices';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = */

// _redux.mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

ApiServices.setup(store);

Sentry.init({
  dsn:
    'https://b8a060c9cebeb12d02f79b7d6c7c909e@o1149518.ingest.sentry.io/4506026114809856',
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
});

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider store={store}>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById('root')
);
