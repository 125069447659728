import { put, takeLatest } from "redux-saga/effects";
import { findSettings, findModules } from "./settingCrud";
import SettingsUtils from "../../app/utils/settings.utils";
import ModulesUtils from "../../app/utils/modules.utils";
import { initialState } from "./settingsState";

const utils = new SettingsUtils();
const ModuleUtil = new ModulesUtils();

export const actionTypes = {
  SettingsRequested: "[Request Settings] Action",
  SettingsLoaded: "[Load Settings] Action",
  ModulesRequested: "[Request Modules] Action",
  ModulesLoaded: "[Load Modules] Action"
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SettingsLoaded: {
        const { settings } = action.payload;
        return { ...state, settings };
      }

      case actionTypes.ModulesLoaded: {
        const { modules } = action.payload;
        return { ...state, modules: modules };
      }

      default:
        return state;
    }
};

export const actions = {
  requestSettings: settings => ({ type: actionTypes.SettingsRequested, payload: { settings } }),
  fetchSettings: settings => ({ type: actionTypes.SettingsLoaded, payload: { settings } }),
  requestModules: modules => ({ type: actionTypes.ModulesRequested, payload: { modules } }),
  fetchModules: modules => ({ type: actionTypes.ModulesLoaded, payload: { modules } })
};

export function* saga() {
  yield takeLatest(actionTypes.SettingsRequested, function* settingsRequested() {
    const { data: settings } = yield findSettings();

    const newSettings = yield utils.filterSettings(settings.results);

    yield put(actions.fetchSettings(newSettings));
    
  });

  yield takeLatest(actionTypes.ModulesRequested, function* modulesRequested() {
    const { data: modules } = yield findModules();

    const newModules = yield ModuleUtil.filterModules(modules.results);

    yield put(actions.fetchModules(newModules));
    
  });
}
