export const initialState = {
    phone: {},
    isVerified: false,
    verified: false,
    updatePhone: {},
    error_fields: '',
}

export const types = {
    PhoneRegister: "[Register] Phone Register",
    PhoneRegisterLoaded: "[Loaded] Phone Register",
    PhoneVerify: "[Verify] Phone Verify",
    PhoneVerifyLoaded: "[Loaded] Phone Verify",
    ClearPhone: "[Clear] Phone",
    LoadIsVerified: "[Load] Is Verified",
    PhoneReSend: "[Code] Resend",
    
    UpdatePhone: '[Update Phone] Phone',
    UpdatePhoneFetch: '[Update Phone] Fetch',
    UpdatePhoneClear: '[Update Phone] Clear',
    UpdatePhoneVerify: '[Update Phone] Verify',

    ErrorsLoaded: '[Error] Errors',
}

export const actions = {
    registerPhone: register => ({ 
        type: types.PhoneRegister, 
        payload: { register } 
    }),
    loadPhone: register => ({ 
        type: types.PhoneRegisterLoaded, 
        payload: { register } 
    }),
    verifyPhone: register => ({ 
        type: types.PhoneVerify, 
        payload: { register } 
    }),
    reSendCode: register => ({ 
        type: types.PhoneReSend, 
        payload: { register } 
    }),
    loadVerify: register => ({ 
        type: types.PhoneVerifyLoaded, 
        payload: { register } 
    }),
    loadIsVerified: register => ({ 
        type: types.LoadIsVerified, 
        payload: { register } 
    }),
    clearPhone: register => ({ 
        type: types.ClearPhone, 
        payload: { register } 
    }),
    
    updatePhone: updatePhone => ({ type: types.UpdatePhone, payload: { updatePhone }}),
    fetchUpdatePhone: updatePhone => {
        return { 
            type: types.UpdatePhoneFetch,
            payload: { updatePhone },
        }
    },
    clearUpdatePhone: () => ({ type: types.UpdatePhoneClear }),
    verifyUpdatePhone: code => ({ type: types.UpdatePhoneVerify, payload: { code } }),
    fetchErrors: error => ({ 
        type: types.ErrorsLoaded, 
        payload: { error } 
    }),
}