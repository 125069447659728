import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ResumePage } from './Resume/ResumePage';
import { ExtractPage } from './Extract/ExtractPage';
import { LayoutSplashScreen, ContentRoute } from '../../components';
import { DepositPage } from './Deposit/DepositPage';
import { DepositTedPage } from './Deposit/DepositTedPage';
import DepositBillPage from './Deposit/deposit-bill/DepositBillPage';
import ProfilePage from './Profile/ProfilePage';
import LimitsPage from './Limits/LimitsPage';
import SupportPage from './Support/SupportPage';
import ComprovantePage from './SComprovante/SComprovantePage';
import FavoritesPage from './Favorites/FavoritesPage';
import PixPage from './Pix/PixPage';
import { PixPagarPage } from './Pix/Pagar';
import PixReceber from './Pix/PixReceber';

export default function eCommercePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from account root URL to /customers */
          <Redirect exact={true} from="/conta" to="/conta/resumo" />
        }
        <ContentRoute
          exact={true}
          path="/conta/resumo"
          component={ResumePage}
        />
        <ContentRoute
          exact={true}
          path="/conta/extrato"
          component={ExtractPage}
        />
        <ContentRoute
          exact={true}
          path="/conta/deposito/boleto"
          component={DepositPage}
        />
        <ContentRoute
          exact={true}
          path="/conta/deposito/boleto/:id"
          component={DepositBillPage}
        />
        <ContentRoute
          exact={true}
          path="/conta/deposito/ted"
          component={DepositTedPage}
        />
        <ContentRoute
          exact={true}
          path="/conta/limits"
          component={LimitsPage}
        />
        <ContentRoute
          exact={true}
          path="/conta/meus-dados"
          component={ProfilePage}
        />
        <ContentRoute
          exact={true}
          path="/conta/suporte"
          component={SupportPage}
        />
        <ContentRoute
          exact={true}
          path="/conta/comprovante"
          component={ComprovantePage}
        />
        <ContentRoute
          exact={true}
          path="/conta/favoritos"
          component={FavoritesPage}
        />
        <ContentRoute exact={true} path="/conta/pix" component={PixPage} />

        <ContentRoute
          exact={true}
          path="/conta/pix/pagar"
          component={PixPagarPage}
        />

        <ContentRoute
          exact={true}
          path="/conta/pix/receber"
          component={PixReceber}
        />
        <Redirect from="/conta" to="/error" />
      </Switch>
    </Suspense>
  );
}
