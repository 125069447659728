import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./PhoneRechargeUIHelpers";

const PhoneRechargeUIContext = createContext();

export function usePhoneRechargeUIContext() {
  return useContext(PhoneRechargeUIContext);
}

export const PhoneRechargeUIConsumer = PhoneRechargeUIContext.Consumer;

export function PhoneRechargeUIProvider({ phoneRechargeUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initPhoneRecharge = {
    id: undefined,
    favored: "",
    document: "",
    value: 0,
    status: "",
    account_number: "",
    account_digit: "",
    account_type: "",
    bank_branch: "",
    bank_branch_digit: "",
    created_at: "",
    updated_at: "",
    bank: []
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initPhoneRecharge,
    openFetchPhoneRechargeDialog: phoneRechargeUIEvents.openFetchPhoneRechargeDialog
  };

  return <PhoneRechargeUIContext.Provider value={value}>{children}</PhoneRechargeUIContext.Provider>;
}