import React from 'react';

export const NotFound = ({ message }) => {
    return (
        <div className="row text-muted border-top mt-5 pt-5">
            <div className="col-md-12">
                <span className="font-weight-bold">{ message }</span>
            </div>
        </div>
    )
}