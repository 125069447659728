import React, { useState, useMemo, useEffect } from 'react';
import * as actions from '../_redux/PixActions';
import { connect, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import { Card, CardBody } from '../../../../../partials/controls';
import { CardMessage } from '../../../../components/message/CardMessage';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';

import ErrorFields from '../../../../components/alert/ErrorFields';
import { useHtmlClassService } from '../../../../_core/MetronicLayout';

import {
  documentMask,
  phoneMask,
  moneyMask,
  accountMask,
  formatPrice,
  removeCurrencyMask,
} from '../../../../utils/currency.utils';

import UserServices from '../../../../../services/UserServices';

const useStyles = makeStyles(theme => ({
  head: {
    color: 'black',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  container: {
    marginBottom: '25px',
    marginTop: '40px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
  },
  left: {
    width: '40%',
  },
  right: {
    width: '60%',
    justifyContent: 'flex-start',
  },
  userData: {
    backgroundColor: '#E1E1E8',
    paddingTop: 20,
    paddingBottom: 20,
    marginRight: 20,
    borderRadius: 15,
  },
  userDataRow: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 5,
  },
}));

function PixPagar(props) {
  const {
    user,
    intl,
    error_fields,
    fetchError,
    loadingAction,
    keyData,
  } = props;

  // const [loading, setLoading] = useState(false);

  const [accountData, setAccountData] = useState([]);
  // const [keyData] = useState([]);

  const [nominalAccount, setNominalAccount] = useState(false);
  const [nominalInfo, setNominalInfo] = useState([]);
  const [haveNominal, setHaveNominal] = useState(false);
  const [contaCorrente, setContaCorrente] = useState(false);
  const [storage, setStorage] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();

  // Schema de validação dos campos
  const PixSchema = Yup.object().shape({
    // value: Yup.string().required(
    //   intl.formatMessage({
    //     id: 'BILL.REQUIRED_FIELD.VALUE',
    //   })
    // ),
    destination_pix_identifier: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    // .test('Document Test', 'O número do documento esta inválido', value => {
    //   if (value) {
    //     const numbers = value.replace(/\D/g, '');
    //     if (numbers.length <= 11) {
    //       return cpfValid(value);
    //     } else {
    //       return cnpjValid(value);
    //     }
    //   }
    // }),
  });

  const updateTax = () => {
    if (accountData.length === 0) return;
    let tax = 0;

    if (accountData.limits.pix_transfer?.fee_amount > 0) {
      tax = accountData.limits.pix_transfer?.fee_amount;
    }
    return tax > 0 ? `Taxa de Emissão de PIX: ${formatPrice(tax)}` : '';
  };

  const normalizeKey = (type, key) => {
    let newKey = '';

    if (['cpf', 'cnpj', 'phone'].includes(type)) {
      newKey = key.replace(/\D/g, '');
    } else {
      newKey = key;
    }

    return newKey;
  };

  // Inicializando e validando formulário
  const formik = useFormik({
    initialValues: {
      key: 'cpf',
      description: '',
      value: '',
      destination_pix_identifier: '',
    },
    validationSchema: PixSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (keyData.length === 0) {
        dispatch(
          actions.pixFetchKey({
            type: values.key,
            key: normalizeKey(values.key, values.destination_pix_identifier),
            document: user.person.cpf || user.person.cnpj,
          })
        );
      } else {
        const value = parseFloat(removeCurrencyMask(values.value));

        if (value === '' || value < 1) {
          // dispatch error
          dispatch(
            actions.fetchError([{ message: 'Valor precisa ser maior que 1!' }])
          );
        } else {
          // Já carregamos a chave, e podemos efetuar a operação!
          const obj = {
            value: value,
            bank_code: keyData.counterpart_bank_code,
            type: values.key,
            destination_pix_identifier: normalizeKey(
              values.key,
              values.destination_pix_identifier
            ),
            description: values.description,
            is_nominal: nominalAccount,
          };

          // colocar o 55 na frente do telefone
          if (values.key === 'phone') {
            obj.destination_pix_identifier =
              '+55' + obj.destination_pix_identifier;
          }

          dispatch(actions.makePixTransfer(obj));
        }
      }
    },
  });

  const uiService = useHtmlClassService();

  const accountTypes = {
    '': '',
    CACC: 'Conta Corrente',
    SLRY: 'Conta Salário',
    SVGS: `Conta Poupança`,
    TRAN: 'Conta de Pagamento',
  };

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const loadInfo = async () => {
    let info = await UserServices.getAccount();
    setAccountData(info.data);

    let provider = await UserServices.getAccountProvider();

    if (provider.data.results.length > 0) {
      const arrayNominal = provider.data.results.filter(
        provider => provider.type === 'graphic'
      );
      const arrayCorrente = provider.data.results.filter(
        provider => provider.type === 'nominal'
      );
      if (arrayNominal.length > 0) {
        setNominalInfo(arrayNominal);
        setHaveNominal(true);
      }
      if (arrayCorrente.length > 0) {
        setContaCorrente(true);
      }
    }
  };

  useEffect(() => {
    if (accountData.length === 0) loadInfo();
  }, [accountData.length]);

  const returnMask = (key, value) => {
    if (value === undefined) return '';

    if (key === 'cpf') {
      return documentMask(value);
    } else if (key === 'cnpj') {
      return documentMask(value);
    } else if (key === 'phone') {
      return phoneMask(value);
    } else {
      return value;
    }
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('copyPaste'));

    if (data !== undefined && data !== null) {
      setStorage(data);

      formik.setFieldValue('value', moneyMask(data.value));
      formik.setFieldValue('destination_pix_identifier', data.key);

      dispatch(
        actions.pixFetchKey({
          key: data.key,
          document: data.cpf_cnpj,
        })
      );

      setTimeout(() => {
        localStorage.removeItem('copyPaste');
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row">
      {/** begin::ModalError */}
      <ErrorFields
        error_fields={error_fields}
        show={error_fields !== '' ? true : false}
        onHide={() => {
          fetchError('');
          // setLoading(false);
        }}
      />
      {/** end::ModalError */}
      <div className="col-md-12 col-xs-12 col-lg-12">
        <CardMessage message="Tarifa de PIX R$ 0,00.">
          <div>
            <br />
            {updateTax()}
          </div>
        </CardMessage>

        <Card className="col-xs-12 col-xl-12 col-md-12">
          <CardBody>
            <h4 className={classes.head}>Enviar Pix</h4>
            {keyData.length === 0 ? (
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  {/** begin::Chaves*/}
                  <div className="col-md-6 col-lg-6 col-xs-12">
                    <div className="form-group fv-plugins-icon-container">
                      <label htmlFor="value">Chave de destino</label>
                      <select
                        className="form-control select2 h-auto py-4 px-6"
                        name="key"
                        onChange={e => {
                          formik.setFieldValue('key', e.target.value);
                          formik.setFieldValue(
                            'destination_pix_identifier',
                            ''
                          );
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.key}
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: 'none',
                          },
                        })}
                      >
                        <option value="cpf">CPF</option>
                        <option value="phone">Telefone</option>
                        <option value="email">E-mail</option>
                        <option value="evp">Chave aleatória</option>
                        <option value="cnpj">CNPJ</option>
                      </select>
                    </div>
                  </div>
                  {/** end::Chaves*/}

                  {/** begin::Chave Selecionada*/}
                  <div className="col-md-6 col-lg-6 col-xs-12">
                    <div className="form-group fv-plugins-icon-container">
                      <label htmlFor="document">Chave</label>
                      <input
                        type="text"
                        className="form-control h-auto py-3 px-6"
                        name="destination_pix_identifier"
                        {...formik.getFieldProps('destination_pix_identifier')}
                        onChange={e => {
                          formik.setFieldValue(
                            'destination_pix_identifier',
                            returnMask(formik.values.key, e.target.value)
                          );
                        }}
                      />
                      {formik.touched.destination_pix_identifier &&
                      formik.errors.destination_pix_identifier ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.destination_pix_identifier}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/** end::Chave Selecionada*/}

                  {/** begin::Valor*/}
                  <div className="col-md-6 col-lg-6 col-xs-12">
                    <div className="form-group fv-plugins-icon-container">
                      <label htmlFor="description">
                        Título / descrição (opcional)
                      </label>
                      <input
                        maxLength="18"
                        type="text"
                        className="form-control h-auto py-3 px-6"
                        name="description"
                        {...formik.getFieldProps('description')}
                        onChange={e => {
                          formik.setFieldValue('description', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/** end::Valor*/}
                </div>

                <div className="row">
                  <div className="col-md-12 col-md-offset-2">
                    <ButtonCustom
                      disabled={
                        formik.values.destination_pix_identifier === '' ||
                        loadingAction
                      }
                      type="submit"
                      layoutProps={layoutProps}
                    >
                      Continuar
                      {loadingAction && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                    </ButtonCustom>
                  </div>
                </div>
              </form>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <div className={classes.container}>
                  <div className={classes.left}>
                    <span>Confirme os dados do destinatário:</span>

                    <div className={classes.userData}>
                      <div className={classes.userDataRow}>
                        <span>Chave</span>
                        <span>{keyData.key || ''}</span>
                      </div>
                      <div className={classes.userDataRow}>
                        <span>Nome</span>
                        <span>{keyData.counterpart_name || ''}</span>
                      </div>
                      {storage.cpf_cnpj && (
                        <div className={classes.userDataRow}>
                          <span>Documento</span>
                          <span>{storage.cpf_cnpj}</span>
                        </div>
                      )}
                      {storage.institution_name && (
                        <div className={classes.userDataRow}>
                          <span>Instituição</span>
                          <span>{storage.institution_name}</span>
                        </div>
                      )}
                      <div className={classes.userDataRow}>
                        <span>Conta</span>
                        <span>{keyData.counterpart_account_number || ''}</span>
                      </div>
                      <div className={classes.userDataRow}>
                        <span>Tipo de Conta</span>
                        <span>
                          {accountTypes[keyData.counterpart_account_type] || ''}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className={classes.right}>
                    <div className="form-group fv-plugins-icon-container">
                      <label htmlFor="value">Valor:</label>
                      <input
                        placeholder="R$ 0,00"
                        type="text"
                        className="form-control h-auto py-3 px-6"
                        name="value"
                        {...formik.getFieldProps('value')}
                        onChange={e => {
                          formik.setFieldValue(
                            'value',
                            moneyMask(e.target.value)
                          );
                        }}
                      />
                      {formik.touched.value && formik.errors.value ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.value}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <label htmlFor="description">
                        Título / descrição (opcional)
                      </label>
                      <input
                        maxLength="18"
                        type="text"
                        className="form-control h-auto py-3 px-6"
                        name="description"
                        {...formik.getFieldProps('description')}
                        onChange={e => {
                          formik.setFieldValue('description', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mx-sm-1 mx-md-1 mx-lg-1 mx-xs-1 my-sm-4 my-md-4 my-lg-4 my-xs-4">
                  {(haveNominal || contaCorrente) && (
                    <div style={{ flexDirection: 'column' }}>
                      <div className="row">
                        <p>De qual conta deseja enviar?</p> &nbsp;
                        <div>
                          <input
                            type="radio"
                            onChange={() => {
                              setNominalAccount(false);
                            }}
                            name="nominal"
                            checked={!nominalAccount}
                          />
                          &nbsp;Conta Digital &nbsp;&nbsp;&nbsp;
                          {contaCorrente && (
                            <>
                              <input
                                type="radio"
                                onChange={() => {
                                  setNominalAccount(true);
                                }}
                                name="nominal"
                                checked={nominalAccount}
                              />
                              &nbsp;Conta Corrente
                            </>
                          )}
                        </div>
                      </div>

                      {nominalInfo.length > 0 && (
                        <div className="row">
                          <div className="boxNominalInfo">
                            <div>
                              <div className="rowNominalInfo">
                                <span className="titleNominalInfo">Nome:</span>
                                &nbsp;&nbsp;
                                <span>
                                  {nominalInfo[0].receiver_name || ''}
                                </span>
                              </div>
                              <div className="rowNominalInfo">
                                <span className="titleNominalInfo">
                                  Agência sem dígito:
                                </span>
                                &nbsp;&nbsp;
                                <span>{nominalInfo[0].branch.slice(0, 4)}</span>
                              </div>
                              <div className="rowNominalInfo">
                                <span className="titleNominalInfo">
                                  Conta com dígito:
                                </span>
                                &nbsp;&nbsp;
                                <span>
                                  {accountMask(nominalInfo[0].number)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-12 col-md-offset-2">
                    <ButtonCustom
                      disabled={
                        formik.values.destination_pix_identifier === '' ||
                        loadingAction
                      }
                      type="submit"
                      layoutProps={layoutProps}
                    >
                      Enviar Pix
                      {loadingAction && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                    </ButtonCustom>
                  </div>
                </div>
              </form>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    ({ pix, auth }) => ({
      loadingAction: pix.listLoading,
      error_fields: pix.error_fields,
      keyData: pix.keyData,
      user: auth.user,
    }),
    actions
  )(PixPagar)
);
