// React bootstrap table next =>
import React, { useEffect, useMemo } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import * as resume from "../../_redux/resume/resumeRedux";

import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../../../helpers";
import * as uiHelpers from "../ResumeUIHelpers";
import * as columnFormatters from "../../_formatters";
import { Pagination } from "../../../../../partials/controls";
import { useResumeUIContext } from "../ResumeUIContext";
import { NotFound } from "../../../../components/message/NotFound";

const ResumeTable = (props) => {
  const { requestResume, results, meta, listLoading } = props;

  // Customers UI Context
  const resumeUIContext = useResumeUIContext();
  const resumeUIProps = useMemo(() => {
    return {
      ids: resumeUIContext.ids,
      setIds: resumeUIContext.setIds,
      queryParams: resumeUIContext.queryParams,
      setQueryParams: resumeUIContext.setQueryParams,
      openEditCustomerDialog: resumeUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: resumeUIContext.openDeleteCustomerDialog,
    };
  }, [resumeUIContext]);

  // Redux state
  useEffect(() => {
    requestResume(resumeUIProps.queryParams.pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestResume, resumeUIProps.queryParams.pageNumber]);

  // Table columns
  const columns = [
    {
      text: "TIPO",
      dataField: "type",
      sort: false,
      align: 'center',
      headerAlign: 'center',
      formatter: columnFormatters.TypeColumnFormatter
    },
    {
      text: "DATA",
      dataField: "formatted_date",
      sort: false,
      align: 'center',
      headerAlign: 'center',
      formatter: columnFormatters.DateColumnFormatter
    },
    {
      text: "VALOR",
      dataField: "value",
      sort: false,
      align: 'center',
      headerAlign: 'center',
      formatter: columnFormatters.ValueColumnFormatter
    },
    {
      text: "DESCRIÇÃO",
      dataField: "title",
      sort: false,
      align: 'center',
      headerAlign: 'center',
      formatter: columnFormatters.TitleColumnFormatter
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: meta.pagination.total_count,
    sizePerPage: meta.pagination.per_page,
    paginationSize: meta.pagination.total_pages,
    page: resumeUIProps.queryParams.pageNumber,
    headerAlign: 'center',
  }
  return (
    (() => {
      if (results.length > 0) {
        return (
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={listLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    remote
                    keyField="uuid"
                    data={results === null ? [] : results}
                    columns={columns}
                    defaultSorted={uiHelpers.defaultSorted}
                    onTableChange={getHandlerTableChange(
                      resumeUIProps.setQueryParams
                    )}
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={results} />
                    <NoRecordsFoundMessage entities={results} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        )
      } else {
        return <NotFound message="Nenhum dado encontrado no momento." />;
      }
    })()
  );
}

export default connect(
  ({ resume }) => ({
    results: resume.results,
    meta: resume.meta,
    listLoading: resume.listLoading
  }),
  resume.actions)(ResumeTable);