import { ENDPOINT } from "./enums/EndPoint";
import ApiServices from "./ApiServices";

/**
 * Retorna a lista de bancos cadastrados
 * @return Promise
 */
export function getAllBanks() {
    return ApiServices.get(ENDPOINT.BANKS)
}

const BankServices = {
    getAllBanks
}
export default BankServices;