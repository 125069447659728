import React from 'react';
import { FormattedMessage } from 'react-intl';

import jsPDF from 'jspdf';

import { currency } from '../../../utils/currency.utils';
import { dateFormatted } from '../../../utils/date.utils';
import { cnpjMask, cpfMask } from '../../../utils/mask_cpf_cnpj.utils';

import PaymentServices from '../../../../services/PaymentServices';
import { ENDPOINT } from '../../../../services/enums/EndPoint';

import base64 from '../../../utils/base64.utils';
import emails from '../../../utils/email.utils';

const endpoints = {
  internal_transfer: ENDPOINT.INTERNAL_TRANSFER,
  bank_transfer: ENDPOINT.EXTERNAL_TRANSFER,
  phone_recharge: ENDPOINT.PHONE_RECHARGE,
  bill_payment: ENDPOINT.BILL_PAYMENTS,
  pix_transfer: ENDPOINT.PIX_TRANSFERS,
};

function stringDivider(str, width, spaceReplacer) {
  if (str.length > width) {
    var p = width;
    for (; p > 0 && str[p] !== ' '; p--) {}
    if (p > 0) {
      var left = str.substring(0, p);
      var right = str.substring(p + 1);
      return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
    }
  }
  return str;
}

const transactionType = {
  BANK_TRANSFER_RECEIVED: 'TED Recebida',
  BANK_TRANSFER: 'Transferência bancária',
  BILL_PAYMENT: 'Pagamento de boleto',
  DEPOSIT: 'Depósito',
  INTERNAL_TRANSFER: 'Transferência interna',
  POS_SALE: 'Venda POS',
  PHONE_RECHARGE: 'Recarga de telefone',
  INTERNAL_DEBIT: 'Débito interno',
  LIQUIDATED: 'Liquidado',
  DENIED: 'Negado',
  PENDING: 'Pendente',
  CARD_PURCHASE: 'Compra no Cartão',
  PIX_TRANSFER: 'Pix Efetuado',
  PIX_TRANSFER_RECEIVED: 'Pix recebido',
  '': '',
};

const salvarPdf = async row => {
  if (
    ![
      'internal_transfer',
      'bank_transfer',
      'phone_recharge',
      'bill_payment',
      'pix_transfer',
    ].includes(row.transaction_type)
  )
    return false;

  let info = await PaymentServices.getSecondVoucher(
    row.tradable_id,
    endpoints[row.transaction_type]
  );

  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    format: 'a4',
  });

  var imgData = 'data:image/jpeg;base64,' + base64[window.location.host];

  pdf.addImage(imgData, 'JPEG', 0, 10, 120, 30);

  pdf.setFontSize(16);
  pdf.text(
    `Comprovante de ${transactionType[row.transaction_type.toUpperCase()]}`,
    420,
    35,
    'right'
  );

  pdf.setFillColor('#000');
  pdf.rect(8, 45, 412, 3, 'F');

  pdf.setFontSize(20);
  var text = currency(info.data.value);
  var xOffset =
    pdf.internal.pageSize.width / 2 -
    (pdf.getStringUnitWidth(text) * pdf.getFontSize()) / 2;
  pdf.text(text, xOffset, 70);

  pdf.setFontSize(14);
  var realizadoEm = `Data da Operação: ${row.formatted_date}`;
  var xOffset2 =
    pdf.internal.pageSize.width / 2 -
    (pdf.getStringUnitWidth(realizadoEm) * pdf.getFontSize()) / 2;
  pdf.text(realizadoEm, xOffset2 + 25, 90);

  pdf.setFillColor('#000');
  pdf.rect(8, 100, 412, 2, 'F');

  pdf.setFontSize(13);

  pdf.text('Data da Transferência', 10, 120);
  pdf.text(row.formatted_date.split(' - ')[0], 135, 120);

  pdf.text('Autenticação', 10, 135);
  pdf.text(row.uuid, 135, 135);

  // START INTERNAL
  if (['internal_transfer', 'bank_transfer'].includes(row.transaction_type)) {
    pdf.setFont(undefined, 'bold');
    pdf.text('INFORMAÇÕES DO FAVORECIDO', 10, 190);
    pdf.setFont(undefined, 'normal');

    if (info.data.favored) {
      pdf.text('Nome:', 10, 210);
      pdf.text(info.data.favored, 135, 210);

      pdf.text('CPF/CNPJ:', 10, 225);
      if (info.data.document.length > 11) {
        pdf.text(cnpjMask(info.data.document), 135, 225);
      } else {
        pdf.text(cpfMask(info.data.document), 135, 225);
      }
    }
    if (info.data.user_receiver) {
      pdf.text('Favorecido:', 10, 210);
      pdf.text(info.data.user_receiver.name, 135, 210);

      pdf.text('CPF/CNPJ:', 10, 225);
      if (info.data.user_receiver.person.cnpj) {
        pdf.text(cnpjMask(info.data.user_receiver.person.cnpj), 135, 225);
      } else {
        pdf.text(cpfMask(info.data.user_receiver.person.cpf), 135, 225);
      }
    }
  }
  // END INTERNAL

  // START TED
  if (row.transaction_type === 'bank_transfer') {
    pdf.text('Banco:', 10, 240);
    pdf.text(
      stringDivider(info.data.bank.displayable_name, 40, '\n'),
      135,
      240
    );

    pdf.text('Tipo da Conta:', 10, 255);
    pdf.text(
      info.data.account_type === 'checking'
        ? 'Conta Corrente'
        : 'Conta Poupança',
      135,
      255
    );

    pdf.text('Agência:', 10, 270);
    pdf.text(
      `${info.data.bank_branch}${
        info.data.bank_branch_digit ? '-' + info.data.bank_branch_digit : ''
      }`,
      135,
      270
    );

    pdf.text('Número da Conta:', 10, 285);
    pdf.text(
      `${info.data.account_number}${
        info.data.account_digit ? '-' + info.data.account_digit : ''
      }`,
      135,
      285
    );
  }
  // END TED

  // START BILLET
  if (row.transaction_type === 'bill_payment') {
    pdf.setFont(undefined, 'bold');
    pdf.text('INFORMAÇÕES DO PAGAMENTO', 10, 190);
    pdf.setFont(undefined, 'normal');

    pdf.text('Adição:', 10, 205);
    pdf.text(currency(info.data.addition || 0), 135, 205);

    pdf.text('Desconto:', 10, 220);
    pdf.text(currency(info.data.discount || 0), 135, 220);

    pdf.text('Data de vencimento:', 10, 235);
    pdf.text(dateFormatted(info.data.due_date), 135, 235);

    pdf.text('Valor original:', 10, 250);
    pdf.text(currency(info.data.value_origin), 135, 250);

    pdf.text('Linha digitável:', 10, 265);
    pdf.text(currency(info.data.writable_line), 135, 265);

    pdf.text('Código de barras:', 10, 280);
    pdf.text(currency(info.data.bar_code), 135, 280);
  }
  // END BILLET

  // START PHONE RECHARGE
  if (row.transaction_type === 'phone_recharge') {
    pdf.setFont(undefined, 'bold');
    pdf.text('INFORMAÇÕES DA RECARGA', 10, 190);
    pdf.setFont(undefined, 'normal');

    pdf.text('Número:', 10, 205);
    pdf.text(`(${info.data.area_code}) ${info.data.phone_number}`, 135, 205);
  }
  // END PHONE RECHARGE

  const accountTypes = {
    '': '',
    CACC: 'Conta corrente',
    SLRY: 'Conta salário',
    SVGS: `Conta poupança`,
    TRAN: 'Conta de Pagamento',
  };

  const accountMask = value => {
    const v = value.replace(/\D/g, '');
    if (v.length < 2) return v;

    const separator = '-';
    const position = v.length - 1;
    const output = [v.slice(0, position), separator, v.slice(position)].join(
      ''
    );
    return output;
  };

  // START PIX
  if (row.transaction_type === 'pix_transfer') {
    pdf.setFont(undefined, 'bold');
    pdf.text('INFORMAÇÕES DA OPERAÇÃO', 10, 190);
    pdf.setFont(undefined, 'normal');

    pdf.text('Chave:', 10, 205);
    pdf.text(info.data.counterpart_pix_identifier, 135, 205);

    let height = 220;

    if (info.data.description) {
      pdf.text('Descrição:', 10, height);
      pdf.text(info.data.description, 135, height);
      height += 40;
    }

    if (info.data.provider_identification) {
      pdf.text('Identificação no Provedor:', 10, height);
      pdf.text(info.data.provider_identification, 135, height);
      height += 40;
    }

    pdf.setFont(undefined, 'bold');
    pdf.text('INFORMAÇÕES DO FAVORECIDO', 10, height);
    pdf.setFont(undefined, 'normal');
    height += 15;

    if (info.data.counterpart_name) {
      pdf.text('Nome:', 10, height);
      pdf.text(info.data.counterpart_name, 135, height);
      height += 15;
    }

    if (info.data.counterpart_cpf_cnpj) {
      pdf.text('CPF/CNPJ:', 10, height);
      pdf.text(info.data.counterpart_cpf_cnpj, 135, height);
      height += 15;
    }

    // if (info.data.counterpart_bank_code) {
    //   pdf.text('Nome:', 10, height);
    //   pdf.text(info.data.counterpart_bank_code, 135, height);
    //   height += 15;
    // }

    if (info.data.counterpart_account_type) {
      pdf.text('Tipo de Conta:', 10, height);
      pdf.text(accountTypes[info.data.counterpart_account_type], 135, height);
      height += 15;
    }

    if (info.data.counterpart_bank_branch) {
      pdf.text('Agência:', 10, height);
      pdf.text(info.data.counterpart_bank_branch, 135, height);
      height += 15;
    }

    if (info.data.counterpart_account) {
      pdf.text('Conta:', 10, height);
      pdf.text(accountMask(info.data.counterpart_account), 135, height);
      height += 15;
    }
  }
  // END PIX

  var alturaPadrao = 0;
  if (['internal_transfer'].includes(row.transaction_type)) {
    alturaPadrao = 250;
  } else if (['phone_recharge'].includes(row.transaction_type)) {
    alturaPadrao = 230;
  } else {
    alturaPadrao = 300;
  }

  // pdf.setFillColor('#000');
  // pdf.rect(8, alturaPadrao, 412, 2, 'F');

  // pdf.setFontSize(14);
  // var idTransacaoText = "ID da transação"
  // var xOffset3 = (pdf.internal.pageSize.width / 2) - (pdf.getStringUnitWidth(idTransacaoText) * pdf.getFontSize() / 2);
  // pdf.text(idTransacaoText, xOffset3+10, alturaPadrao + 20);

  // pdf.setFontSize(20);
  // var idTransacao = `${info.data.id}`;
  // var xOffset4 = (pdf.internal.pageSize.width / 2) - (pdf.getStringUnitWidth(idTransacao) * pdf.getFontSize() / 2);
  // pdf.text(idTransacao, xOffset4, alturaPadrao + 40);

  // pdf.setFillColor('#000');
  // pdf.rect(8, alturaPadrao + 50, 412, 2, 'F');

  pdf.setFontSize(11);
  pdf.text('Ouvidoria: ', 10, alturaPadrao + 65);
  pdf.text(emails[window.location.host] || '', 50, alturaPadrao + 65);

  pdf.save(
    `comprovante_${row.formatted_date
      .split(' - ')[0]
      .replace('/', '.')
      .replace('/', '.')}.pdf`
  );
};

export function TitleColumnFormatter(cellContent, row) {
  return (
    <div onClick={() => salvarPdf(row)} style={{ cursor: 'pointer' }}>
      <div className="text-center d-flex flex-column">
        <span>{row.title}</span>
        <span className="text-muted font-size-sm mt-3">
          <FormattedMessage
            id={`TRANSFER.TYPE.${row.transaction_type.toUpperCase()}`}
          />
        </span>
      </div>
    </div>
  );
}
