import { mask, unMask } from 'remask';
import VMasker from 'vanilla-masker';
import { format } from 'date-fns';

export const currency = (value) => {
    return (value).toLocaleString('pt-BR', {
        maximumFractionDigits: 2, 
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL'
    })
}

export const cpfMask = (value) => {
    return mask(value, ['999.999.999-99']);
};

export const cnpjMask = (value) => {
    return mask(value, ['99.999.999/9999-99']);
};

export const documentMask = (value) => {
    const valueWithoutMask = unMask(value);
    return mask(valueWithoutMask, ['999.999.999-99', '99.999.999/9999-99']);
}

export const phoneMask = (value) => {
    return mask(value, ['(99) 9 9999-9999']);
};

export const phoneMaskWithoutDDD = (value) => {
    return mask(value, ['9 9999-9999']);
};

export const cepMask = (value) => {
    return mask(value, ['99999-999']);
};

export const onlyNumbers = (value, quantity = 10) => {
    const repeatedNumbers = "9".repeat(quantity);
    return mask(value, [repeatedNumbers]);
}

export const accountMask = (value) => {
    const v = value.replace(/\D/g, '');
    if (v.length < 2) return v;
  
    const separator = '-';
    const position = v.length - 1;
    const output = [v.slice(0, position), separator, v.slice(position)].join('');
    return output;
};

export const dateMask = (value) => {
    return mask(value, ['99/99/9999']);
};

export const formatPrice = (price) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(price);
};

export const moneyMask = (value) => {
    return VMasker.toMoney(value, { unit: 'R$' });
};

export const billetMask = value => {
    const repeatedNumbers = "9".repeat(48);
    return mask(value, [repeatedNumbers]);
}

export const currencyMask = value => {
    let valor = value;
    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g,''));
    valor = valor + '';
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
        valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    return (valor === 'NaN') ? ('') : (valor)
}

export const removeMask = value => {
    return unMask(value);
}

export const removeCurrencyMask = value => {
    if (value === undefined) value = '0';
    if (value === 0) value += '';

    return value
        .replace('R$ ', '')
        .replace('.', '')
        .replace(',', '.')
}

export const formatDate = value => {
    return format(value, 'yyyy-MM-dd');
}