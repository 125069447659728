/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { connect } from "react-redux";
import * as auth from "../../pages/User/Auth/_redux/authRedux";

function QuickUserToggler(props) {
  const { user, account, requestUser } = props;
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
      colors: uiService.getColors(),
    };
  }, [uiService]);

  useEffect(() => {
    requestUser();
  }, [requestUser]);

  return (<>
    {layoutProps.offcanvas && (<OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="quick-user-tooltip">Perfil</Tooltip>}
    >
      <div className="topbar-item">
        <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          id="kt_quick_user_toggle">
          <>

            <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
              {user.name}
              <br />
              <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                {account.number}
              </span>
            </span>
            <span className="symbol symbol-35 symbol-light-light">
              <span className="symbol-label text-light-light font-size-h5 font-weight-bold"
                style={{
                  backgroundColor: layoutProps.colors.primary
                }}
              >{(user.name) ? (user.name[0]) : ('')}</span>
            </span>
          </>
        </div>
      </div>
    </OverlayTrigger>)}
  </>
  );
}


export default connect(
  ({ auth }) => ({
    user: auth.user,
    account: auth.account,
  }),
auth.actions)(QuickUserToggler);