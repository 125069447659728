import React, { useMemo } from "react";
import { DocumentFile } from "../../../../components/document/DocumentFile";
import * as fromState from "../_redux/actions";
import { connect } from "react-redux";
import { ButtonCustom } from "../../../../components/button/ButtonCustom";
import { useHtmlClassService } from "../../../../components";
import { useHistory } from "react-router-dom";

import { toAbsoluteUrl } from "../../../../../helpers";

function DocumentFiles(props) {
    const uiService = useHtmlClassService();
    const history = useHistory()
    const layoutProps = useMemo(() => {
        return {
            colors: uiService.getColors()
        };
    }, [uiService]);

    return (
        <div className="login-form login-signin" style={{ display: "block" }}>
            <div className="row ml-1">
                <DocumentFile 
                    title="RG ou CNH Frente"
                    subtitle="RG ou CNH (Com n° de CPF)"
                    icon="/media/svg/icons/Register/Person-CPF.svg"
                    name="file_document_front"
                    approved={props.file_document_front !== '' ? 4 : 3}
                    onChange={(e) => {
                        props.loadDocumentFront(e.target.files[0])
                    }}
                />
                <div className="modelDocument">
                    <h5 className="mt-2">
                        Modelo CNH Frente
                    </h5>
                    <img width="150" alt="imagem" src={toAbsoluteUrl('media/images/cnh_frente.jpg')} />
                </div>
            </div> 
            <div className="row ml-1">
                <DocumentFile
                    title="RG ou CNH Verso"
                    subtitle="RG ou CNH (Com n° de CPF)"
                    icon="/media/svg/icons/Register/Person-CPF-back.svg"
                    name="file_document_back"
                    approved={props.file_document_back !== '' ? 4 : 3}
                    onChange={(e) => {
                        props.loadDocumentBack(e.target.files[0])
                    }}
                />
                <div className="modelDocument">
                    <h5 className="mt-2">
                        Modelo CNH Verso
                    </h5>
                    <img width="150" alt="imagem" src={toAbsoluteUrl('media/images/cnh_verso.jpg')} />
                </div>
            </div>
            {(() => {
                if(!props.update) {
                    return (
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-5">
                                <ButtonCustom 
                                    layoutProps={layoutProps}
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                >
                                    Voltar
                                </ButtonCustom>
                            </div>
                        </div>
                    )
                }
            })()}
        </div>
    );
}

export default connect(({ register }) => ({
    file_document_front: register.file_document_front,
    file_document_back: register.file_document_back,
}), fromState.actions)(DocumentFiles);
