import { ENDPOINT } from "../../../../../services/enums/EndPoint";
import ApiServices from "../../../../../services/ApiServices";

const api = ApiServices;

/**
 * Retorna os depósitos realizados
 */
export function getAllDeposit(page) {
  return api.get(ENDPOINT.DEPOSIT, {
    params: {
      page: page
    }
  });
}

/**
 * Realiza um depósito
 */
export function makeDeposit(value) {
  return api.post(ENDPOINT.DEPOSIT, value);
}

/**
 * Retorna as informações do boleto de depósito
 */
export function findBill({ id }) {
  return api.get(`${ENDPOINT.DEPOSIT}/${id}`);
}

/**
 * Busca os débitos automáticos pendentes
 */
export function getAllDebits () {
  return api.get(`${ENDPOINT.USER_DEBITS}`);
}

/**
 * Busca a operação do débito automático
 */
export function getDebitsOperation (id) {
  return api.get(`${ENDPOINT.USER_DEBITS_OPERATION}/${id}${ENDPOINT.USER_DEBITS_INCLUDES}`);
}

/**
 * Atualiza a operação do débito automático
 */
export function resendDebitsOperation (id) {
  return api.put(`${ENDPOINT.OPERATION_SEND}/${id}${ENDPOINT.OPERATION_RESEND}`);
}

