import React from "react";
import './style.css';

export default function ContainerOverflow(props) {
    const style = {
        normal: {
        }
    };

    return (
        <div className="container-scroll"
            style={{
                ...style.normal
            }}
        >
            {props.children}
        </div>
    );
}
