import React, { useState, useMemo, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonCustom } from '../../../../../components/button/ButtonCustom';
import { useHtmlClassService } from '../../../../../components';
import { connect } from 'react-redux';
import * as fromState from './_redux/actions';
import { Card } from '../../../../../../partials/controls';

function PhoneSMSCode(props) {
  const { auth, verifyPhone, reSendCode } = props;

  const intl = useIntl();
  const [time, setTime] = useState(60);
  const [send, isSend] = useState(false);

  // let inputNumberOne = React.createRef();
  let inputNumberTwo = React.createRef();
  let inputNumberThree = React.createRef();
  let inputNumberFour = React.createRef();

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const RegistrationSchema = Yup.object().shape({
    number_one: Yup.string()
      .min(1, 'Minimun 1 symbol')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    number_two: Yup.string()
      .min(1, 'Minimun 1 symbol')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    number_three: Yup.string()
      .min(1, 'Minimun 1 symbol')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    number_four: Yup.string()
      .min(1, 'Minimun 1 symbol')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });

  useEffect(() => {
    if (time !== 0 && send) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [send, time]);

  const initialValues = {
    number_one: '',
    number_two: '',
    number_three: '',
    number_four: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: values => {
      values.id = auth.id;
      verifyPhone(values);
    },
  });

  const reSend = id => {
    reSendCode(id);
    setTime(60);
    isSend(true);
  };

  const maskNumber = number => {
    return `${number}`.replace(/\D/g, '').substr(0, 1);
  };

  return (
    <Card>
      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated 
				animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        <div className="card-body text-center border-0 pt-5">
          <h4>Verificação de telefone</h4>

          <p className="text-muted">
            Clique em enviar, para <br />
            receber o código de validação em ({auth.region_code}) {auth.number}.
          </p>
        </div>
        {(() => {
          if (!send) {
            return (
              <div className="form-group d-flex flex-wrap flex-center">
                <div className="col-md-10">
                  <ButtonCustom
                    layoutProps={layoutProps}
                    id="kt_login_forgot_submit"
                    type="button"
                    className="btn btn-primary font-weight-bold px-6 py-4 my-3 mx-4"
                    size="sm"
                    onClick={() => {
                      reSend(auth.id);
                    }}
                  >
                    <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                  </ButtonCustom>
                </div>
              </div>
            );
          }
        })()}

        {(() => {
          if (send) {
            return (
              <>
                <div className="row pt-10">
                  <div className="col-md-2 col-xs-1"></div>
                  <div className="col-md-2 col-xs-2">
                    <input
                      maxLength={1}
                      type="text"
                      className={`form-control text-center h-auto`}
                      name="number_one"
                      {...formik.getFieldProps('number_one')}
                      onChange={e => {
                        const number = maskNumber(e.target.value);
                        formik.setFieldValue('number_one', number);
                        if (number !== '') inputNumberTwo.focus();
                      }}
                    />
                  </div>

                  <div className="col-md-2 col-xs-2">
                    <input
                      maxLength="1"
                      type="text"
                      className={`form-control text-center h-auto`}
                      name="number_two"
                      ref={ref => (inputNumberTwo = ref)}
                      {...formik.getFieldProps('number_two')}
                      onChange={e => {
                        const number = maskNumber(e.target.value);
                        formik.setFieldValue('number_two', number);
                        if (number !== '') inputNumberThree.focus();
                      }}
                    />
                  </div>

                  <div className="col-md-2 col-xs-2">
                    <input
                      maxLength="1"
                      type="text"
                      className={`form-control text-center h-auto`}
                      name="number_three"
                      ref={ref => (inputNumberThree = ref)}
                      {...formik.getFieldProps('number_three')}
                      onChange={e => {
                        const number = maskNumber(e.target.value);
                        formik.setFieldValue('number_three', number);
                        if (number !== '') inputNumberFour.focus();
                      }}
                    />
                  </div>

                  <div className="col-md-2 col-xs-2">
                    <input
                      maxLength="1"
                      type="text"
                      className={`form-control text-center h-auto`}
                      name="number_four"
                      ref={ref => (inputNumberFour = ref)}
                      {...formik.getFieldProps('number_four')}
                      onChange={e => {
                        formik.setFieldValue(
                          'number_four',
                          maskNumber(e.target.value)
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="form-group text-center pt-3 fv-plugins-icon-container">
                  <p className="text-muted">Código SMS</p>
                </div>

                <div className="form-group text-center text-muted fv-plugins-icon-container">
                  <p>
                    <FormattedMessage id="AUTH.REGISTER.SMS.TITLE" />
                  </p>
                  <p className="font-weight-bold">
                    <FormattedMessage id="AUTH.REGISTER.SMS.SUBTITLE" />
                    <span
                      style={{
                        color: layoutProps.colors.primary,
                      }}
                    >
                      {time === 0 || time === 60 ? (
                        <button
                          className="btn"
                          type="button"
                          onClick={() => {
                            reSend(auth.id);
                          }}
                        >
                          Reenviar
                        </button>
                      ) : (
                          `${time}s`
                        )}
                    </span>
                  </p>
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                  <div className="col-md-10">
                    <ButtonCustom
                      layoutProps={layoutProps}
                      id="kt_login_forgot_submit"
                      type="submit"
                      className="btn btn-primary font-weight-bold px-6 py-4 my-3 mx-4"
                      size="sm"
                    >
                      <FormattedMessage id="AUTH.REGISTER.NEXT" />
                    </ButtonCustom>
                  </div>
                </div>
              </>
            );
          }
        })()}
      </form>
    </Card>
  );
}

export default connect(
  ({ phone, auth }) => ({
    phone: phone.phone,
    auth: auth.phone,
    isVerified: phone.isVerified,
  }),
  fromState.actions
)(PhoneSMSCode);
