import React from "react";
import {
  CustomerStatusCssClasses
} from "../Resume/ResumeUIHelpers";
import { FormattedMessage } from "react-intl";

export function TypeColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      CustomerStatusCssClasses[(row.type === 'debit') ? (0) : (1)]
    } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      <FormattedMessage
        id={`TRANSFER.CARD.TYPE.${row.type.toUpperCase()}`}
      />
    </span>
  );
}
