import { put, takeLatest } from "redux-saga/effects";
import { initialState, types, actions } from "./actions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from 'redux-persist';


export const reducer = persistReducer(
    { 
        storage, 
        key: "register-legal", 
        whitelist: ["basic", "personal", "financial", "initialized"] 
    },
    (state = initialState, action) => {
        switch (action.type) {

            case types.LegalRegisterLoaded: {
                const { register } = action.payload;
                return { ...state, basic: register, initialized: false };
            }

            case types.LegalPersonalRegisterLoaded: {
                const { register } = action.payload;
                return { ...state, personal: register };
            }

            case types.LegalFinancialRegisterLoaded: {
                const { register } = action.payload;
                return { ...state, financial: register };
            }

            case types.ClearLegal: {
                return {
                    basic: {}, 
                    personal: {}, 
                    financial: {}, 
                    initialized: true
                };
            }

            default:
                return state;
        }
    }
)

export function* saga() {
    yield takeLatest(types.LegalRegister, function* registerInformation(action) {
        const { register } = action.payload;
        yield put(actions.loadBasicInformation(register));
    });

    yield takeLatest(types.LegalPersonalRegister, function* registerPersonalInformation(action) {
        const { register } = action.payload;
        yield put(actions.loadPersonalInformation(register));
    });

    yield takeLatest(types.LegalFinancialRegister, function* registerFinancialInformation(action) {
        const { register } = action.payload;
        yield put(actions.loadFinancialInformation(register));
    });
}