import React from "react";
import {PaginationLinks} from "./PaginationLinks";

export function Pagination(props) {
  const { children, paginationProps } = props;
  return (
    <>
      {children}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <PaginationLinks paginationProps={paginationProps} />
      </div>
    </>
  );
}
