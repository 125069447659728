import { put, takeLatest } from "redux-saga/effects";
import { initialState, types, actions } from "./actions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from 'redux-persist';


export const reducer = persistReducer(
    { 
        storage, 
        key: "register", 
        whitelist: ["basic", "personal", "financial", "initialized"] 
    },
    (state = initialState, action) => {
        switch (action.type) {

            case types.BasicRegisterLoaded: {
                const { register } = action.payload;
                return { ...state, basic: register, initialized: true };
            }

            case types.PersonalRegisterLoaded: {
                const { register } = action.payload;
                return { ...state, personal: register };
            }

            case types.FinancialRegisterLoaded: {
                const { register } = action.payload;
                return { ...state, financial: register };
            }

            case types.ClearPhysical: {
                return {
                    basic: {}, 
                    personal: {}, 
                    financial: {}, 
                    initialized: false
                };
            }

            default:
                return state;
        }
    }
)

export function* saga() {
    yield takeLatest(types.BasicRegister, function* registerBasicInformation(action) {
        const { register } = action.payload;
        yield put(actions.loadBasicInformation(register));
    });

    yield takeLatest(types.PersonalRegister, function* registerBasicInformation(action) {
        const { register } = action.payload;
        yield put(actions.loadPersonalInformation(register));
    });

    yield takeLatest(types.FinancialRegister, function* registerBasicInformation(action) {
        const { register } = action.payload;
        yield put(actions.loadFinancialInformation(register));
    });
}