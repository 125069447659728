
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import { connect } from "react-redux";
import * as extract from "../../_redux/extract/extractRedux";

import * as uiHelpers from "../ExtractUIHelpers";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../../../helpers";
import * as columnFormatters from "../../_formatters";
import { Pagination } from "../../../../../partials/controls";
import { useExtractUIContext } from "../ExtractUIContext";
import { NotFound } from "../../../../components/message/NotFound";

function ExtractTable(props) {
  const { requestExtract, results, meta, listLoading } = props;

  // Products UI Context
  const extractUIContext = useExtractUIContext();
  const extractUIProps = useMemo(() => {
    return {
      ids: extractUIContext.ids,
      setIds: extractUIContext.setIds,
      queryParams: extractUIContext.queryParams,
      setQueryParams: extractUIContext.setQueryParams,
      openEditProductPage: extractUIContext.openEditProductPage,
      openDeleteProductDialog: extractUIContext.openDeleteProductDialog,
    };
  }, [extractUIContext]);

  // Redux state
  useEffect(() => {
    requestExtract(extractUIProps.queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestExtract, extractUIProps.queryParams]);

  // Table columns
  const columns = [
    {
      text: 'TIPO',
      align: 'center',
      headerAlign: 'center',
      dataField: "type",
      sort: false,
      formatter: columnFormatters.TypeColumnFormatter
    },
    {
      text: 'DATA',
      align: 'center',
      headerAlign: 'center',
      dataField: "formatted_date",
      sort: false,
      formatter: columnFormatters.DateColumnFormatter
    },
    {
      text: 'VALOR',
      align: 'center',
      headerAlign: 'center',
      dataField: "value",
      sort: false,
      formatter: columnFormatters.ValueColumnFormatter
    },
    {
      text: 'DESCRIÇÃO',
      align: 'center',
      headerAlign: 'center',
      dataField: "title",
      sort: false,
      formatter: columnFormatters.TitleColumnFormatter
    }
  ];
  
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: meta.pagination.total_count,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: meta.pagination.per_page,
    paginationSize: meta.pagination.total_pages,
    page: extractUIProps.queryParams.pageNumber
  };
  return (
    (() => {
      if (results.length > 0) {
        return (
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  isLoading={listLoading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField="uuid"
                    data={results === null ? [] : results}
                    columns={columns}
                    defaultSorted={uiHelpers.defaultSorted}
                    onTableChange={getHandlerTableChange(
                      extractUIProps.setQueryParams
                    )}
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={results} />
                    <NoRecordsFoundMessage entities={results} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        )
      } else {
        return <NotFound message="Nenhum dado encontrado no momento." />;
      }
    })()
  );
}

export default connect(
  ({ extract }) => ({ 
    results: extract.results,
    meta: extract.meta,
    listLoading: extract.listLoading
  }),
  extract.actions)(ExtractTable);