import React from 'react';

export function ButtonSelector(props) {
    const { hover } = props;

    const style = {
        normal: {
            background: '#fff',
            color: props.layoutProps.colors.primary
        },
        hover: {
            background: (props.secondary) ? 
            (props.layoutProps.colors.primary) : 
            (props.layoutProps.colors.secondary),
            color: '#fff'
        }
    };

    return (
        <button 
            id={props.id}
            style={{
                ...style.normal,
                ...(hover ? style.hover : null),
            }}
            onClick={props.onClick}
            type="button"
            size={(props.size ? props.size : "lg")}
            className={props.className}
        >
            {props.children}
        </button>
    )
}