/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../helpers";
import { DropdownTopbarItemToggler } from "../../../../partials/dropdowns";
import { FormattedMessage } from "react-intl";

import UserServices from '../../../../services/UserServices';

import { dateFormatted } from '../../../utils/date.utils';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown({history}) {
  const [key, setKey] = useState("Events");
  const [notificacoes, setNotificacoes] = useState([]);
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.notifications.layout") === "offcanvas",
      colors: uiService.getColors()
    };
  }, [uiService]);

  const loadMessages = async () => {
    let info = await UserServices.getNotifications();
    setNotificacoes(info.data.results);
  };

  useEffect(() => {
    loadMessages();
  }, []);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse"
            id="kt_quick_notifications_toggle"
          >
            <i className="flaticon2-notification"
              style={{
                color: layoutProps.colors.primary
              }}
            >
            </i>
            <span className="pulse-ring"
              style={{
                borderColor: layoutProps.colors.primary
              }}></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip"
                  style={{ color: "#000" }}
                >
                  <FormattedMessage id="USER.NOTIFICATION.TITLE" />
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse"
                id="kt_quick_notifications_toggle"
              >
                <i className="flaticon2-notification"
                  style={{
                    color: layoutProps.colors.primary
                  }}
                >
                </i>
                <span className="pulse-ring"
                  style={{
                    borderColor: layoutProps.colors.primary
                  }}
                >
                </span>
                <span className="pulse-ring" />
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-dark">
                    <FormattedMessage id="USER.NOTIFICATION.TITLE" /></span>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}
                  >
                  </Nav>

                  <Tab.Content className="tab-content">
                    <Tab.Pane
                      eventKey="Events"
                      id="topbar_notifications_events"
                    >
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="navi navi-hover scroll my-4"
                        style={{ maxHeight: "300px", position: "relative" }}
                      >

                      {notificacoes.length === 0 && (
                        <div className="navi-link">
                          <div className="navi-text">
                            <div className="text-muted text-center">
                              <span>Sem notificaçõesno momento.</span>
                            </div>
                          </div>
                        </div>                        
                      )}
 
                      {notificacoes && notificacoes.slice(0,4).map((not) => {
                        return (
                          <div className="navi-link" style={{
                            marginLeft: '20px',
                            marginBottom: '15px',
                            borderBottom: '1px solid #efefef',
                          }}>
                            <div className="navi-text">
                              <div className="text-muted text-left">
                                <span>{dateFormatted(not.created_at.split(' ')[0])} {not.created_at.split(' ')[1]}</span> <br />
                                <span>{not.data.title}</span>
                              </div>
                            </div>
                          </div>
                        )
                      })}

                      {notificacoes && (
                        <a
                          href="/notificacoes"
                          style={{
                            marginLeft: '20px',
                            cursor: 'pointer'
                          }}
                        >
                          Ver Todas
                        </a>
                      )}
                      </PerfectScrollbar>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
