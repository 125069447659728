import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export default function ModalNotice(props) {
  const { show, onHide, message, title, children, name, layoutProps, onSuccess } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <p className="text-align-center">
            <FormattedMessage id={title} />
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          {(() => {
            if (message !== '') {
              return (        
                <div className="timeline timeline-5 mt-3"> 
                  <FormattedMessage id={message} values={{ name: name}} />
                </div>
              )
            }
          })()}
        {children}
      </Modal.Body>
      <Modal.Footer>
        {
         (() => {
            if(props.success) {
              return (
                <button
                  type="button"
                  onClick={onSuccess}
                  className="btn btn-light-light btn-elevate ml-4"
                  style={{
                    backgroundColor: `${layoutProps.colors.primary}`,
                  }}
                >
                  Confirmar
                </button>
              )
            }
         })() 
        }
          <button
            type="button"
            onClick={onHide}
            className="btn btn-default btn-elevate ml-4"
          >
            Fechar
          </button>
      </Modal.Footer>
    </Modal>
  );
}
