import { put, takeLatest } from 'redux-saga/effects';
import { initialState, types, actions } from './actions';
import * as fromServer from './crud';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { HTTPCodes } from '../../../../../services/enums/HTTPCodes';
import {
  removeStorage,
  clearStorage,
} from '../../../../../helpers/LocalStorageHelpers';

export const reducer = persistReducer(
  { storage, key: 'register-docs', whitelist: ['documents', 'loadType'] },
  (state = initialState, action) => {
    switch (action.type) {
      case types.UserPersonalRegisterLoaded: {
        const { register } = action.payload;
        return { ...state, register: register, send: true };
      }

      case types.LoadSend: {
        return { ...state, send: false };
      }

      case types.LoadSuccessSend: {
        return { ...state, send: true };
      }

      case types.SelfieFile: {
        const { register } = action.payload;
        return { ...state, file_selfie: register };
      }

      case types.DocumentSelfieFile: {
        const { register } = action.payload;
        return { ...state, file_document_selfie: register };
      }

      case types.DocumentFrontFile: {
        const { register } = action.payload;
        return { ...state, file_document_front: register };
      }

      case types.DocumentBackFile: {
        const { register } = action.payload;
        return { ...state, file_document_back: register };
      }

      case types.ProofOfResidenceFile: {
        const { register } = action.payload;
        return { ...state, file_proof_of_residence: register };
      }

      case types.CompanyDocumentFile: {
        const { register } = action.payload;
        return { ...state, file_company_document: register };
      }

      case types.LoadDocuments: {
        const { register } = action.payload;
        return { ...state, documents: register };
      }

      case types.LoadType: {
        const { register } = action.payload;
        return { ...state, loadType: register };
      }

      case types.ErrorsLoaded: {
        return { ...state, error_fields: action.payload.error };
      }

      case types.ClearDocuments: {
        return {
          ...state,
          documents: {},
        };
      }

      default:
        return state;
    }
  }
);

export function* saga() {
  yield takeLatest(types.UserPersonalRegister, function* register(action) {
    const {
      register: { person, zipcode, phone, password, allBanks },
    } = yield action.payload;
    const params = {
      financial: person.financial,
      basic: person.basic,
      personal: person.personal,
      banks: allBanks.banks,
      password,
      phone,
      zipcode,
    };

    try {
      let data = {};
      let loadType = '';
      if (person.basic.cpf) {
        data = yield fromServer.registerPF(params);
        loadType = yield 'physical_type';
      } else {
        data = yield fromServer.registerPJ(params);
        loadType = yield 'legal_type';
      }

      yield clearStorage();
      yield put(actions.loadType(loadType));
      yield put(actions.load(data));
    } catch (err) {
      let message_error = '';

      switch (err.response.status) {
        case HTTPCodes.UNKNOWN_SERVER_ERROR:
          message_error = `[${err.response.status}] Serviço indisponível`;
          yield put(actions.fetchErrors([{ message: message_error }]));
          break;

        case HTTPCodes.FORBIDDEN:
          let {
            data: { message },
          } = err.response;
          message_error = `[${err.response.status}] ${message}`;
          yield put(actions.fetchErrors([{ message: message_error }]));

          break;

        default:
          let {
            data: { field_errors },
          } = err.response;
          yield put(actions.fetchErrors(Object.values(field_errors)[0]));
          break;
      }
    }
  });

  yield takeLatest(types.RegisterDocuments, function* register(action) {
    const { register } = yield action.payload;

    // Files
    let form = new FormData();
    if (register.file_proof_of_residence) {
      form.append('file_proof_of_residence', register.file_proof_of_residence);
    }

    if (register.file_company_document) {
      form.append('file_company_document', register.file_company_document);
    }

    if (register.file_selfie) {
      form.append('file_selfie', register.file_selfie);
    }
    if (register.file_document_selfie) {
      form.append('file_document_selfie', register.file_document_selfie);
    }
    if (register.file_document_front) {
      form.append('file_document_front', register.file_document_front);
    }
    if (register.file_document_back) {
      form.append('file_document_back', register.file_document_back);
    }
    // - - -

    try {
      const { data } = yield fromServer.sendDocuments(form);
      removeStorage('register-docs');
      yield put(actions.loadDocuments(data));
      yield put(actions.loadSuccessSend(true));
    } catch (err) {
      let message_error = '';

      switch (err.response.status) {
        case HTTPCodes.UNKNOWN_SERVER_ERROR:
          message_error = `[${err.response.status}] Serviço indisponível`;
          yield put(actions.fetchErrors([{ message: message_error }]));
          break;

        case HTTPCodes.FORBIDDEN:
          const {
            data: { message },
          } = err.response;
          message_error = `[${err.response.status}] ${message}`;
          yield put(actions.fetchErrors([{ message: message_error }]));

          break;

        case HTTPCodes.UNAUTHORIZED:
          let { data } = err.response;
          message_error = `[${err.response.status}] ${data.message}`;
          yield put(actions.fetchErrors([{ message: message_error }]));

          break;

        default:
          const {
            data: { field_errors },
          } = err.response;
          yield put(actions.fetchErrors(Object.values(field_errors)[0]));
          break;
      }
    }
  });

  yield takeLatest(types.UpdateDocuments, function* register(action) {
    const { register } = yield action.payload;
    // Files
    let form = new FormData();
    if (register.file_proof_of_residence) {
      form.append('file_proof_of_residence', register.file_proof_of_residence);
    }

    if (register.file_company_document) {
      form.append('file_company_document', register.file_company_document);
    }

    if (register.file_selfie) {
      form.append('file_selfie', register.file_selfie);
    }
    if (register.file_document_selfie) {
      form.append('file_document_selfie', register.file_document_selfie);
    }
    if (register.file_document_front) {
      form.append('file_document_front', register.file_document_front);
    }
    if (register.file_document_back) {
      form.append('file_document_back', register.file_document_back);
    }
    // - - -

    try {
      const { data } = yield fromServer.updateDocuments(form);
      removeStorage('register-docs');
      yield put(actions.loadDocuments(data));
      yield put(actions.loadSuccessSend(true));
    } catch (err) {
      let message_error = '';

      switch (err.response.status) {
        case HTTPCodes.UNKNOWN_SERVER_ERROR:
          message_error = `[${err.response.status}] Serviço indisponível`;
          yield put(actions.fetchErrors([{ message: message_error }]));
          break;

        case HTTPCodes.FORBIDDEN:
          const { data } = yield fromServer.sendDocuments(form);
          yield put(actions.loadDocuments(data));
          break;

        default:
          const {
            data: { field_errors },
          } = err.response;
          yield put(actions.fetchErrors(Object.values(field_errors)[0]));
          break;
      }
    }
  });
}
