import React, { useMemo, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';
import { useHtmlClassService } from '../../../../components';
import { connect } from 'react-redux';

import { Card, CardHeader, CardBody } from '../../../../../partials/controls';

//import * as fromState from './_redux/actions';
import * as fromPhone from '../../../User/Register/Verification/PhoneNumber/_redux/actions';

import { phoneNumberFormatted } from '../../../../utils/phone.utils';

function PhoneForm(props) {
  const {
    phone,
    phoneAuth,
    updatePhone,
    clearUpdatePhone,
    verifyUpdatePhone,
  } = props;
  const [send, isSend] = useState(false);
  const intl = useIntl();

  const uiService = useHtmlClassService();

  let inputNumberTwo = React.createRef();
  let inputNumberThree = React.createRef();
  let inputNumberFour = React.createRef();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  useEffect(() => {
    clearUpdatePhone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RegistrationSchema = Yup.object().shape({
    phone_number: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .phone(
        intl.formatMessage({
          id: 'FIELD.INVALID.PHONE',
        })
      ),
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const initialValues = {
    phone_number: '',
    number_one: '',
    number_two: '',
    number_three: '',
    number_four: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: values => {
      if (send) {
        values.id = phone.updatePhone.id;
        verifyUpdatePhone(values);
      } else {
        clearUpdatePhone();

        values = {
          country_code: '55',
          region_code: `${values.phone_number}`.replace(/\D/g, '').substr(0, 2),
          number: `${values.phone_number}`.replace(/\D/g, '').substr(2),
        };

        updatePhone(values);
      }
    },
  });

  useEffect(() => {
    isSend(phone.updatePhone.id);
  }, [phone]);

  useEffect(() => {
    if (send) console.log('mudouu');
  }, [send]);

  const maskNumber = number => {
    return `${number}`.replace(/\D/g, '').substr(0, 1);
  };

  return (
    <Card className="col-xs-12 col-xl-12 col-md-12">
      <CardHeader title="Alterar meu celular" />
      <CardBody>
        {send ? (
          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated 
              animate__animated animate__backInUp"
            onSubmit={formik.handleSubmit}
          >
            <div className="row pt-10">
              <div className="col-md-2 col-xs-1"></div>
              <div className="col-md-2 col-xs-2">
                <input
                  maxLength={1}
                  type="text"
                  className={`form-control text-center h-auto`}
                  name="number_one"
                  {...formik.getFieldProps('number_one')}
                  onChange={e => {
                    const number = maskNumber(e.target.value);
                    formik.setFieldValue('number_one', number);
                    if (number !== '') inputNumberTwo.focus();
                  }}
                />
              </div>

              <div className="col-md-2 col-xs-2">
                <input
                  maxLength="1"
                  type="text"
                  className={`form-control text-center h-auto`}
                  name="number_two"
                  ref={ref => (inputNumberTwo = ref)}
                  {...formik.getFieldProps('number_two')}
                  onChange={e => {
                    const number = maskNumber(e.target.value);
                    formik.setFieldValue('number_two', number);
                    if (number !== '') inputNumberThree.focus();
                  }}
                />
              </div>

              <div className="col-md-2 col-xs-2">
                <input
                  maxLength="1"
                  type="text"
                  className={`form-control text-center h-auto`}
                  name="number_three"
                  ref={ref => (inputNumberThree = ref)}
                  {...formik.getFieldProps('number_three')}
                  onChange={e => {
                    const number = maskNumber(e.target.value);
                    formik.setFieldValue('number_three', number);
                    if (number !== '') inputNumberFour.focus();
                  }}
                />
              </div>

              <div className="col-md-2 col-xs-2">
                <input
                  maxLength="1"
                  type="text"
                  className={`form-control text-center h-auto`}
                  name="number_four"
                  ref={ref => (inputNumberFour = ref)}
                  {...formik.getFieldProps('number_four')}
                  onChange={e => {
                    formik.setFieldValue(
                      'number_four',
                      maskNumber(e.target.value)
                    );
                  }}
                />
              </div>
            </div>

            <div className="form-group text-center pt-3 fv-plugins-icon-container">
              <p className="text-muted">Código SMS</p>
            </div>

            <div className="form-group d-flex flex-wrap flex-center">
              <div className="col-md-10">
                <ButtonCustom
                  layoutProps={layoutProps}
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-6 py-4 my-3 mx-4"
                  size="sm"
                >
                  <FormattedMessage id="AUTH.REGISTER.NEXT" />
                </ButtonCustom>
              </div>
            </div>
          </form>
        ) : (
          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated 
                        animate__animated animate__backInUp"
            onSubmit={formik.handleSubmit}
          >
            {/* begin: Fullname */}
            <div className="form-group fv-plugins-icon-container">
              <label className="text-muted">
                Número atual:{' '}
                {phoneNumberFormatted(phoneAuth.region_code + phoneAuth.number)}
              </label>
              <br />
              <label className="text-muted">Novo número de celular:</label>
              <input
                maxLength="15"
                placeholder="(00) 00000-0000"
                type="text"
                className={`form-control h-auto py-5 px-6 
                                ${getInputClasses('phone_number')}`}
                name="phone_number"
                {...formik.getFieldProps('phone_number')}
                onChange={e => {
                  formik.setFieldValue(
                    'phone_number',
                    phoneNumberFormatted(e.target.value)
                  );
                }}
              />

              {formik.touched.phone_number && formik.errors.phone_number ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.phone_number}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Fullname */}

            <div className="row">
              <div className="col-md-12 col-md-offset-2">
                <ButtonCustom
                  layoutProps={layoutProps}
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary btn-block btn-lg"
                >
                  Alterar meu telefone
                </ButtonCustom>
              </div>
            </div>
          </form>
        )}
      </CardBody>
    </Card>
  );
}

export default connect(
  ({ phone, auth }) => ({
    phone,
    phoneAuth: auth.phone,
  }),
  fromPhone.actions
)(PhoneForm);
