import React from 'react';
import { Subaside } from '../../../components/subaside/Subaside';
import { Card, CardBody } from '../../../../partials/controls';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import * as auth from '../../../pages/User/Auth/_redux/authRedux';

const useStyles = makeStyles(theme => ({
  accountBox: {
    marginBottom: '25px',
    marginTop: '30px',
  },

  head: {
    color: 'black',
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 'bold',
  },

  titleRight: {
    fontWeight: 'bold',
    fontSize: '12px',
    width: '40%',
    textAlign: 'center',
  },

  title: {
    color: 'gray',
    fontWeight: 'bold',
    fontSize: '12px',
    width: '60%',
    textAlign: 'left',
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '10px',
    paddingBottom: '5px',
  },

  column: {
    flexDirection: 'column',
    display: 'flex',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '10px',
    paddingBottom: '5px',
  },

  subTitle: {
    color: theme.primary,
    fontSize: '13px',
    fontWeight: 'bold',
    width: '40%',
  },
  subTitleLeft: {
    color: 'gray',
    fontSize: '13px',
    width: '40%',
  },
  boxButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex',
  },
  button: {
    backgroundColor: '#004571',
    border: '1px solid #4C1BA9',
    borderRadius: '10px',
    fontFamily: 'Poppins',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '10px 20px',
  },
  buttonText: {
    color: 'white',
    fontSize: '14px',
  },
}));

function SupportPage(props) {
  const { settings } = props;
  const classes = useStyles();
  console.log(settings);

  return (
    <div className="row">
      <Subaside />
      {settings.support !== undefined && (
        <div className="col-md-8 col-xs-12 col-lg-8">
          <Card className="col-xs-12 col-xl-12 col-md-12">
            <CardBody>
              <h4 className={classes.head}>Suporte</h4>

              <div className={classes.accountBox}>
                <div className={classes.column}>
                  <span className={classes.title}>
                    Horário de Funcionamento
                  </span>
                  <span
                    style={{ color: settings.js.colors.theme.base.primary }}
                    className={classes.subTitle}
                  >
                    {settings.support.hours}
                  </span>
                </div>

                <div className={classes.column}>
                  <span className={classes.subTitleLeft}>
                    Suporte via e-mail
                  </span>

                  <a
                    href={`mailto::${settings.support.mail}`}
                    style={{ color: settings.js.colors.theme.base.primary }}
                    className={classes.subTitle}
                  >
                    {settings.support.mail}
                  </a>
                </div>

                <div className={classes.column}>
                  <span className={classes.subTitleLeft}>
                    Suporte via telefone
                  </span>

                  <span
                    style={{ color: settings.js.colors.theme.base.primary }}
                    className={classes.subTitle}
                  >
                    {settings.support.phone}
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>

          <div className={classes.boxButton}>
            <span className={classes.button}>
              <a
                href={settings.support.url}
                className={classes.buttonText}
                rel="noopener noreferrer"
                target="_blank"
              >
                Acessar Central de Atendimento
              </a>
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default connect(
  ({ auth, settings }) => ({
    user: auth.limits,
    success: auth.success,
    account: auth.account,
    settings: settings.settings,
  }),
  auth.actions
)(SupportPage);
