import * as requestFromServer from './crud';
import { put, takeLatest } from "redux-saga/effects";
import { initialState, types, actions } from "./actions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from 'redux-persist';
import { removeFormatter } from "../../../../../../utils/phone.utils";
import UserServices from '../../../../../../../services/UserServices';

export const reducer = persistReducer(
    {
        storage,
        key: "register-phone",
        whitelist: ["phone"]
    },
    (state = initialState, action) => {
        switch (action.type) {

            case types.PhoneRegisterLoaded: {
                const { register } = action.payload;
                return { ...state, phone: register, isVerified: register.verified };
            }

            case types.PhoneVerifyLoaded: {
                return { ...state, verified: true };
            }

            case types.ClearPhone: {
                return { phone: {}, verified: false };
            }

            case types.LoadIsVerified: {
                return { ...state, isVerified: false };
            }

            case types.UpdatePhoneFetch: {
                const updatePhone = action.payload.updatePhone;
                return { ...state, updatePhone };
            }

            case types.UpdatePhoneClear: {
                return { ...state, phoneUpdate: {} };
            }

            case types.ErrorsLoaded: {
                return { ...state, error_fields: action.payload.error };
            }

            default:
                return state;
        }
    })

export function* saga() {
    yield takeLatest(types.PhoneRegister, function* registerPhone(action) {
        const { register } = action.payload;
        let number = yield removeFormatter(register.phone_number);
        const { data } = yield requestFromServer.createPhone(number);
        yield put(actions.loadPhone(data));
    });

    yield takeLatest(types.PhoneVerify, function* verifyPhone(action) {
        const { register } = yield action.payload;
        const code = {
            code: `${register.number_one}${register.number_two}${register.number_three}${register.number_four}`,
            id: register.id
        }
        try {
            const data = yield requestFromServer.verifyPhone(code);
            yield put(actions.loadPhone(data.data));
            window.location.reload('true');
        } catch (error) {
            alert(error.response.data.message);
        }
    });

    yield takeLatest(types.PhoneReSend, function* resendCode(action) {
        const { register } = yield action.payload;
        const code = {
            id: register
        }
        const { data } = yield requestFromServer.resendCode(code);
        yield put(actions.loadPhone(data));
    });

    yield takeLatest(types.UpdatePhone, function* updatePhone(action) {
        try {
            const result = yield UserServices.changePhone(action.payload.updatePhone);
            yield put(
                actions.fetchUpdatePhone(result.data)
            );
        } catch (err) {
            switch (err.response.status) {
                default:
                    const {
                        data: { field_errors },
                    } = err.response;
                    yield put(actions.fetchErrors(Object.values(field_errors)[0]));

                    break;
            }
        }
    });

    yield takeLatest(types.UpdatePhoneVerify, function* verifyUpdatePhone(action) {
        try {
            const { code } = yield action.payload;

            const values = {
                code: `${code.number_one}${code.number_two}${code.number_three}${code.number_four}`,
                id: code.id
            }
            console.log(values);
            yield requestFromServer.verifyPhone(values);

            window.location.reload(true);
        } catch (err) {
            switch (err.response.status) {
                default:
                    const {
                        data: { field_errors },
                    } = err.response;
                    yield put(actions.fetchErrors(Object.values(field_errors)[0]));

                    break;
            }
        }
    });
}