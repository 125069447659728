import { ENDPOINT } from './enums/EndPoint';
import ApiServices from './ApiServices';

/**
 * Efetua o login do usuário
 * @param {string} email
 * @param {string} password
 * @return Promise
 */
const login = (email, password) => {
  return ApiServices.post(ENDPOINT.LOGIN, { email, password });
};

/**
 * Busca os dados de perfil do usuário.
 * @return Promise
 */
const getUserByToken = () => {
  return ApiServices.get(ENDPOINT.USER_PROFILE);
};

/**
 * Busca o endereço do usuário
 * @return Promise
 */
const getAddress = () => {
  return ApiServices.get(ENDPOINT.USER_ADDRESS);
};

/**
 * Busca os dados da conta do usuário,
 * incluindo devices e dados financeiros.
 * @return Promise
 */
const getAccount = () => {
  return ApiServices.get(ENDPOINT.USER_ACCOUNT);
};

/**
 * Altera o endereço do usuário
 * @return Promise
 */
const changeAddress = ({ address }) => {
  return ApiServices.put(ENDPOINT.USER_ADDRESSES, address);
};

/**
 * Altera o e-mail do usuário
 * @return Promise
 */
const changeEmail = ({ user }) => {
  return ApiServices.put(ENDPOINT.USER_EMAIL, { email: user.email });
};

/**
 * Encaminha um e-mail para alteração da senha de acesso
 * @return Promise
 */
const sendEmail = params => {
  return ApiServices.put(ENDPOINT.USER_PASSWORD_RESET, params);
};

/**
 * Salva o novo telefone e envia código para o celular
 * @return Promise
 */
const changePhone = params => {
  return ApiServices.post(ENDPOINT.USER_PHONE, params);
};

/**
 * Busca a conta no provedor
 * @return Promise
 */
const getAccountProvider = () => {
  return ApiServices.get(ENDPOINT.USER_ACCOUNT_PROVIDER);
};

/**
 * Busca a conta no provedor
 * @return Promise
 */
 const getNotifications = () => {
  return ApiServices.get(ENDPOINT.USER_NOTIFICATIONS);
};

const UserServices = {
  login,
  getUserByToken,
  getAddress,
  getAccount,
  changeAddress,
  changeEmail,
  sendEmail,
  changePhone,
  getAccountProvider,
  getNotifications,
};

export default UserServices;
