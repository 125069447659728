import React from 'react';
import { Subaside } from '../../../components/subaside/Subaside';
import { Card, CardBody } from '../../../../partials/controls';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import * as auth from '../../../pages/User/Auth/_redux/authRedux';
import { formatPrice } from '../../../utils/currency.utils';

const useStyles = makeStyles(theme => ({
  accountBox: {
    marginBottom: '15px',
  },
  box: {
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  row: {
    borderBottom: '1px solid #dedede',
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '10px',
    paddingBottom: '5px',
  },
  title: {
    color: '#4C4E67',
    fontWeight: 'bold',
    fontSize: '14px',
    width: '40%',
    textAlign: 'center',
  },
  titleLeft: {
    color: '#4C4E67',
    fontWeight: 'bold',
    fontSize: '14px',
    width: '40%',
    textAlign: 'left',
  },
  subTitle: {
    color: '#4C4E67',
    fontSize: '13px',
    width: '40%',
    textAlign: 'center',
  },
  subTitleLeft: {
    color: '#4C4E67',
    fontSize: '13px',
    width: '40%',
    textAlign: 'left',
  },
  button: {
    width: '300px',
    height: '37px',
    border: '1px solid #4C1BA9',
    borderRadius: '10px',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '45px',
  },
  buttonText: {
    color: '#4C1BA9',
    fontSize: '14px',
  },
  boxMensage: {
    marginTop: '10px',
  },
}));

function LimitsPage(props) {
  const { user } = props;

  const classes = useStyles();

  return (
    <div className="row">
      <Subaside />
      <div className="col-md-8 col-xs-12 col-lg-8">
        <Card className="col-xs-12 col-xl-12 col-md-12">
          <CardBody>
            <h4>Tarifas e Limites</h4>
            {user.bank_transfer !== undefined && (
              <div className={classes.accountBox}>
                <div className={classes.box}>
                  <div className={classes.row}>
                    <span className={classes.titleLeft}>Limites</span>
                    <span className={classes.title}>Dia</span>
                    <span className={classes.title}>Mês</span>
                  </div>
                  <div className={classes.row}>
                    <span className={classes.subTitleLeft}>Transferências</span>
                    <span className={classes.subTitle}>
                      {formatPrice(user.bank_transfer.daily_value)}
                    </span>
                    <span className={classes.subTitle}>
                      {formatPrice(user.bank_transfer.monthly_value)}
                    </span>
                  </div>
                  <div className={classes.row}>
                    <span className={classes.subTitleLeft}>
                      Transferências recebidas
                    </span>
                    <span className={classes.subTitle}>
                      {formatPrice(user.bank_transfer_received.daily_value)}
                    </span>
                    <span className={classes.subTitle}>
                      {formatPrice(user.bank_transfer_received.monthly_value)}
                    </span>
                  </div>
                  <div className={classes.row}>
                    <span className={classes.subTitleLeft}>
                      Pagamento de contas
                    </span>
                    <span className={classes.subTitle}>
                      {formatPrice(user.bill_payment.daily_value)}
                    </span>
                    <span className={classes.subTitle}>
                      {formatPrice(user.bill_payment.monthly_value)}
                    </span>
                  </div>
                  <div className={classes.row}>
                    <span className={classes.subTitleLeft}>Depósito</span>
                    <span className={classes.subTitle}>
                      {formatPrice(user.deposit.daily_value)}
                    </span>
                    <span className={classes.subTitle}>
                      {formatPrice(user.deposit.monthly_value)}
                    </span>
                  </div>
                  <div className={classes.row}>
                    <span className={classes.subTitleLeft}>
                      Transferência interna
                    </span>
                    <span className={classes.subTitle}>
                      {formatPrice(user.internal_transfer.daily_value)}
                    </span>
                    <span className={classes.subTitle}>
                      {formatPrice(user.internal_transfer.monthly_value)}
                    </span>
                  </div>
                  <div className={classes.row}>
                    <span className={classes.subTitleLeft}>
                      Recarga de celular
                    </span>
                    <span className={classes.subTitle}>
                      {formatPrice(user.phone_recharge.daily_value)}
                    </span>
                    <span className={classes.subTitle}>
                      {formatPrice(user.phone_recharge.monthly_value)}
                    </span>
                  </div>
                </div>
                <div className={classes.box}>
                  <div className={classes.row}>
                    <span className={classes.titleLeft}>Taxas</span>
                    <span className={classes.title}>Normal</span>
                    <span className={classes.title}>Nominal</span>
                  </div>
                  <div className={classes.row}>
                    <span className={classes.subTitleLeft}>Transferências</span>
                    <span className={classes.subTitle}>
                      {user.bank_transfer.fee_amount > 0 &&
                        formatPrice(user.bank_transfer.fee_amount)}
                      {user.bank_transfer.fee_amount > 0 &&
                        user.bank_transfer.fee_percent > 0 &&
                        ` + `}
                      {user.bank_transfer.fee_percent > 0 &&
                        `${user.bank_transfer.fee_percent}%`}
                      {user.bank_transfer.fee_amount > 0 ||
                      user.bank_transfer.fee_percent > 0
                        ? ``
                        : 'R$ 0,00'}
                    </span>
                    <span className={classes.subTitle}>
                      {user.bank_transfer.fee_amount_nominal > 0 &&
                        formatPrice(user.bank_transfer.fee_amount_nominal)}
                      {user.bank_transfer.fee_amount_nominal > 0 &&
                        user.bank_transfer.fee_percent_nominal > 0 &&
                        ` + `}
                      {user.bank_transfer.fee_percent_nominal > 0 &&
                        `${user.bank_transfer.fee_percent_nominal}%`}
                      {user.bank_transfer.fee_amount_nominal > 0 ||
                      user.bank_transfer.fee_percent_nominal > 0
                        ? ``
                        : 'R$ 0,00'}
                    </span>
                  </div>
                  <div className={classes.row}>
                    <span className={classes.subTitleLeft}>
                      Transferências recebidas
                    </span>
                    <span className={classes.subTitle}>
                      {user.bank_transfer_received.fee_amount > 0 &&
                        formatPrice(user.bank_transfer_received.fee_amount)}
                      {user.bank_transfer_received.fee_amount > 0 &&
                        user.bank_transfer_received.fee_percent > 0 &&
                        ` + `}
                      {user.bank_transfer_received.fee_percent > 0 &&
                        `${user.bank_transfer_received.fee_percent}%`}
                      {user.bank_transfer_received.fee_amount > 0 ||
                      user.bank_transfer_received.fee_percent > 0
                        ? ``
                        : 'R$ 0,00'}
                    </span>
                    <span className={classes.subTitle}>
                      {user.bank_transfer_received.fee_amount_nominal > 0 &&
                        formatPrice(
                          user.bank_transfer_received.fee_amount_nominal
                        )}
                      {user.bank_transfer_received.fee_amount_nominal > 0 &&
                        user.bank_transfer_received.fee_percent_nominal > 0 &&
                        ` + `}
                      {user.bank_transfer_received.fee_percent_nominal > 0 &&
                        `${user.bank_transfer_received.fee_percent_nominal}%`}
                      {user.bank_transfer_received.fee_amount_nominal > 0 ||
                      user.bank_transfer_received.fee_percent_nominal > 0
                        ? ``
                        : 'R$ 0,00'}
                    </span>
                  </div>
                  <div className={classes.row}>
                    <span className={classes.subTitleLeft}>
                      Pagamento de contas
                    </span>
                    <span className={classes.subTitle}>
                      {user.bill_payment.fee_amount > 0 &&
                        formatPrice(user.bill_payment.fee_amount)}
                      {user.bill_payment.fee_amount > 0 &&
                        user.bill_payment.fee_percent > 0 &&
                        ` + `}
                      {user.bill_payment.fee_percent > 0 &&
                        `${user.bill_payment.fee_percent}%`}
                      {user.bill_payment.fee_amount > 0 ||
                      user.bill_payment.fee_percent > 0
                        ? ``
                        : 'R$ 0,00'}
                    </span>
                    <span className={classes.subTitle}>
                      {user.bill_payment.fee_amount_nominal > 0 &&
                        formatPrice(user.bill_payment.fee_amount_nominal)}
                      {user.bill_payment.fee_amount_nominal > 0 &&
                        user.bill_payment.fee_percent_nominal > 0 &&
                        ` + `}
                      {user.bill_payment.fee_percent_nominal > 0 &&
                        `${user.bill_payment.fee_percent_nominal}%`}
                      {user.bill_payment.fee_amount_nominal > 0 ||
                      user.bill_payment.fee_percent_nominal > 0
                        ? ``
                        : 'R$ 0,00'}
                    </span>
                  </div>
                  <div className={classes.row}>
                    <span className={classes.subTitleLeft}>Depósito</span>
                    <span className={classes.subTitle}>
                      {user.deposit.fee_amount > 0 &&
                        formatPrice(user.deposit.fee_amount)}
                      {user.deposit.fee_amount > 0 &&
                        user.deposit.fee_percent > 0 &&
                        ` + `}
                      {user.deposit.fee_percent > 0 &&
                        `${user.deposit.fee_percent}%`}
                      {user.deposit.fee_amount > 0 ||
                      user.deposit.fee_percent > 0
                        ? ``
                        : 'R$ 0,00'}
                    </span>
                    <span className={classes.subTitle}>
                      {user.deposit.fee_amount_nominal > 0 &&
                        formatPrice(user.deposit.fee_amount_nominal)}
                      {user.deposit.fee_amount_nominal > 0 &&
                        user.deposit.fee_percent_nominal > 0 &&
                        ` + `}
                      {user.deposit.fee_percent_nominal > 0 &&
                        `${user.deposit.fee_percent_nominal}%`}
                      {user.deposit.fee_amount_nominal > 0 ||
                      user.deposit.fee_percent_nominal > 0
                        ? ``
                        : 'R$ 0,00'}
                    </span>
                  </div>
                  <div className={classes.row}>
                    <span className={classes.subTitleLeft}>
                      Transferência interna
                    </span>
                    <span className={classes.subTitle}>
                      {user.internal_transfer.fee_amount > 0 &&
                        formatPrice(user.internal_transfer.fee_amount)}
                      {user.internal_transfer.fee_amount > 0 &&
                        user.internal_transfer.fee_percent > 0 &&
                        ` + `}
                      {user.internal_transfer.fee_percent > 0 &&
                        `${user.internal_transfer.fee_percent}%`}
                      {user.internal_transfer.fee_amount > 0 ||
                      user.internal_transfer.fee_percent > 0
                        ? ``
                        : 'R$ 0,00'}
                    </span>
                    <span className={classes.subTitle}>
                      {user.internal_transfer.fee_amount_nominal > 0 &&
                        formatPrice(user.internal_transfer.fee_amount_nominal)}
                      {user.internal_transfer.fee_amount_nominal > 0 &&
                        user.internal_transfer.fee_percent_nominal > 0 &&
                        ` + `}
                      {user.internal_transfer.fee_percent_nominal > 0 &&
                        `${user.internal_transfer.fee_percent_nominal}%`}
                      {user.internal_transfer.fee_amount_nominal > 0 ||
                      user.internal_transfer.fee_percent_nominal > 0
                        ? ``
                        : 'R$ 0,00'}
                    </span>
                  </div>
                  <div className={classes.row}>
                    <span className={classes.subTitleLeft}>
                      Recarga de celular
                    </span>
                    <span className={classes.subTitle}>
                      {user.phone_recharge.fee_amount > 0 &&
                        formatPrice(user.phone_recharge.fee_amount)}
                      {user.phone_recharge.fee_amount > 0 &&
                        user.phone_recharge.fee_percent > 0 &&
                        ` + `}
                      {user.phone_recharge.fee_percent > 0 &&
                        `${user.phone_recharge.fee_percent}%`}
                      {user.phone_recharge.fee_amount > 0 ||
                      user.phone_recharge.fee_percent > 0
                        ? ``
                        : 'R$ 0,00'}
                    </span>
                    <span className={classes.subTitle}>
                      {user.phone_recharge.fee_amount_nominal > 0 &&
                        formatPrice(user.phone_recharge.fee_amount_nominal)}
                      {user.phone_recharge.fee_amount_nominal > 0 &&
                        user.phone_recharge.fee_percent_nominal > 0 &&
                        ` + `}
                      {user.phone_recharge.fee_percent_nominal > 0 &&
                        `${user.phone_recharge.fee_percent_nominal}%`}
                      {user.phone_recharge.fee_amount_nominal > 0 ||
                      user.phone_recharge.fee_percent_nominal > 0
                        ? ``
                        : 'R$ 0,00'}
                    </span>
                  </div>
                </div>

                <div className={classes.boxMensage}>
                  <span>
                    Em caso de dúvidas ou se você deseja alterar os limites
                    definidos, por favor contate o nosso suporte.
                  </span>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default connect(
  ({ auth }) => ({
    user: auth.limits,
    success: auth.success,
    account: auth.account,
  }),
  auth.actions
)(LimitsPage);
