
import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";

export const Progress = (props) => {
    
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colors: uiService.getColors()
        };
    }, [uiService]);
        
    return (<>
        {(() => {
            if(props.percent > 0) {
                return (
                    <div className="column border-0 col-xs-12 col-xl-12 col-md-12">
                        <h5>{props.percent}% completo</h5>
                        <div className="progress" 
                        style={{
                            backgroundColor: '#f5f5f5',
                        }}>
                        <div role="progressbar" className="progress-bar" 
                            aria-valuenow={props.percent} 
                            aria-valuemin="0" 
                            aria-valuemax="100" 
                            style={{ 
                                width: `${props.percent}%`, 
                                backgroundColor: layoutProps.colors.primary 
                            }}>
                        </div>
                        </div>
                    </div>
                )
            }
        })()}
    </>);
}
