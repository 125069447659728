import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import * as fromAuth from '../../User/Auth/_redux/authRedux';
import * as fromPhone from '../../User/Register/Verification/PhoneNumber/_redux/actions';

import { ProfileUIProvider } from './ProfileUIContext';
import EmailForm from './form/EmailForm';
import AddressForm from './form/AddressForm';
import PhoneForm from './form/PhoneForm';
import { Subaside } from '../../../components/subaside/Subaside';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { Success, Fail } from '../../User/Register/Documents';
import PhoneSMSCode from '../../User/Register/Verification/PhoneNumber/PhoneSMSCode';
import { bindActionCreators } from 'redux';
import ErrorFields from '../../../components/alert/ErrorFields';

function ProfilePage(props) {
  const { history, financialSecurity, phone, error_fields, actions } = props;
  const profileUIEvents = {
    openFetchProfileDialog: () => {
      history.push(`/conta/meus-dados/fetch`);
    },
  };

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  return (
    <div className="row">
      {/** begin::ModalError */}
      <ErrorFields
        error_fields={error_fields}
        show={error_fields !== '' ? true : false}
        onHide={() => {
          actions.fetchErrors('');
          window.location.reload(true);
        }}
      />
      {/** end::ModalError */}

      {(() => {
        if (phone.verified === false) {
          return (
            <>
              <div className="col-md-2 col-xs-2 col-lg-2"></div>
              <div className="col-md-8 col-xs-12 col-lg-8">
                <PhoneSMSCode />
                <PhoneForm layoutProps={layoutProps} />
              </div>
            </>
          );
        }

        if (financialSecurity.status === 'analyzing') {
          return (
            <>
              <div className="col-md-2 col-xs-2 col-lg-2"></div>
              <div className="col-md-8 col-xs-12 col-lg-8">
                <Success hasRedirectToDashboard />
              </div>
            </>
          );
        }

        if (
          financialSecurity.status === 'for_correction' ||
          !financialSecurity.status
        ) {
          return (
            <>
              <div className="col-md-2 col-xs-0 col-lg-2"></div>
              <div className="col-md-7 col-xs-12 col-lg-7">
                <Fail
                  documents={
                    financialSecurity.documents
                      ? financialSecurity.documents
                      : undefined
                  }
                />
              </div>
            </>
          );
        }

        return (
          <>
            <Subaside />
            <ProfileUIProvider profileUIEvents={profileUIEvents}>
              <div className="col-md-8 col-xs-12 col-lg-8">
                <EmailForm layoutProps={layoutProps} />
                <AddressForm layoutProps={layoutProps} />
                <PhoneForm layoutProps={layoutProps} />
              </div>
            </ProfileUIProvider>
          </>
        );
      })()}
    </div>
  );
}

export default connect(
  ({ auth, phone }) => ({
    financialSecurity: auth.financial,
    phone: auth.phone,
    request: phone.phone,
    error_fields: phone.error_fields,
  }),
  dispatch => {
    return {
      actions: bindActionCreators(
        Object.assign({}, fromAuth.actions, fromPhone.actions),
        dispatch
      ),
    };
  }
)(ProfilePage);
