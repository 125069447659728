import React, { Suspense } from "react";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../components";

import { PhoneRechargePage } from "./PhoneRecharge";
import { Subaside } from "../../components/subaside/Subaside";

export default function OtherServicePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
        {
          /* Redirect from account root URL to /customers */
          <Redirect
            exact={true}
            from="/servicos"
            to="/servicos/recarga-celular"
          />
        }
        <div className="row"> 
          <Subaside />
          <ContentRoute 
            exact={true} 
            path="/servicos/recarga-celular" 
            component={PhoneRechargePage} 
          /> 
        </div>
    </Suspense>
  );
}
