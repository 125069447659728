import React from "react";
import {
  Card,
  CardBody,
} from "../../../../partials/controls";
import ResumeTable from "./resume-table/ResumeTable";

export function ResumeCard() {
  return (
    <Card className="col-xs-12 col-xl-8 col-md-8">
      <CardBody>
        <h4>Últimas movimentações</h4>
        <p className="text-muted mt-1">
          Confira as últimas movimentações em seu extrato
        </p>
        <ResumeTable />
      </CardBody>
    </Card>
  );
}
