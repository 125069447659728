import * as Yup from 'yup';
import { cpfValid, cnpjValid, phoneValid } from './valid.utils';

Yup.addMethod(Yup.string, 'cnpj', function(message) {
  return Yup.mixed().test('cnpj', message, value => cnpjValid(value));
});

Yup.addMethod(Yup.string, 'cpf', function(message) {
  return Yup.mixed().test('cpf', message, value => cpfValid(value));
});

Yup.addMethod(Yup.string, 'phone', function(message) {
  return Yup.mixed().test('phone', message, value => phoneValid(value));
});
