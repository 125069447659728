import React from 'react';

import BillForm from '../../Bill/form/BillForm';

export function ConsumerForm (props){

    return (
        <BillForm consumer="true" layoutProps={props.layoutProps} />
    )
} 
