import { currency } from "../../app/utils/currency.utils";

export const initialState = {
    listLoading: false,
    summary: {
        colors: ['#000', '#c0c0c0'],
        series: [],
        chart: {
            type: 'bar',
            height: 250
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '45%',
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            labels: {
                style: {
                    colors: '#c0c0c0',
                    fontSize: '10px',
                }
            },
            categories: ['03/08', '04/08', '05/08', '06/08', '07/08'],
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#c0c0c0',
                    fontSize: '10px',
                }
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return currency(val);
                }
            }
        },
    },
    credit: [],
    debit: [],
    labels: [],
    balance: []
};

export const types = {
    SummaryRequested: "[Request Summary] Action",
    SummaryLoaded: "[All Summary] Summary Load",
    BalanceRequested: "[Request Balance] Action",
    BalanceLoaded: "[All Balance] Balance Load"
};

export const actions = {
    requestSummary: summary => ({ type: types.SummaryRequested, payload: { summary } }),
    fetchSummary: summary => ({ type: types.SummaryLoaded, payload: { summary } }),
    requestBalance: balance => ({ type: types.BalanceRequested, payload: { balance } }),
    fetchBalance: balance => ({ type: types.BalanceLoaded, payload: { balance } })
};
  