import React from 'react';
import { ResumeLoadingDialog } from './resume-loading-dialog/ResumeLoadingDialog';
import { ResumeUIProvider } from './ResumeUIContext';
import { ResumeCard } from './ResumeCard';
import { Subaside } from '../../../components/subaside/Subaside';
import { useSelector } from 'react-redux';
import {
  Success,
  CardActionsPending,
} from '../../User/Register/Documents';

export function ResumePage({ history }) {
  const resumeUIEvents = {
    openFetchCustomersDialog: () => {
      history.push(`/conta/resumo/fetch`);
    },
  };

  const { financial, phone, request } = useSelector(({ auth, phone }) => ({
    financial: auth.financial,
    phone: auth.phone,
    request: phone.phone,
  }));
  
  return (
    <div className="row">
      <Subaside />
      <ResumeUIProvider resumeUIEvents={resumeUIEvents}>
        <ResumeLoadingDialog />
        {(() => {
          if (financial.status === 'approved') {
            return <ResumeCard />;
          } else if (financial.status === 'analyzing') {
            return (
              <div className="col-xs-12 col-md-8 col-lg-8">
                <Success />
              </div>
            );
          } else if (
            (phone.verified === false && request.verified !== true) || (!['analyzing', 'approved'].includes(financial.status) && financial.status !== undefined)
          ) {
            return (
              <div className="col-xs-12 col-md-8 col-lg-8">
                <CardActionsPending />
              </div>
            );
          } else if (financial.results !== undefined) {
              return (
                <div className="col-xs-12 col-md-8 col-lg-8">
                  <CardActionsPending />
                </div>
              );
          }
        })()}
      </ResumeUIProvider>
    </div>
  );
}
