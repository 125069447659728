import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    listLoading: false,
    actionsLoading: false,
    entities: [],
    pagination: [],
    lastError: null,
    carriers: [],
    loadCarriers: "-- Selecione --",
    plans: {
        plan_values: []
    },
    loadPlans: "-- Selecione --",
    operation: [],
    error_fields: ""
}

export const callTypes = {
    list: "list",
    action: "action"
};

export const phoneRechargeSlice = createSlice({
    name: "phone_recharge",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        startCarriers: (state, action) => {
            state.error = null;
            
            if (action.payload.callType === callTypes.list) {
                state.loadCarriers = "Carregando...";
            } else {
                state.actionsLoading = false;
            }
        },
        startPlans: (state, action) => {
            state.error = null;
            
            if (action.payload.callType === callTypes.list) {
                state.loadPlans = "Carregando...";
            } else {
                state.actionsLoading = false;
            }
        },
        phoneRechargeFetched: (state, action) => {  
            const { pagination, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.pagination = pagination;
            state.operation = [];
        },
        carriersFetched: (state, action) => {
            state.carriers = action.payload.results;
            state.loadCarriers = "-- Selecione --";
        },
        fetchTransfer: (state, action) => {
            state.listLoading = false;
            state.operation = action.payload;
        },
        plansFetched: (state, action) => {
            state.listLoading = false;
            state.loadPlans = "-- Selecione --";
            state.plans = action.payload;
        },
        fetchError: (state, action) => {
            state.listLoading = false;
            state.error_fields = action.payload;
        }
    }
});