/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/pages/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useEffect} from "react";
import { Redirect, Switch, Route, useLocation, useHistory } from "react-router-dom";
import { matchPath } from "react-router";
import { shallowEqual, useSelector } from "react-redux";
import {Layout, ContentRoute} from "../app/components";
import BasePage from "./BasePage";
import { Logout } from "./pages/User/Auth";
import { Helmet } from "react-helmet";
import UserPage from "./pages/User/UserPage";
import RegisterPage from "./pages/User/Register/RegisterPage";
import { ErrorPage1 } from "./pages/ErrorsExamples/ErrorPage1";
import { routes } from "../helpers/RouterHelpers";

export function Routes() {
    const history = useHistory();
    const actualRoute = useLocation().pathname;
    

    useEffect(() => {
        const match = matchPath(actualRoute, {
            path: routes,
            exact: true,
            strict: false
          });
          if(!match) history.push('/error');
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [actualRoute]);

    const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.access_token != null,
        }),
        shallowEqual
    );
    const { settings } = useSelector((state) => state.settings);

    return (<>
        <Helmet title={settings.header.title} />
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <>
                    <ContentRoute exact={true}>
                        <UserPage />
                    </ContentRoute>
                    <Route path="/error">
                        <ErrorPage1 />
                    </Route>
                </>
            ) : (
                <ContentRoute path="/cadastro">
                    <RegisterPage />
                </ContentRoute>,
                <Redirect from="/auth" to="/"/>
            )}

            <Route exact={true} path="/logout" component={Logout}/>
            <Route path="/error">
                <ErrorPage1 />
            </Route>

            <Layout>
                <BasePage/>
            </Layout>
        </Switch>
    </>);
}
