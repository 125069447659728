import React, { useState, useMemo, useEffect } from 'react';
import { useFormik } from 'formik';
import { useHtmlClassService } from '../../../../components';
import { Document } from '../../../../components/document/Document';
import { DocumentFile } from '../../../../components/document/DocumentFile';
import { Link, useHistory } from 'react-router-dom';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';
import { connect } from 'react-redux';
import * as fromState from '../_redux/actions';
import ErrorFields from '../../../../components/alert/ErrorFields';
import DocumentFiles from './DocumentFiles';
import ModalNotice from '../../../../components/alert/ModalNotice';
import { toAbsoluteUrl } from '../../../../../helpers';

function DocumentInformation(props) {
  const { error_fields } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const enableLoading = () => {
    setLoading(true);
  };

  useEffect(() => {
    const buttonDisabled =
      props.file_selfie === '' ||
      props.file_document_selfie === '' ||
      props.file_proof_of_residence === '' ||
      props.file_document_front === '' ||
      props.file_document_back === '';
    setDisabled(buttonDisabled);
  }, [
    props.file_document_back,
    props.file_document_front,
    props.file_document_selfie,
    props.file_proof_of_residence,
    props.file_selfie,
  ]);

  const initialValues = {
    file_selfie: props.file_selfie,
    file_document_selfie: props.file_document_selfie,
    file_proof_of_residence: props.file_proof_of_residence,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      enableLoading();
      const value = {
        file_selfie: props.file_selfie,
        file_document_selfie: props.file_document_selfie,
        file_proof_of_residence: props.file_proof_of_residence,
        file_document_front: props.file_document_front,
        file_document_back: props.file_document_back,
      };

      if (props.documents) {
        props.updateDocuments(value);
      } else {
        props.registerDocuments(value);
      }
    },
  });

  const titles = name => {
    let data = {
      selfie: 'Selfie do Rosto',
      document_selfie: 'Selfie com Documento',
      document_front: 'RG ou CNH (Frente)',
      document_back: 'RG ou CNH (Verso)',
      proof_of_residence: 'Comprovante de residência',
    };
    return data[name];
  };

  return (
    <>
      {/** begin::ModalError */}
      <ErrorFields
        error_fields={error_fields}
        show={error_fields !== '' ? true : false}
        onHide={() => {
          props.fetchErrors('');
          setLoading(false);
          props.setBoolean(false);
          window.location.reload(true);
        }}
      />
      {/** end::ModalError */}

      {/** begin::Modal Open Account */}
      <ModalNotice
        show={error_fields === '' && props.send ? true : false}
        onHide={() => {
          props.setBoolean(true);
          setLoading(false);
          window.location.reload(true);
        }}
        title="UPDATE.DOCUMENTS.TITLE"
        message="UPDATE.DOCUMENTS.SUCCESS"
      />
      {/** end::Modal Open Account */}

      <div className="login-form login-signin" style={{ display: 'block' }}>
        <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          onSubmit={formik.handleSubmit}
        >
          <div className="row">
            <h5
              style={{
                color: '#000',
                marginBottom: '10px',
              }}
            >
              Atenção: Os arquivos precisam estar na extensão .jpeg
            </h5>
            {(() => {
              if (
                props.documents === undefined &&
                history.location.pathname !== '/cadastro/documentos'
              ) {
                return <DocumentFiles update={true} />;
              }

              if (props.documents) {
                if (props.documents.length === 0) {
                  return <DocumentFiles update={true} />;
                }
              }

              if (!props.documents && typeof props.documents === 'undefined') {
                return (
                  <>
                    <Document
                      title="RG ou CNH"
                      subtitle="Com n° de CPF"
                      selected={
                        (props.file_document_front &&
                          props.file_document_back) !== ''
                          ? true
                          : false
                      }
                      icon="/media/svg/icons/Register/Person-CPF.svg"
                    >
                      {(() => {
                        if (
                          (props.file_document_front &&
                            props.file_document_back) !== ''
                        ) {
                          return;
                        }

                        return (
                          <Link to="conta-fisica/documento">
                            <label
                              type="button"
                              style={{
                                backgroundColor: layoutProps.colors.secondary,
                                color: '#fff',
                                height: '25px',
                                lineHeight: '25px',
                                textAlign: 'center',
                                width: '100px',
                                borderRadius: '3px',
                              }}
                            >
                              Selecionar
                            </label>
                          </Link>
                        );
                      })()}
                    </Document>
                    {(() => {
                      if (
                        props.documents === undefined &&
                        history.location.pathname === '/cadastro/documentos'
                      ) {
                        return (
                          <DocumentFile
                            title="Comprovante de residência"
                            subtitle="Até 3 meses"
                            icon="/media/svg/icons/Register/Person-Comprovante.svg"
                            name="file_proof_of_residence"
                            approved={
                              props.file_proof_of_residence !== '' ? 4 : 3
                            }
                            onChange={e => {
                              props.loadProofFile(e.target.files[0]);
                            }}
                          />
                        );
                      }
                    })()}
                  </>
                );
              }
            })()}
          </div>

          {/* begin: Sexo */}
          <div className="row">
            {(() => {
              if (props.documents && props.documents.results) {
                return props.documents.results.map((v, i) => {
                  return (
                    <>
                      <DocumentFile
                        title={titles(v.type)}
                        approved={v.approved}
                        icon="/media/svg/icons/Register/Person-Comprovante.svg"
                        onChange={e => {
                          switch (v.type) {
                            case 'selfie':
                              v.approved = 4;
                              props.loadSelfie(e.target.files[0]);
                              break;
                            case 'document_selfie':
                              v.approved = 4;
                              props.loadDocumentSelfie(e.target.files[0]);
                              break;
                            case 'document_front':
                              v.approved = 4;
                              props.loadDocumentFront(e.target.files[0]);
                              break;
                            case 'document_back':
                              v.approved = 4;
                              props.loadDocumentBack(e.target.files[0]);
                              break;
                            case 'proof_of_residence':
                              v.approved = 4;
                              props.loadProofFile(e.target.files[0]);
                              break;

                            default:
                              break;
                          }
                        }}
                      >
                        <p
                          style={{
                            color: v.approved === 1 ? '' : '#F11F52',
                          }}
                        >
                          {v.comment}
                        </p>
                      </DocumentFile>

                      {['document_front', 'document_back'].includes(v.type) && (
                        <div className="modelDocument">
                          <h5 className="mt-2">
                            Modelo CNH{' '}
                            {v.type === 'document_front' ? 'Frente' : 'Verso'}
                          </h5>
                          <img
                            width="150"
                            alt="imagem"
                            src={toAbsoluteUrl(
                              v.type === 'document_front'
                                ? 'media/images/cnh_frente.jpg'
                                : 'media/images/cnh_verso.jpg'
                            )}
                          />
                        </div>
                      )}
                    </>
                  );
                });
              }

              return (
                <>
                  <DocumentFile
                    title="Selfie com Documento"
                    subtitle="RG ou CNH"
                    icon="/media/svg/icons/Register/Person-Selfie.svg"
                    name="file_document_selfie"
                    approved={props.file_document_selfie !== '' ? 4 : 3}
                    onChange={e => {
                      props.loadDocumentSelfie(e.target.files[0]);
                    }}
                  />

                  <DocumentFile
                    title="Selfie do Rosto"
                    subtitle="Com CPF"
                    icon="/media/svg/icons/Register/Person-Phone.svg"
                    name="file_selfie"
                    approved={props.file_selfie !== '' ? 4 : 3}
                    onChange={e => {
                      props.loadSelfie(e.target.files[0]);
                    }}
                  />
                  <input type="file" id="" hidden />
                  {(() => {
                    if (
                      props.documents === undefined &&
                      history.location.pathname !== '/cadastro/documentos'
                    ) {
                      return (
                        <DocumentFile
                          title="Comprovante de residência"
                          subtitle="Até 3 meses"
                          icon="/media/svg/icons/Register/Person-Comprovante.svg"
                          name="file_proof_of_residence"
                          approved={
                            props.file_proof_of_residence !== '' ? 4 : 3
                          }
                          onChange={e => {
                            props.loadProofFile(e.target.files[0]);
                          }}
                        />
                      );
                    }
                  })()}
                </>
              );
            })()}
          </div>

          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-5">
              <ButtonCustom
                onClick={() => {}}
                disabled={disabled}
                layoutProps={layoutProps}
                size="sm"
                type="submit"
              >
                Enviar
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </ButtonCustom>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default connect(
  ({
    register,
    physicalPerson,
    legalPerson,
    phone,
    zipcode,
    password,
    allBanks,
  }) => ({
    file_selfie: register.file_selfie,
    file_document_selfie: register.file_document_selfie,
    file_proof_of_residence: register.file_proof_of_residence,
    file_document_front: register.file_document_front,
    file_document_back: register.file_document_back,
    error_fields: register.error_fields,
    send: register.send,
    registerUser: {
      person: physicalPerson.basic.cpf > 0 ? physicalPerson : legalPerson,
      password: password,
      phone: phone.phone,
      zipcode: zipcode.zipcode,
      allBanks: allBanks,
    },
  }),
  fromState.actions
)(DocumentInformation);
