import React from "react";
import {
  Card,
  CardBody
} from "../../../../partials/controls";
import ExtractFilter from "./extract-filter/ExtractFilter";
import ExtractTable from "./extract-table/ExtractTable";

export function ExtractCard() {

  return (
    <Card className="col-xs-12 col-xl-8 col-md-8">
      <CardBody>
        <ExtractFilter />
        <ExtractTable />
      </CardBody>
    </Card>
  );
}
