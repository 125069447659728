import React, { useState, useMemo, useEffect } from 'react';
import { connect } from "react-redux";
import * as actions from "../../_redux/bill/billActions";

import {
    Card,
    CardBody
} from '../../../../../partials/controls';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import { initialValues } from '../BillUIHelpers';
import { injectIntl } from "react-intl";
import { CardMessage } from '../../../../components/message/CardMessage';
import ErrorFields from '../../../../components/alert/ErrorFields';
import { moneyMask, removeCurrencyMask, billetMask, formatPrice } from '../../../../utils/currency.utils';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';
import { useHtmlClassService } from '../../../../_core/MetronicLayout';
import DatePicker from 'react-datepicker';

import UserServices from '../../../../../services/UserServices';
import PaymentServices from '../../../../../services/PaymentServices';

function BillForm(props) {
    const { intl, infoBill, listLoading, error_fields, fetchError, consumer } = props;
    const [loading, setLoading] = useState(false);
    const [accountInfo, setAccountInfo] = useState([]);
    const [ready, setReady] = useState(false);
    const [submitingRead, setSubmitingRead] = useState(false);

    initialValues.type_payment = (consumer) ? ('1') : ('2');
    initialValues.value = "R$ 0,00";

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colors: uiService.getColors()
        };
    }, [uiService]);

    // Schema de validação dos campos
    const DepositSchema = Yup.object().shape({
        value: Yup.string()
            .required(
                intl.formatMessage({
                    id: "BILL.REQUIRED_FIELD",
                })
            ),
        bar_code: Yup.number()
            .required(
                intl.formatMessage({
                    id: "BILL.REQUIRED_FIELD",
                })
            ).min(44, 'Informe a quantidade mínima de número')
    });
    // - - -

    const enableLoading = () => {
        setLoading(true);
    };

    const loadInfo = async () => {
        let info = await UserServices.getAccount();
        setAccountInfo(info.data);
    };
    
    useEffect(() => {
        loadInfo();
    },[]);

    const updateTax = () => {
        if (accountInfo.length === 0) return;
        
        let tax = 0;

        if (accountInfo.limits.bill_payment.fee_amount > 0) {
            tax = accountInfo.limits.bill_payment.fee_amount;
        }

        return `Taxa de Pagamento: ${formatPrice(tax)}`;
    };

    const updateTaxPercent = () => {
        if (accountInfo.length === 0) return;
        let tax = 0;

        if (accountInfo.limits.bill_payment.fee_percent > 0) {
            tax = accountInfo.limits.bill_payment.fee_percent;

            tax = (tax / 100) * removeCurrencyMask(formik.values.value);
        }

        return `Taxa de serviço: ${formatPrice(tax)}`;
    };

    const loadBilletData = async (bar_code) => {
        try {
            let info = await PaymentServices.infoBill({bar_code: bar_code});
    
            formik.setFieldValue("value", moneyMask(formatPrice(info.data.original_value)));
            if (info.data.discount > 0) formik.setFieldValue("discount", moneyMask(formatPrice(info.data.discount)));
            
            if (info.data.interest > 0) formik.setFieldValue("interest", moneyMask(formatPrice(info.data.interest)));
            if (info.data.fine > 0) formik.setFieldValue("fine", moneyMask(formatPrice(info.data.fine)));

            formik.setFieldValue("total_value", moneyMask(formatPrice(info.data.total_value)));
    
            try {
                if (info.data.due_date !== null) formik.setFieldValue("due_date", new Date(`${info.data.due_date} 23:59:59`));
            } catch (err) {}
            
            setReady(true);
            setLoading(false);
            setSubmitingRead(false);
        } catch (error) {
            setLoading(false);
            setSubmitingRead(false);

            if (error.response) {
                const errorObject = _.get(error, 'response.data.field_errors');
                const errorArray = Object.keys(errorObject).map((k) => errorObject[k]);     
                
                try {
                    alert(errorArray[0][0].message);
                } catch (err) {
                    alert("Falha ao efetuar pagamento!");
                }                
            } else {
                alert("Falha ao efetuar pagamento!");
            }
        }
        
        
    };

    // Inicializando e validando formulário
    const formik = useFormik({
        initialValues,
        validationSchema: DepositSchema,
        onSubmit: (values, { setSubmitting }) => {
            const data = {
                ...values,
                value: removeCurrencyMask(values.value),
            }

            if (ready) data.due_date = values.due_date.toISOString().substr(0, 10);

            enableLoading();
            setTimeout(() => {
                try {
                    if (!ready) {
                        setSubmitingRead(true);
                        loadBilletData(values.bar_code);
                    }
                    else {
                        data.value_origin = data.value;                        
                        data.value = removeCurrencyMask(data.total_value);
                        data.discount = removeCurrencyMask(data.discount);

                        let fine = data.fine !== '' ? parseFloat(removeCurrencyMask(data.fine)) : 0;
                        let interest = data.interest !== ''  ? parseFloat(removeCurrencyMask(data.interest)) : 0;

                        data.addition = fine + interest;
                        infoBill(data);
                        enableLoading();
                    }
                } catch (error) {
                    console.log(error);
                    setSubmitting(false);
                    setLoading(false);
                }
            }, 1000)
        },
    });
    // - - - 

    return (<>

        {/** begin::ModalError */}
        <ErrorFields
            error_fields={error_fields}
            show={(error_fields !== '') ? (true) : (false)}
            onHide={() => {
                fetchError('')
                setLoading(false)
            }}
        />
        {/** end::ModalError */}

        <CardMessage
            message="Realize o pagamento de seus boletos bancários ou contas de consumo (água, luz, telefone, etc)."
        >
            <div>
                <br />
                {updateTax()}
                <br />  
                {updateTaxPercent()}
            </div>
        </CardMessage>

        <Card className="col-xs-12 col-xl-12 col-md-12">
            <CardBody>
                <form
                    onSubmit={formik.handleSubmit}>

                    <div className="row">
                        {/** begin::Servico*/}
                        <div className="col-md-4 col-lg-4 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="type_payment">Tipo pagamento:</label>
                                <select
                                    className="form-control select2 h-auto py-4 px-6"
                                    name="type_payment"
                                    onChange={(e) => {
                                        formik.setFieldValue("type_payment", e.target.value);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.type_payment}
                                >
                                    <option value="1">Conta de serviços</option>
                                    <option value="2">Boleto bancário</option>
                                </select>
                            </div>
                        </div>
                        {/** end::Servico*/}
                        
                        {ready && (
                            <>
                                {/** begin::Valor*/}
                                <div className="col-md-4 col-lg-4 col-xs-12">
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor="value">Valor Original:</label>
                                        <input
                                            placeholder="R$ 0,00"
                                            type="text"
                                            className="form-control h-auto py-4 px-6"
                                            name="value"
                                            {...formik.getFieldProps("value")}
                                            onChange={(e) => {
                                                formik.setFieldValue("value", moneyMask(e.target.value));
                                            }}
                                        />
                                        {formik.touched.value && formik.errors.value ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{formik.errors.value}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                {/** end::Valor*/}

                                {/** begin::Vencimento*/}
                                <div className="col-md-3 col-lg-3 col-xs-12">
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor="due_date">Vencimento:</label>
                                        <DatePicker
                                            className="form-control h-auto py-4 px-6"
                                            style={{ width: '100%' }}
                                            placeholderText="dd/mm/yyyy"
                                            selected={formik.values.due_date}
                                            onChange={val => {
                                                console.log(val);
                                                formik.setFieldValue('due_date', val);
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                </div>
                                {/** end::Vencimento*/}
                            </>
                        )}
                    </div>

                    {ready && (
                        <div className="row">
                            {/** begin::Multa*/}
                            <div className="col-md-3 col-lg-3 col-xs-12">
                                <div className="form-group fv-plugins-icon-container">
                                    <label htmlFor="value">Multa:</label>
                                    <input
                                        placeholder="R$ 0,00"
                                        type="text"
                                        className="form-control h-auto py-4 px-6"
                                        name="fine"
                                        {...formik.getFieldProps("fine")}
                                        onChange={(e) => {
                                            formik.setFieldValue("fine", moneyMask(e.target.value));
                                        }}
                                    />
                                    {formik.touched.fine && formik.errors.fine ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.fine}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/** end::Multa*/}

                            {/** begin::Juros*/}
                            <div className="col-md-3 col-lg-3 col-xs-12">
                                <div className="form-group fv-plugins-icon-container">
                                    <label htmlFor="value">Juros:</label>
                                    <input
                                        placeholder="R$ 0,00"
                                        type="text"
                                        className="form-control h-auto py-4 px-6"
                                        name="interest"
                                        {...formik.getFieldProps("interest")}
                                        onChange={(e) => {
                                            formik.setFieldValue("interest", moneyMask(e.target.value));
                                        }}
                                    />
                                    {formik.touched.interest && formik.errors.interest ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.interest}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/** end::Juros*/}

                            {/** begin::Desconto*/}
                            <div className="col-md-3 col-lg-3 col-xs-12">
                                <div className="form-group fv-plugins-icon-container">
                                    <label htmlFor="value">Desconto:</label>
                                    <input
                                        placeholder="R$ 0,00"
                                        type="text"
                                        className="form-control h-auto py-4 px-6"
                                        name="discount"
                                        {...formik.getFieldProps("discount")}
                                        onChange={(e) => {
                                            formik.setFieldValue("discount", moneyMask(e.target.value));
                                        }}
                                    />
                                    {formik.touched.discount && formik.errors.discount ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.discount}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/** end::Desconto*/}

                            {/** begin::Valor Total*/}
                            <div className="col-md-3 col-lg-3 col-xs-12">
                                <div className="form-group fv-plugins-icon-container">
                                    <label htmlFor="value">Valor Total:</label>
                                    <input
                                        placeholder="R$ 0,00"
                                        type="text"
                                        className="form-control h-auto py-4 px-6"
                                        name="total_value"
                                        {...formik.getFieldProps("total_value")}
                                        onChange={(e) => {
                                            formik.setFieldValue("total_value", moneyMask(e.target.value));
                                        }}
                                    />
                                    {formik.touched.total_value && formik.errors.total_value ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.total_value}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/** end::Valor Total*/}
                        </div>
                    )}

                    <div className="row">
                        {/** begin::Codigo_de_barra*/}
                        <div className="col-md-12 col-lg-12 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="bar_code">Código de barras:</label>
                                <input
                                    disabled={ready}
                                    placeholder="Digite ou cole o código de barras do documento."
                                    type="text"
                                    className="form-control h-auto py-4 px-6"
                                    name="bar_code"
                                    {...formik.getFieldProps("bar_code")}
                                    onChange={(e) => {
                                        formik.setFieldValue("bar_code", billetMask(e.target.value));
                                    }}
                                />
                                {formik.touched.bar_code && formik.errors.bar_code ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.bar_code}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/** end::Codigo_de_barra*/}
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-md-offset-2">
                            {ready ? (
                                <ButtonCustom
                                    disabled={formik.values.value === 'R$ 0,00' || !formik.values.due_date || loading || listLoading}
                                    type="submit"
                                    layoutProps={layoutProps}
                                >
                                    Realizar pagamento
                                    {(loading || listLoading) && <span className="ml-3 spinner spinner-white"></span>}
                                </ButtonCustom>
                            ) : (
                                <ButtonCustom
                                    disabled={formik.values.bar_code === '' || submitingRead || loading || listLoading}
                                    layoutProps={layoutProps}
                                    type="submit"
                                >
                                    Ler boleto
                                    {(loading || listLoading) && <span className="ml-3 spinner spinner-white"></span>}
                                </ButtonCustom>
                            )}
                        </div>
                    </div>

                </form>
            </CardBody>
        </Card>
    </>);
}

export default injectIntl(connect(
    ({ bill }) => ({
        message: bill.error,
        listLoading: bill.listLoading,
        error_fields: bill.error_fields
    }), actions)(BillForm));