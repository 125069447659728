import React, { useState } from 'react';
import { Subaside } from '../../../components/subaside/Subaside';
import { Card, CardBody } from '../../../../partials/controls';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import * as auth from '../../../pages/User/Auth/_redux/authRedux';
import { useEffect } from 'react';
import jsPDF from 'jspdf';

import { currency } from '../../../utils/currency.utils';
import { dateFormatted } from '../../../utils/date.utils';
import { cnpjMask, cpfMask } from '../../../utils/mask_cpf_cnpj.utils';
import { ButtonCustom } from '../../../components/button/ButtonCustom';

import PaymentServices from '../../../../services/PaymentServices';
import { ENDPOINT } from '../../../../services/enums/EndPoint';

import { useHtmlClassService } from '../../../_core/MetronicLayout';
import base64 from '../../../utils/base64.utils';
import emails from '../../../utils/email.utils';

const useStyles = makeStyles(theme => ({
  head: {
    color: 'black',
    textAlign: 'left',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  title: {
    color: 'black',
    fontSize: '14px',
    textAlign: 'left',
    width: '30%',
  },
  titleBig: {
    color: 'black',
    fontSize: '14px',
    textAlign: 'left',
    width: '70%',
    marginTop: '20px',
  },
  subtitle: {
    fontSize: '14px',
    textAlign: 'left',
    width: '70%',
  },
  rowTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    paddinRight: '20px',
    width: '70%',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    marginBottom: '5px',
    width: '100%',
  },
  verticalCenter: {
    alignItems: 'center',
  },
  box: {
    width: '55%',
  },
  boxBorderless: {
    width: '55%',
  },
  boxValueOperation: {
    borderTop: '5px solid #000',
    borderBottom: '3px solid #000',
    padding: '10px 0px',
    margin: '10px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bvoTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  bvoSubTitle: {
    fontSize: '14px',
    fontWeight: 'semi-bold',
  },
}));

function SComprovante(props) {
  const classes = useStyles();
  const [userStatement, setUserStatement] = useState([]);
  const [info, setInfo] = useState([]);
  const [loading] = useState(false);

  const endpoints = {
    internal_transfer: ENDPOINT.INTERNAL_TRANSFER,
    bank_transfer: ENDPOINT.EXTERNAL_TRANSFER,
    phone_recharge: ENDPOINT.PHONE_RECHARGE,
    bill_payment: ENDPOINT.BILL_PAYMENTS,
    pix_transfer: ENDPOINT.PIX_TRANSFERS,
  };

  function stringDivider(str, width, spaceReplacer) {
    if (str.length > width) {
      var p = width;
      for (; p > 0 && str[p] !== ' '; p--) {}
      if (p > 0) {
        var left = str.substring(0, p);
        var right = str.substring(p + 1);
        return (
          left + spaceReplacer + stringDivider(right, width, spaceReplacer)
        );
      }
    }
    return str;
  }

  const transactionType = {
    BANK_TRANSFER_RECEIVED: 'TED Recebida',
    BANK_TRANSFER: 'Transferência bancária',
    BILL_PAYMENT: 'Pagamento de boleto',
    DEPOSIT: 'Depósito',
    INTERNAL_TRANSFER: 'Transferência interna',
    POS_SALE: 'Venda POS',
    PHONE_RECHARGE: 'Recarga de telefone',
    INTERNAL_DEBIT: 'Débito interno',
    LIQUIDATED: 'Liquidado',
    DENIED: 'Negado',
    PENDING: 'Pendente',
    CARD_PURCHASE: 'Compra no Cartão',
    PIX_TRANSFER: 'Transferência PIX',
    PIX_TRANSFER_RECEIVED: 'Transferência Pix Recebida',
    '': '',
  };

  var imgData = 'data:image/jpeg;base64,' + base64[window.location.host];

  const uiService = useHtmlClassService();

  useEffect(() => {
    const us = JSON.parse(localStorage.getItem('userStatement'));

    if (!us) {
      window.location.href = '/conta/resumo';
    } else {
      setUserStatement(us);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = async () => {
    let inf = await PaymentServices.getSecondVoucher(
      userStatement.tradable_id,
      endpoints[userStatement.transaction_type]
    );
    setInfo(inf);
    //localStorage.removeItem('userStatement');
  };

  useEffect(() => {
    if (userStatement.length !== 0) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatement]);

  const salvarPdf = async row => {
    if (
      ![
        'internal_transfer',
        'bank_transfer',
        'phone_recharge',
        'bill_payment',
      ].includes(row.transaction_type)
    )
      return false;

    let info = await PaymentServices.getSecondVoucher(
      row.tradable_id,
      endpoints[row.transaction_type]
    );

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'a4',
    });

    var imgData = 'data:image/jpeg;base64,' + base64[window.location.host];

    pdf.addImage(imgData, 'JPEG', 10, 10, 120, 30);

    pdf.setFontSize(16);
    pdf.text(
      `Comprovante de ${transactionType[row.transaction_type.toUpperCase()]}`,
      420,
      35,
      'right'
    );

    pdf.setFillColor('#000');
    pdf.rect(8, 45, 412, 3, 'F');

    pdf.setFontSize(20);
    var text = currency(info.data.value);
    var xOffset =
      pdf.internal.pageSize.width / 2 -
      (pdf.getStringUnitWidth(text) * pdf.getFontSize()) / 2;
    pdf.text(text, xOffset, 70);

    pdf.setFontSize(14);
    var realizadoEm = `Data da Operação: ${row.formatted_date}`;
    var xOffset2 =
      pdf.internal.pageSize.width / 2 -
      (pdf.getStringUnitWidth(realizadoEm) * pdf.getFontSize()) / 2;
    pdf.text(realizadoEm, xOffset2 + 25, 90);

    pdf.setFillColor('#000');
    pdf.rect(8, 100, 412, 2, 'F');

    pdf.setFontSize(13);

    pdf.text('Data da Transferência', 10, 120);
    pdf.text(row.formatted_date.split(' - ')[0], 135, 120);

    pdf.text('Autenticação', 10, 135);
    pdf.text(row.uuid, 135, 135);

    // START INTERNAL
    if (['internal_transfer', 'bank_transfer'].includes(row.transaction_type)) {
      pdf.setFont(undefined, 'bold');
      pdf.text('INFORMAÇÕES DO FAVORECIDO', 10, 190);
      pdf.setFont(undefined, 'normal');

      if (info.data.favored) {
        pdf.text('Nome:', 10, 210);
        pdf.text(info.data.favored, 135, 210);

        pdf.text('CPF/CNPJ:', 10, 225);
        if (info.data.document.length > 11) {
          pdf.text(cnpjMask(info.data.document), 135, 225);
        } else {
          pdf.text(cpfMask(info.data.document), 135, 225);
        }
      }
      if (info.data.user_receiver) {
        pdf.text('Favorecido:', 10, 210);
        pdf.text(info.data.user_receiver.name, 135, 210);

        pdf.text('CPF/CNPJ:', 10, 225);
        if (info.data.user_receiver.person.cnpj) {
          pdf.text(cnpjMask(info.data.user_receiver.person.cnpj), 135, 225);
        } else {
          pdf.text(cpfMask(info.data.user_receiver.person.cpf), 135, 225);
        }
      }
    }
    // END INTERNAL

    // START TED
    if (row.transaction_type === 'bank_transfer') {
      pdf.text('Banco:', 10, 240);
      pdf.text(
        stringDivider(info.data.bank.displayable_name, 40, '\n'),
        135,
        240
      );

      pdf.text('Tipo da Conta:', 10, 255);
      pdf.text(
        info.data.account_type === 'checking'
          ? 'Conta Corrente'
          : 'Conta Poupança',
        135,
        255
      );

      pdf.text('Agência:', 10, 270);
      pdf.text(
        `${info.data.bank_branch}${
          info.data.bank_branch_digit ? '-' + info.data.bank_branch_digit : ''
        }`,
        135,
        270
      );

      pdf.text('Número da Conta:', 10, 285);
      pdf.text(
        `${info.data.account_number}${
          info.data.account_digit ? '-' + info.data.account_digit : ''
        }`,
        135,
        285
      );
    }
    // END TED

    // START BILLET
    if (row.transaction_type === 'bill_payment') {
      pdf.setFont(undefined, 'bold');
      pdf.text('INFORMAÇÕES DO PAGAMENTO', 10, 190);
      pdf.setFont(undefined, 'normal');

      pdf.text('Adição:', 10, 205);
      pdf.text(currency(info.data.addition || 0), 135, 205);

      pdf.text('Desconto:', 10, 220);
      pdf.text(currency(info.data.discount || 0), 135, 220);

      pdf.text('Data de vencimento:', 10, 235);
      pdf.text(dateFormatted(info.data.due_date), 135, 235);

      pdf.text('Valor original:', 10, 250);
      pdf.text(currency(info.data.value_origin), 135, 250);

      pdf.text('Linha digitável:', 10, 265);
      pdf.text(currency(info.data.writable_line), 135, 265);

      pdf.text('Código de barras:', 10, 280);
      pdf.text(currency(info.data.bar_code), 135, 280);
    }
    // END BILLET

    // START PHONE RECHARGE
    if (row.transaction_type === 'phone_recharge') {
      pdf.setFont(undefined, 'bold');
      pdf.text('INFORMAÇÕES DA RECARGA', 10, 190);
      pdf.setFont(undefined, 'normal');

      pdf.text('Número:', 10, 205);
      pdf.text(`(${info.data.area_code}) ${info.data.phone_number}`, 135, 205);
    }
    // END PHONE RECHARGE

    var alturaPadrao = 0;
    if (['internal_transfer'].includes(row.transaction_type)) {
      alturaPadrao = 250;
    } else if (['phone_recharge'].includes(row.transaction_type)) {
      alturaPadrao = 230;
    } else {
      alturaPadrao = 300;
    }

    // pdf.setFillColor('#000');
    // pdf.rect(8, alturaPadrao, 412, 2, 'F');

    // pdf.setFontSize(14);
    // var idTransacaoText = "ID da transação"
    // var xOffset3 = (pdf.internal.pageSize.width / 2) - (pdf.getStringUnitWidth(idTransacaoText) * pdf.getFontSize() / 2);
    // pdf.text(idTransacaoText, xOffset3+10, alturaPadrao + 20);

    // pdf.setFontSize(20);
    // var idTransacao = `${info.data.id}`;
    // var xOffset4 = (pdf.internal.pageSize.width / 2) - (pdf.getStringUnitWidth(idTransacao) * pdf.getFontSize() / 2);
    // pdf.text(idTransacao, xOffset4, alturaPadrao + 40);

    // pdf.setFillColor('#000');
    // pdf.rect(8, alturaPadrao + 50, 412, 2, 'F');

    pdf.setFontSize(11);
    pdf.text('Ouvidoria: ', 10, alturaPadrao + 65);
    pdf.text(emails[window.location.host], 50, alturaPadrao + 65);

    pdf.save(
      `comprovante_${row.formatted_date
        .split(' - ')[0]
        .replace('/', '.')
        .replace('/', '.')}.pdf`
    );
  };

  return (
    <div className="row">
      <Subaside />
      <div className="col-md-8 col-xs-12 col-lg-8">
        <Card className="col-xs-12 col-xl-12 col-md-12">
          {userStatement !== undefined && (
            <CardBody>
              <div className="row">
                <div
                  className={`${classes.verticalCenter} row col-md-8 col-md-offset-2`}
                >
                  <img width="180" src={imgData} alt="" />
                  <h4 className={classes.head}>
                    Comprovante de{' '}
                    {userStatement.transaction_type
                      ? transactionType[
                          userStatement.transaction_type.toUpperCase()
                        ]
                      : ''}
                  </h4>
                </div>
                <div className="col-md-4 col-md-offset-2">
                  <ButtonCustom
                    onClick={() => salvarPdf(userStatement)}
                    layoutProps={{
                      colors: uiService.getColors(),
                    }}
                  >
                    Baixar Comprovante
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </ButtonCustom>
                </div>
              </div>
              <div className={classes.boxValueOperation}>
                <span className={classes.bvoTitle}>
                  {userStatement.value && currency(userStatement.value)}
                </span>
                <span className={classes.bvoSubTitle}>
                  Data da Operação: {userStatement.formatted_date}
                </span>
              </div>
              <div className={classes.rowTop}>
                <div className={classes.title}>
                  <span>Data da Operação</span>
                </div>

                <div className={classes.subtitle}>
                  <span>
                    {userStatement.formatted_date
                      ? userStatement.formatted_date.split(' - ')[0]
                      : ''}
                  </span>
                </div>
              </div>
              <div className={classes.rowTop}>
                <div className={classes.title}>
                  <span>Autenticação</span>
                </div>

                <div className={classes.subtitle}>
                  <span>{userStatement.uuid}</span>
                </div>
              </div>

              {['internal_transfer', 'bank_transfer'].includes(
                userStatement.transaction_type
              ) && (
                <div className={classes.box}>
                  <div className={classes.row}>
                    <div className={classes.titleBig}>
                      <strong>
                        <span>INFORMAÇÕES DO FAVORECIDO</span>
                      </strong>
                    </div>
                  </div>
                  {info.data && info.data.favored && (
                    <>
                      <div className={classes.row}>
                        <div className={classes.title}>
                          <span>Nome</span>
                        </div>

                        <div className={classes.subtitle}>
                          <span>{info.data.favored}</span>
                        </div>
                      </div>
                      <div className={classes.row}>
                        <div className={classes.title}>
                          <span>CPF/CNPJ</span>
                        </div>

                        <div className={classes.subtitle}>
                          <span>
                            {info.data.document.length > 11
                              ? cnpjMask(info.data.document)
                              : cpfMask(info.data.document)}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {info.data && info.data.user_receiver && (
                    <>
                      <div className={classes.row}>
                        <div className={classes.title}>
                          <span>Nome</span>
                        </div>

                        <div className={classes.subtitle}>
                          <span>{info.data.user_receiver.name}</span>
                        </div>
                      </div>
                      <div className={classes.row}>
                        <div className={classes.title}>
                          <span>CPF/CNPJ</span>
                        </div>

                        <div className={classes.subtitle}>
                          <span>
                            {info.data.user_receiver.person.cnpj
                              ? cnpjMask(info.data.user_receiver.person.cnpj)
                              : cpfMask(info.data.user_receiver.person.cpf)}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {['bank_transfer'].includes(userStatement.transaction_type) && (
                <div className={classes.box}>
                  {info.data && info.data.bank && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Banco</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>{info.data.bank.displayable_name}</span>
                      </div>
                    </div>
                  )}
                  {info.data && info.data.account_type && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Tipo da Conta</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>
                          {info.data.account_type === 'checking'
                            ? 'Conta Corrente'
                            : 'Conta Poupança'}
                        </span>
                      </div>
                    </div>
                  )}
                  {info.data && info.data.bank_branch && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Agência</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>
                          {info.data.bank_branch}
                          {info.data.bank_branch_digit
                            ? '-' + info.data.bank_branch_digit
                            : ''}
                        </span>
                      </div>
                    </div>
                  )}
                  {info.data && info.data.account_number && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Número da Conta</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>
                          {info.data.account_number}
                          {info.data.account_digit
                            ? '-' + info.data.account_digit
                            : ''}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {userStatement.transaction_type === 'bill_payment' && (
                <div className={classes.boxBorderless}>
                  <div className={classes.row}>
                    <div className={classes.titleBig}>
                      <strong>
                        <span>INFORMAÇÕES DO PAGAMENTO</span>
                      </strong>
                    </div>
                  </div>
                  {info.data && info.data.addition && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Adição</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>
                          {info.data.addition && currency(info.data.addition)}
                        </span>
                      </div>
                    </div>
                  )}
                  {info.data && info.data.discount && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Desconto</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>
                          {info.data.discount && currency(info.data.discount)}
                        </span>
                      </div>
                    </div>
                  )}
                  {info.data && info.data.due_date && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Data de vencimento</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>
                          {info.data.due_date &&
                            dateFormatted(info.data.due_date)}
                        </span>
                      </div>
                    </div>
                  )}
                  {info.data && info.data.value_origin && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Valor original</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>
                          {info.data.value_origin &&
                            currency(info.data.value_origin)}
                        </span>
                      </div>
                    </div>
                  )}
                  {info.data && info.data.writable_line && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Linha digitável</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>{info.data.writable_line || ''}</span>
                      </div>
                    </div>
                  )}
                  {info.data && info.data.bar_code && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Código de barras</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>{info.data.bar_code || ''}</span>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {userStatement.transaction_type === 'phone_recharge' && (
                <div className={classes.boxBorderless}>
                  <div className={classes.row}>
                    <div className={classes.titleBig}>
                      <strong>
                        <span>INFORMAÇÕES DA RECARGA</span>
                      </strong>
                    </div>
                  </div>
                  {info.data && info.data.phone_number && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Número</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>
                          ({info.data.area_code}) {info.data.phone_number}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {userStatement.transaction_type === 'pix_transfer' && (
                <div className={classes.boxBorderless}>
                  <div className={classes.row}>
                    <div className={classes.titleBig}>
                      <strong>
                        <span>INFORMAÇÕES DA OPERAÇÂO</span>
                      </strong>
                    </div>
                  </div>
                  {info.data && info.data.counterpart_pix_identifier && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Chave</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>{info.data.counterpart_pix_identifier}</span>
                      </div>
                    </div>
                  )}
                  {info.data && info.data.description && (
                    <div className={classes.row}>
                      <div className={classes.title}>
                        <span>Descrição</span>
                      </div>

                      <div className={classes.subtitle}>
                        <span>{info.data.description}</span>
                      </div>
                    </div>
                  )}
                  <div className={classes.row} />
                </div>
              )}
              {/* <div className={classes.boxValueOperation}>
                  <span className={classes.bvoTitle}>ID da transação</span>
                  <span className={classes.bvoSubTitle}>{userStatement.tradable_id}</span>
                </div> */}
              <span>
                Ouvidoria:{' '}
                <a href={`mailto:${emails[window.location.host]}`}>
                  {emails[window.location.host]}
                </a>
              </span>
            </CardBody>
          )}
        </Card>
      </div>
    </div>
  );
}

export default connect(
  ({ auth, settings }) => ({
    user: auth.limits,
    success: auth.success,
    account: auth.account,
    settings: settings.settings,
  }),
  auth.actions
)(SComprovante);
