import React from 'react';

import {
    Card,
    CardHeader,
    CardBody
} from "../../../partials/controls";
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import './QuickShortcuts.css';

function QuickShortcuts(props) {
    const { intl } = props;
    return(
        <Card className="column col-xs-12 col-xl-12 col-md-12">
            <CardHeader title={intl.formatMessage({id: "QUICK_SHORTCUTS.TITLE"})}>
            </CardHeader>
            <CardBody>
                <div className="column">
                    <Link to="/conta/deposito/boleto" className="quickshortcut">
                        <div className="col-md-12 col-lg-12 col-xs-12 mb-4 mt-4">
                            <h4 className="text-dark-65">
                                Depositar em sua conta via boleto
                            </h4>
                            <p className="text-muted mt-1">
                                Realize um deposito em sua conta usando boleto bancário.
                            </p>
                        </div>
                    </Link>
                    <Link to="/conta/deposito/ted" className="quickshortcut">
                        <div className="col-md-12 col-lg-12 col-xs-12 mb-4 mt-4">
                            <h4 className="text-dark-65">
                                Depositar em sua conta via TED
                            </h4>
                            <p className="text-muted mt-1">
                                Realize um deposito em sua conta usando uma TED.
                            </p>
                        </div>
                    </Link>
                    <Link to="/transferencia/ted" className="quickshortcut">
                        <div className="col-md-12 col-lg-12 col-xs-12 mb-4 mt-4">
                            <h4 className="text-dark-65">Transferência (TED)</h4>
                            <p className="text-muted mt-1">
                                Realize uma TED usando seu saldo para qualquer banco do Brasil.
                            </p>
                        </div>
                    </Link>
                    <Link to="/servicos/recarga-celular" className="quickshortcut">
                        <div className="col-md-12 col-lg-12 col-xs-12 mb-4 mt-4">
                            <h4 className="text-dark-65">Recarregar celular</h4>
                            <p className="text-muted mt-1">
                                Recarregue telefone celular pré-pago de qualquer operadora do Brasil.
                            </p>
                        </div>
                    </Link>
                </div>
            </CardBody>
        </Card>
    )
}

export default injectIntl(QuickShortcuts)