import { put, takeLatest } from "redux-saga/effects";
import { initialState, types, actions } from "./actions";

export const reducer = (state = initialState, action) => {
    switch (action.type) {

        case types.PasswordRegisterLoaded: {
            const { register } = action.payload;
            return { ...state, password: register };
        }

        default:
            return state;
    }
}

export function* saga() {
    yield takeLatest(types.PasswordRegister, function* registerPassword(action) {
        const { register } = action.payload;
        yield put(actions.loadPassword(register));
    });
}