import React, { useState, useMemo } from 'react';
import { Subaside } from '../../../components/subaside/Subaside';
import { Card, CardBody } from '../../../../partials/controls';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import * as auth from '../../../pages/User/Auth/_redux/authRedux';

import { ButtonCustom } from '../../../components/button/ButtonCustom';

import { useHtmlClassService } from '../../../_core/MetronicLayout';
import hex from '../../../utils/hexToRgba';

import { ENDPOINT } from '../../../../services/enums/EndPoint';
import axios from 'axios';
import { encode as base64_encode } from 'base-64';

const useStyles = makeStyles(theme => ({
  accountBox: {
    marginBottom: '25px',
    marginTop: '40px',
  },

  head: {
    color: 'black',
    fontSize: '15px',
    fontWeight: 'bold',
  },

  row: {
    flexDirection: 'row',
    display: 'flex',
    paddingRight: '20px',
    marginBottom: '10px',
    paddingBottom: '5px',
  },

  boxButton: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
  },

  boxPai: {
    textAlign: 'center',
    marginRight: 25,
    cursor: 'pointer',
  },

  boxPai2: {
    textAlign: 'center',
    marginRight: 25,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },

  button: {
    width: '80px',
    height: '80px',
    backgroundColor: hex('#37393C', 0.1),
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
  },

  button2: {
    width: '51px',
    height: '51px',
    backgroundColor: hex('#37393C', 0.1),
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
  },

  buttonCopy: {
    width: '310px',
    height: '65px',
    backgroundColor: '#fff',
    border: `1px solid ${hex('#37393C', 0.1)}`,
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  buttonCopyPaste: {
    width: '45%',
    height: '50px',
  },

  buttonCopyPaste1: {
    marginTop: '7px',
    width: '45%',
    height: '50px',
  },

  text: {
    fontSize: 12,
    fontWeight: '400',
    color: '#4C4859',
  },

  text2: {
    fontSize: 15,
    fontWeight: '700',
    color: '#37393C',
    marginLeft: 15,
  },

  textCopyPaste: {
    fontSize: 12,
    fontWeight: '500',
    color: '#37393C',
    opacity: 0.8,
    marginLeft: '30px',
  },
}));

function PixPage(props) {
  const [copyPasteOn, setCopyPasteOn] = useState(false);
  const [ready] = useState(false);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const toogleCopyPaste = () => {
    setCopyPasteOn(!copyPasteOn);

    // console.log(layoutProps);
  };

  const makeTransfer = () => {
    const encoded = base64_encode(code);
    const urlSafeEncoded = encoded
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '.');

    setLoading(true);

    try {
      axios
        .get(`${ENDPOINT.PIX_QR_CODE}/${urlSafeEncoded}`)
        .then(responseSuccess => {
          localStorage.setItem(
            'copyPaste',
            JSON.stringify(responseSuccess.data)
          );
          props.history.push('/conta/pix/pagar');

          setLoading(false);
        })
        .catch(responseError => {
          console.log(responseError);
          setLoading(false);
        });
      // generateTransfer(data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  // constantes do tenant-modules
  const enablePix = true;

  return (
    <div className="row">
      <Subaside />
      {enablePix ? (
        <div className="col-md-8 col-xs-12 col-lg-8">
          <Card className="col-xs-12 col-xl-12 col-md-12">
            <CardBody>
              <h4 className={classes.head}>PIX</h4>
              <div className={classes.accountBox}>
                <div className={classes.boxButton}>
                  <div
                    className={classes.boxPai}
                    onClick={() => props.history.push('/conta/pix/pagar')}
                  >
                    <div className={classes.button}>
                      <img
                        alt=""
                        width={30}
                        src="/media/svg/files/icon-payments.svg"
                      />
                    </div>
                    <span className={classes.text}>Pagar</span>
                  </div>

                  <div
                    className={classes.boxPai}
                    onClick={() => props.history.push('/conta/pix/receber')}
                  >
                    <div className={classes.button}>
                      <img
                        alt=""
                        width={30}
                        src="/media/svg/files/icon-deposit.svg"
                      />
                    </div>
                    <span className={classes.text}>Receber</span>
                  </div>

                  <div>
                    {!copyPasteOn ? (
                      <div
                        onClick={toogleCopyPaste}
                        className={classes.buttonCopy}
                      >
                        <img
                          alt=""
                          width={16}
                          src="/media/svg/files/icon-copy.svg"
                        />
                        <span className={classes.textCopyPaste}>
                          Pix Copia e Cola (QR Code)
                        </span>
                      </div>
                    ) : (
                      <div style={{ width: '300px' }}>
                        <input
                          disabled={ready}
                          autoComplete="off"
                          placeholder="Digite o código gerado"
                          type="text"
                          className="form-control h-auto py-4 px-6"
                          name="barcode"
                          onChange={e => {
                            setCode(e.target.value);
                          }}
                        />

                        <div
                          style={{
                            flexDdirection: 'row',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <ButtonCustom
                            className={classes.buttonCopyPaste1}
                            disabled={code === '' || ready || loading}
                            type="submit"
                            layoutProps={layoutProps}
                            onClick={e => {
                              e.preventDefault();
                              makeTransfer();
                            }}
                          >
                            Continuar
                            {(ready || loading) && (
                              <span className="ml-3 spinner spinner-white"></span>
                            )}
                          </ButtonCustom>

                          <ButtonCustom
                            className={classes.buttonCopyPaste}
                            layoutProps={layoutProps}
                            onClick={e => {
                              e.preventDefault();
                              toogleCopyPaste();
                            }}
                          >
                            Cancelar
                          </ButtonCustom>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Conferir funcionamento do Minhas chaves. Fazer os testes necessários! */}
              {2 === 3 ? (
                <>
                  <h4 className={classes.head}>Mais opções</h4>
                  <div className={classes.accountBox}>
                    <div className={classes.boxButton}>
                      <div className={classes.row}>
                        <div
                          className={classes.boxPai2}
                          onClick={() =>
                            props.history.push('/conta/pix/receber')
                          }
                        >
                          <div className={classes.button2}>
                            <img
                              alt=""
                              width={30}
                              src="/media/svg/files/icon-pix-new-key.svg"
                            />
                          </div>
                          <span className={classes.text2}>Registrar chave</span>
                        </div>

                        <div
                          className={classes.boxPai2}
                          onClick={() =>
                            props.history.push('/conta/pix/receber')
                          }
                        >
                          <div className={classes.button2}>
                            <img
                              alt=""
                              width={30}
                              src="/media/svg/files/icon-pix-my-keys.svg"
                            />
                          </div>
                          <span className={classes.text2}>Minhas chaves</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </CardBody>
          </Card>
        </div>
      ) : (
        <div className="col-md-8 col-xs-12 col-lg-8">
          <Card className="col-xs-12 col-xl-12 col-md-12">
            <CardBody>
              <h5 className={classes.head}>
                Pix indisponível. Tente novamente mais tarde
              </h5>
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  );
}

export default connect(
  ({ auth, settings }) => ({
    user: auth.limits,
    success: auth.success,
    account: auth.account,
    settings: settings.settings,
  }),
  auth.actions
)(PixPage);
