import React, { useEffect } from 'react';
import { Card, CardBody } from '../../../../../partials/controls';
import { DepositUIProvider } from '../DepositUIContext';
import { DepositLoadingDialog } from '../loading/DepositLoadingDialog';
import { connect } from 'react-redux';
import * as deposit from '../../_redux/deposit/depositRedux';
import { currency } from '../../../../utils/currency.utils';
import { dateFormatted } from '../../../../utils/date.utils';
import SVG from "react-inlinesvg";
import { boletoBarcodeSvg } from "boleto-barcode-svg";

const DepositBillPage = (props) => {
    const { history, requestBill, billDeposit, match: { params } } = props;

    const depositUIEvents = {
        openFetchCustomersDialog: () => {
          history.push(`/conta/deposito/boleto/${params.id}`);
        }
    }
    
    useEffect(() => {
        requestBill(params.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestBill]);

    return (
        <DepositUIProvider depositUIEvents={depositUIEvents}>
        <DepositLoadingDialog />
      
        <div className="row">
            
            {/** begin::Vencimento */}
            <Card className="col-xs-12 col-lg-4 col-md-4 mr-2">
                <CardBody>
                    <h3>{currency(billDeposit.value)}</h3>
                    <p className="text-muted mt-1">
                        Data do vencimento: <span>{dateFormatted(billDeposit.due_date)}</span>
                    </p>
                </CardBody>
            </Card>
            {/** end::Vencimento */}

            {/** begin::Codigo_de_barras */}
            <Card className="col-xs-12 col-lg-7 col-md-7 ml-2">
                <CardBody className="text-center justify-content-center">
                    <div className="col-md-12">
                        <p className="text-muted mt-1">
                            {billDeposit.bar_code} 
                        </p>
                        {(() => {
                            if(billDeposit.writable_line) {
                                return (
                                    <SVG
                                        src={boletoBarcodeSvg(billDeposit.writable_line)}
                                    ></SVG>
                                );
                            }
                        })()}
                    </div>
                    <div className="col-md-12 text-center pt-5">
                        <a 
                            href={billDeposit.payment_link} 
                            rel="noopener noreferrer"
                            target="_blank" 
                            className="btn btn-dark"
                        >
                            Boleto PDF
                        </a>
                    </div>
                </CardBody>
            </Card>
            {/** end::Codigo_de_barras */}
        </div>
      </DepositUIProvider>
    );
}

export default connect(
    ({ deposit: { billDeposit } }) => ({ billDeposit }),
    deposit.actions
)(DepositBillPage);