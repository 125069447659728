import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Document } from "./Document";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../helpers";

export const DocumentFile = (props) => {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            colors: uiService.getColors()
        };
    }, [uiService]);

    const style = {
        file: { 
            position: 'relative', 
            height: '30px', 
            width: '100px' 
        },
        input: { 
            position: 'absoulte', 
            opacity: 0, 
            top: 0, 
            left: 0, 
            right: 0,
            bottom: 0 
        },
        label: {
            position: 'absolute', 
            borderRadius: '3px', 
            top: 0, 
            right: 0, 
            left: 0, 
            bottom: 0, 
            backgroundColor: layoutProps.colors.secondary,
            color: '#fff', 
            lineHeight: '26px', 
            textAlign: 'center', 
            cursor: 'pointer'
        }
    }

    return (<>
            {(() => {
                if(props.approved === 1 || props.approved === 4) {
                    return (
                        <Document
                            backgroundColor="#46D39A"
                            title={props.title}
                            icon={props.icon}
                        >  
                            <p className="mt-10">
                                <SVG 
                                    src={toAbsoluteUrl("/media/svg/icons/Register/Register-Approved.svg")}
                                >
                                </SVG> 
                                &nbsp;{props.approved === 1 ? 'Aprovado' : 'Imagem selecionada'}
                            </p>
                        </Document>)
                }

                return(
                    <Document
                        title={props.title}
                        subtitle={props.subtitle}
                        icon={props.icon}
                    >
                        <div className="file" style={{
                            ...style.file
                        }}>
                            <label htmlFor="file-input" style={{
                                ...style.label
                            }}>Selecionar</label>
                            <input 
                                onChange={props.onChange}
                                type="file" 
                                accept="image/jpeg"
                                name={props.name} 
                                style={{
                                    ...style.input
                                }} 
                                id="file-input" 
                            />
                        </div>
                    {props.children}
                </Document>
                )
            })()}
    </>);
}