import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as deposit from '../../_redux/deposit/depositRedux';

import { Card, CardBody } from '../../../../../partials/controls';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import { CardMessage } from '../../../../components/message/CardMessage';
import { Redirect } from 'react-router-dom';
import ErrorFields from '../../../../components/alert/ErrorFields';
import {
  formatDate,
  moneyMask,
  removeCurrencyMask,
  formatPrice,
  accountMask,
} from '../../../../utils/currency.utils';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { addDays } from 'date-fns';

import UserServices from '../../../../../services/UserServices';

registerLocale('ptBR', ptBR);

function DepositForm(props) {
  const {
    makeDeposit,
    billDeposit,
    newDeposit,
    error_fields,
    fetchError,
  } = props;

  const [loading, setLoading] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [accountInfo, setAccountInfo] = useState([]);
  const [nominalAccount, setNominalAccount] = useState(false);
  const [nominalInfo, setNominalInfo] = useState([]);
  const [haveNominal, setHaveNominal] = useState(false);
  const [contaCorrente, setContaCorrente] = useState(false);

  // Schema de validação dos campos
  const DepositSchema = Yup.object().shape({
    value: Yup.string().test(
      'verify-is-lower-than',
      'O valor deve ser maior que R$ 6,00',
      value => {
        return removeCurrencyMask(value) >= 6;
      }
    ),
    //due_date: Yup
    //.string()
    //.required('A data de vencimento é obrigatória.')
    //.nullable()
  });
  // - - -

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  // Inicializando e validando formulário
  const formik = useFormik({
    initialValues: {
      value: 'R$ 0,00',
      //due_date: ''
    },
    validationSchema: DepositSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const data = {
        ...values,
        value: removeCurrencyMask(values.value),
        due_date: formatDate(addDays(new Date(), 10)),
        is_nominal: nominalAccount,
      };

      enableLoading();
      setTimeout(() => {
        try {
          makeDeposit(data);
          disableLoading();
        } catch (error) {
          setSubmitting(false);
          disableLoading();
        }
      }, 1000);
    },
  });
  // - - -

  const loadInfo = async () => {
    let info = await UserServices.getAccount();
    setAccountData(info.data);

    let provider = await UserServices.getAccountProvider();
    setAccountInfo(provider.data.results);

    if (provider.data.results.length > 0) {
      const arrayNominal = provider.data.results.filter(
        provider => provider.type === 'graphic'
      );
      const arrayCorrente = provider.data.results.filter(
        provider => provider.type === 'nominal'
      );
      if (arrayNominal.length > 0) {
        setNominalInfo(arrayNominal);
        setHaveNominal(true);
      }
      if (arrayCorrente.length > 0) {
        setContaCorrente(true);
      }
    }
  };

  useEffect(() => {
    loadInfo();
  }, []);

  useEffect(() => {
    if (accountInfo.length > 0) {
      if (nominalAccount) {
        const nominal = accountInfo.filter(
          provider => provider.type === 'nominal'
        );

        setNominalInfo(nominal);
      } else {
        const graphic = accountInfo.filter(
          provider => provider.type === 'graphic'
        );
        setNominalInfo(graphic);
      }
    }
  }, [accountInfo, nominalAccount]);

  const updateTax = () => {
    if (accountData.length === 0) return;
    let tax = 0;

    if (
      accountData.limits.deposit.fee_amount > 0 ||
      accountData.limits.deposit.fee_amount_nominal > 0
    ) {
      tax = nominalAccount
        ? accountData.limits.deposit.fee_amount_nominal
        : accountData.limits.deposit.fee_amount;
    }

    return `Taxa de Emissão de Boleto: ${formatPrice(tax)}`;
  };

  const updateTaxPercent = () => {
    if (accountData.length === 0) return;
    let tax = 0;

    if (
      accountData.limits.deposit.fee_percent > 0 ||
      accountData.limits.deposit.fee_percent_nominal > 0
    ) {
      tax = nominalAccount
        ? accountData.limits.deposit.fee_percent_nominal
        : accountData.limits.deposit.fee_percent;

      tax = (tax / 100) * removeCurrencyMask(formik.values.value);
    }

    return `Taxa de serviço: ${formatPrice(tax)}`;
  };

  const updateLimit = () => {
    let string = '';
    if (accountData.length === 0) return '';

    if (accountData.limits.deposit.daily_value > 0) {
      string = `No momento o seu limite máximo é de ${formatPrice(
        accountData.limits.deposit.daily_value
      )} por depósito.`;
    }

    return string;
  };

  return (
    <>
      <CardMessage
        message={`Digite abaixo o valor desejado para realizar o seu depósito com boleto bancário.
            ${updateLimit()}`}
      >
        <div>
          <br />
          {updateTax()}
          <br />
          {updateTaxPercent()}

          {/* <div className="col-md-12 col-lg-12 col-xs-12"> */}
          <div className="row mx-sm-1 mx-md-1 mx-lg-1 mx-xs-1 my-sm-4 my-md-4 my-lg-4 my-xs-4">
            {(haveNominal || contaCorrente) && (
              <div style={{ flexDirection: 'column' }}>
                <div className="row">
                  <p>De qual conta deseja enviar?</p> &nbsp;
                  <div>
                    <input
                      type="radio"
                      onChange={() => {
                        setNominalAccount(false);
                      }}
                      name="nominal"
                      checked={!nominalAccount}
                    />
                    &nbsp;Conta Digital &nbsp;&nbsp;&nbsp;
                    {contaCorrente && (
                      <>
                        <input
                          type="radio"
                          onChange={() => {
                            setNominalAccount(true);
                          }}
                          name="nominal"
                          checked={nominalAccount}
                        />
                        &nbsp;Conta Corrente
                      </>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="boxNominalInfo">
                    <div>
                      <div className="rowNominalInfo">
                        <span className="titleNominalInfo">Nome:</span>
                        &nbsp;&nbsp;
                        <span>{nominalInfo[0].receiver_name}</span>
                      </div>
                      <div className="rowNominalInfo">
                        <span className="titleNominalInfo">
                          Agência sem dígito:
                        </span>
                        &nbsp;&nbsp;
                        <span>{nominalInfo[0].branch.slice(0, 4)}</span>
                      </div>
                      <div className="rowNominalInfo">
                        <span className="titleNominalInfo">
                          Conta com dígito:
                        </span>
                        &nbsp;&nbsp;
                        <span>{accountMask(nominalInfo[0].number)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* </div> */}
        </div>
      </CardMessage>

      {/** redireciona para exibição do boleto */}
      {(() => {
        if (newDeposit !== 0) {
          return (
            <Redirect
              exact={true}
              from="/conta/deposito/boleto"
              to={`/conta/deposito/boleto/${billDeposit.id}`}
            />
          );
        }
      })()}
      {/** - - - */}

      {/** begin::ModalError */}
      <ErrorFields
        error_fields={error_fields}
        show={error_fields !== '' ? true : false}
        onHide={() => {
          fetchError('');
          setLoading(false);
        }}
      />
      {/** end::ModalError */}

      <Card className="col-xs-12 col-xl-12 col-md-12">
        <CardBody>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              {/** begin::Valor*/}
              <div className="col-md-12 col-lg-12 col-xs-12">
                <div className="form-group fv-plugins-icon-container">
                  <label htmlFor="value">Valor:</label>
                  <input
                    placeholder="R$ 0,00"
                    type="text"
                    className="form-control h-auto py-4 px-6"
                    name="value"
                    {...formik.getFieldProps('value')}
                    onChange={e => {
                      formik.setFieldValue('value', moneyMask(e.target.value));
                    }}
                  />
                  {formik.touched.value && formik.errors.value ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.value}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/** end::Valor*/}

              {/* begin: Data de Vencimento */}
              {/*<div className="col-md-6 col-lg-6 col-xs-6">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="value">Data de Vencimento:</label>
                                <DatePicker
                                    locale="ptBR"
                                    className="form-control h-auto py-4 px-6"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={addDays(new Date(), 1)}
                                    selected={formik.values.due_date}
                                    onChange={(value) => {
                                        formik.setFieldValue("due_date", value);
                                    }}
                                />
                                {formik.touched.due_date && formik.errors.due_date ? (
                                    <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.due_date}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>*/}
              {/* end: Data de Vencimento */}
            </div>

            <div className="row">
              <div className="col-md-12 col-md-offset-2">
                <ButtonCustom layoutProps={props.layoutProps}>
                  Gerar boleto
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </ButtonCustom>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
}

export default injectIntl(
  connect(
    ({ deposit: { billDeposit, newDeposit, error_fields } }) => ({
      billDeposit,
      newDeposit,
      error_fields,
    }),
    deposit.actions
  )(DepositForm)
);
