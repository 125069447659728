/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react';
import { Link, Switch, Redirect } from 'react-router-dom';
import {
  ContentRoute,
  useHtmlClassService,
} from '../../../../../app/components';
import Login from './Login';
import RegisterPage from '../../Register/RegisterPage';
import ForgotPassword from './ForgotPassword';
import '../../../../../assets/sass/pages/login/classic/login-1.scss';
import ModalNotice from '../../../../components/alert/ModalNotice';
import { FormattedMessage } from 'react-intl';
import { useLang } from '../../../../../i18n/Metronici18n';
import { appStoreURI, playStoreURI } from '../../../../utils/storeURI.utils';

export default function AuthPage() {
  const locale = useLang();
  const uiService = useHtmlClassService();
  const [loading, setLoading] = useState(false);

  const layoutProps = useMemo(() => {
    return {
      bg_login: uiService.getBgLogin(),
      logo: uiService.getLogo(),
      colors: uiService.getColors(),
    };
  }, [uiService]);

  return (
    <div className="d-flex flex-column flex-root">
      {/*begin::Login*/}
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
        id="kt_login"
      >
        {/*begin::Aside*/}
        <div
          className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat pt-10 p-lg-10"
          style={{
            backgroundImage: `url(${layoutProps.bg_login})`,
            backgroundPosition: 'center',
          }}
        >
          {/*begin: Aside Container*/}
          <div className="d-flex flex-row-fluid pt-10 flex-column justify-content-between">
            {/* start:: Aside content */}
            <div className="flex-column-fluid d-flex align-items-center flex-column justify-content-center">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto">
                <img
                  alt="Logo"
                  className="max-h-70px center"
                  src={layoutProps.logo}
                />
              </Link>
              {/* end:: Aside header */}
            </div>
            {/* end:: Aside content */}
          </div>
          {/*end: Aside Container*/}
        </div>
        {/*begin::Aside*/}

        {/*begin::Content*/}
        <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
          {/*begin::Content header*/}
          {(appStoreURI() !== '' || playStoreURI() !== '') && (
            <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <a
                onClick={() => setLoading(true)}
                className="font-weight-bold ml-2"
                style={{ color: `${layoutProps.colors.secondary}` }}
              >
                <FormattedMessage id="AUTH.GENERAL.DOWNLOAD_APP" />
              </a>
            </div>
          )}
          {/*end::Content header*/}

          {/** begin::Modal Open Account */}
          <ModalNotice
            show={loading}
            onHide={() => {
              setLoading(false);
            }}
            title="AUTH.GENERAL.DOWNLOAD_ACCOUNT"
            message="LOGIN.OPEN_ACCOUNT.MESSAGE"
          >
            <div className="row text-center mt-10">
              {playStoreURI() !== '' && (
                <div className="col-md-6">
                  <a
                    rel="noopener noreferrer"
                    href={playStoreURI()}
                    target="_blank"
                    style={{
                      color: `${layoutProps.colors.secondary}`,
                    }}
                  >
                    <img
                      alt="android"
                      src={
                        locale === 'pt'
                          ? '/media/logos/bt_android_pt.svg'
                          : '/media/logos/bt_android_en.svg'
                      }
                      width="200px"
                    ></img>
                  </a>
                </div>
              )}
              {appStoreURI() !== '' && (
                <div className="col-md-6" style={{ marginTop: 10 }}>
                  <a
                    rel="noopener noreferrer"
                    href={appStoreURI()}
                    target="_blank"
                    style={{
                      color: `${layoutProps.colors.secondary}`,
                    }}
                  >
                    <img
                      alt="ios"
                      src={
                        locale === 'pt'
                          ? '/media/logos/bt_ios_pt.svg'
                          : '/media/logos/bt_ios_en.svg'
                      }
                      width="180px"
                    ></img>
                  </a>
                </div>
              )}
            </div>
          </ModalNotice>
          {/** end::Modal Open Account */}

          {/* begin::Content body */}
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <Switch>
              <ContentRoute path="/auth/login" component={Login} />
              <ContentRoute path="/cadastro" component={RegisterPage} />
              <ContentRoute path="/alterar/senha" component={ForgotPassword} />
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
          {/*end::Content body*/}

          {/* begin::Mobile footer */}
          <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
              &copy; 2020 All Rights Reserved.
            </div>
          </div>
          {/* end::Mobile footer */}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Login*/}
    </div>
  );
}
