export const ProductStatusCssClasses = ["success", "info", ""];
export const ProductStatusTitles = ["Selling", "Sold"];
export const ProductConditionCssClasses = ["success", "danger", ""];
export const ProductConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];

// Transaction Types
export const optionsTransactionTypes = [
  { id: "bank_transfer", text: 'TRANSFER.TYPE.BANK_TRANSFER' },
  { id: "bill_payment", text : 'TRANSFER.TYPE.BILL_PAYMENT' },
  { id: "deposit", text: 'TRANSFER.TYPE.DEPOSIT'},
  { id: "internal_transfer", text: 'TRANSFER.TYPE.INTERNAL_TRANSFER'},
  {id: "pos_sale", text: 'TRANSFER.TYPE.POS_SALE'},
  {id: "phone_recharge", text: 'TRANSFER.TYPE.PHONE_RECHARGE'}
];
// - - -

// Card Types
export const optionsCardTypes = [
  { id: "debit", text: 'TRANSFER.CARD.TYPE.DEBIT' },
  { id: "credit", text : 'TRANSFER.CARD.TYPE.CREDIT' },
];
// - - -

export const initialFilter = {
  filter: {
    type: "",
    date_from: "",
    date_to: "",
    transaction_type: ""
  },
  sortOrder: "asc", // asc||desc
  sortField: "VINCode",
  pageNumber: 1,
  pageSize: 10
};
