import { ENDPOINT } from "./enums/EndPoint";
import ApiServices from "./ApiServices";

/**
 * Busca as últimas recargas, por página.
 * @param {number} page
 */
const getAllRecharges = (page) => {
    return ApiServices.get(ENDPOINT.PHONE_RECHARGE, {
        params: {
            page: page
        }
    });
}

/**
 * Busca as operados cadastradas 
 * de acordo com o código de area.
 * @param {number} params 
 */
const getCarriersByAreaCode = (params) => {
    return ApiServices.get(`${ENDPOINT.CARRIERS}`, {
        params: {
            country_code: "55",
            area_code: params
        }
    });
}

/**
 * Busca os valores dos planos de recargas,
 * de acordo com o id da operadora.
 * @param {number} id
 * @param {number} area_code
 */
const getValuesByCarriers = ({ id, area_code}) => {
    return ApiServices.get(`${ENDPOINT.CARRIERS}/${id}`, {
        params: {
            country_code: "55",
            area_code: area_code,
        }
    });
}

/**
 * Gera uma recarga telefônica.
 * @param {number} carrier_id
 * @param {number} phone_ddd
 * @param {number} phone_number
 * @param {decimal} value
 */
const generateRecharge = ({
    carrier_id,
    phone_ddd,
    phone_number,
    value
}) => {
    return ApiServices.post(ENDPOINT.PHONE_RECHARGE, {
        carrier_id: carrier_id,
        country_code: "55",
        area_code: phone_ddd,
        phone_number: phone_number,
        value: value
    });
}

const PhoneServices = {
    getAllRecharges,
    getCarriersByAreaCode,
    getValuesByCarriers,
    generateRecharge
}
export default PhoneServices;