import React, { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';
import { useHtmlClassService } from '../../../../components';
import * as fromState from './_redux/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onlyNumbers, cnpjMask } from '../../../../utils/mask_cpf_cnpj.utils';
import ApiServices from '../../../../../services/ApiServices';
import { ENDPOINT } from '../../../../../services/enums/EndPoint';
import MessageAlert from '../../../../components/alert/MessageAlert';
import _ from 'lodash';

function BasicInformation(props) {
  const { registerBasicInformation, basic } = props;
  const intl = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [listErrors, setListErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(false);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const RegistrationSchema = Yup.object().shape({
    trade_name: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(150, 'Maximum 150 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    name: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    cnpj: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .cnpj(
        intl.formatMessage({
          id: 'FIELD.INVALID.CNPJ',
        })
      ),
  });

  const verifyInformations = async data => {
    try {
      await ApiServices.post(ENDPOINT.REGISTER_PERSON, data);
    } catch (err) {
      const { field_errors } = err.response.data;
      let listErrors = {};

      if (field_errors.email) {
        listErrors.email = field_errors.email[0];
      }

      if (field_errors.cnpj) {
        listErrors.cnpj = field_errors.cnpj[0];
      }

      console.log('retorno: ', listErrors);
      return listErrors;
    }
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const nextStep = () => {
    history.push('/cadastro/telefone');
  };

  const initialValues = {
    name: basic.name || '',
    trade_name: basic.trade_name || '',
    email: basic.email || '',
    cnpj: basic.cnpj > 0 ? cnpjMask(basic.cnpj) : '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      values.cnpj = onlyNumbers(values.cnpj);

      const response = await verifyInformations({
        cnpj: values.cnpj,
        email: values.email,
      });

      if (!_.has(response, 'cnpj') && !_.has(response, 'email')) {
        setListErrors({});
        setHasErrors(false);
        registerBasicInformation(values);
        nextStep();
      } else {
        setListErrors(response);
        setHasErrors(true);
      }
    },
  });

  const ErrorMessage = () => {
    let messageText = '';
    const { cnpj, email } = listErrors;

    if (cnpj) {
      messageText += `${cnpj.message}          `;
    }

    if (email) {
      messageText += `${email.message}`;
    }

    return (
      <MessageAlert
        show={hasErrors}
        title="Opsss... Aconteceu um erro!"
        message={messageText}
        onHide={() => {
          setHasErrors(false);
          setLoading(false);
        }}
      />
    );
  };

  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      <ErrorMessage />

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Razão Social */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">
            <FormattedMessage id="REGISTER.LABEL.COMPANY_NAME" />
          </label>
          <input
            type="text"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              'name'
            )}`}
            name="name"
            {...formik.getFieldProps('name')}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
        {/* end: Razão Social */}

        {/* begin: Username */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">
            <FormattedMessage id="REGISTER.LEGAL.NAME" />
          </label>
          <input
            type="text"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              'trade_name'
            )}`}
            name="trade_name"
            {...formik.getFieldProps('trade_name')}
          />
          {formik.touched.trade_name && formik.errors.trade_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.trade_name}</div>
            </div>
          ) : null}
        </div>
        {/* end: Username */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">
            <FormattedMessage id="REGISTER.LABEL.EMAIL" />
          </label>
          <input
            type="email"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              'email'
            )}`}
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: CNPJ */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">
            <FormattedMessage id="REGISTER.LEGAL.DOCUMENT" />
          </label>
          <input
            maxLength="18"
            type="text"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              'cnpj'
            )}`}
            name="cnpj"
            {...formik.getFieldProps('cnpj')}
            onChange={e => {
              formik.setFieldValue('cnpj', cnpjMask(e.target.value));
            }}
          />
          {formik.touched.cnpj && formik.errors.cnpj ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.cnpj}</div>
            </div>
          ) : null}
        </div>
        {/* end: CNPJ */}

        <div className="form-group d-flex flex-wrap flex-center">
          <div className="col-md-10">
            <ButtonCustom
              layoutProps={layoutProps}
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-6 py-4 my-3 mx-4"
              size="sm"
            >
              <FormattedMessage id="AUTH.REGISTER.CREATE" />
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </ButtonCustom>
          </div>
        </div>
      </form>
    </div>
  );
}

export default connect(
  ({ legalPerson }) => ({
    basic: legalPerson.basic,
  }),
  fromState.actions
)(BasicInformation);
