import React, { useMemo } from 'react';

import { LoteLoadingDialog } from './loading/LoteLoadingDialog';
import { LoteUIProvider } from './LoteUIContext';

import LoteForm from './form/LoteForm';
import { Subaside } from '../../../components/subaside/Subaside';
import { useSelector } from 'react-redux';
import SecurityTransaction from '../../SecurityTransaction';
import { useHtmlClassService } from '../../../_core/MetronicLayout';

export function LotePage({ history }) {
  const { operation } = useSelector(state => state.external);

  const externalUIEvents = {
    openFetchLoteDialog: () => {
      history.push(`/transferencia/ted`);
    },
  };

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  return (
    <div className="row">
      <Subaside />
      <LoteUIProvider externalUIEvents={externalUIEvents}>
        <LoteLoadingDialog />
        <div className="col-md-8 col-xs-12 col-lg-8">
          {(() => {
            if (operation.length !== 0) {
              return (
                <SecurityTransaction
                  layoutProps={layoutProps}
                  operation={operation.operation}
                />
              );
            }
            return <LoteForm layoutProps={layoutProps} />;
          })()}
          {/* <LoteCard /> */}
        </div>
      </LoteUIProvider>
    </div>
  );
}
