export const DepositStatusCssClasses = {
  pending: "warning", 
  liquidated: "success"
};
export const DepositStatusTitles = ["Suspended", "Active", "Pending", ""];
export const DepositTypeCssClasses = ["success", "primary", ""];
export const DepositTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  filter: {
    favored: "",
    document: undefined,
    account_type: "",
    value: '0,00'
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};

export const initialValues = {
  "favored": "",
	"document": undefined,
	"account_number": undefined,
	"account_digit": undefined,
	"account_type": "",
	"bank_branch": undefined,
	"bank_branch_digit": undefined,
	"bank_code": undefined,
	"value": ''
}