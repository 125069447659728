/**
 * Formata os valores para o gráfico
 * @param { Array } value 
 */
export const dataChartFormatted = (value) => {

    const keys = Object.keys(value);
    let credit = []
    let debit = []

    keys.map((i, v) => {
            credit.push(value[i][1])
            debit.push(value[i][0])
        return v;
    });

    return {
        credit: credit,
        debit: debit,
        keys: keys
    }
}