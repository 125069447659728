export const initialExternalState = {
    actionsLoading: false,
    listLoading: false,
    results: [],
    meta: {
      pagination: {}
    },
    lastError: null,
    banks: [],
    operation: [],
    message: "",
    error_fields: ""
}

export const types = {
    ExternalTransferRequest: "[External Transfer] Action",
    ExternalTransferLoaded: "[External Transfer] Loaded",
    ExternalOperationRequest: "[Operation] Request",
    ExternalOperationLoaded: "[Operation] Loaded",
    BanksRequest: "[Banks] Banks Request",
    BanksLoaded: "[Banks] Banks Loaded",
    ErrorLoaded: "[Error] Error Loaded"
};

export const actions = {
    requestExternalTransfer: external => ({ type: types.ExternalTransferRequest, payload: { external } }),
    fetchExternalTransfer: external => ({ type: types.ExternalTransferLoaded, payload: { external } }),
    findBanks: operation => ({ type: types.BanksRequest, payload: { operation } }),
    fetchBanks: operation => ({ type: types.BanksLoaded, payload: { operation } }),
    generateTransfer: operation => ({ type: types.ExternalOperationRequest, payload: { operation } }),
    fetchTransfer: operation => ({ type: types.ExternalOperationLoaded, payload: { operation } }),
	fetchError: error => ({ type: types.ErrorLoaded, payload: { error } })
};