import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardBody, CardFooter } from '../../../partials/controls';
import { connect } from 'react-redux';
import ApiServices from '../../../services/ApiServices';
import { ENDPOINT } from '../../../services/enums/EndPoint';
import ModalNotice from '../../components/alert/ModalNotice';
import {
  dateFormatted,
  dateByTimestampFormatted,
} from '../../utils/date.utils';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { FormattedMessage } from "react-intl";

const PaymentBillPage = props => {
  const {
    match: { params },
    settings,
  } = props;

  const primaryColor = _.get(settings, 'js.colors.theme.base.primary');
  const logo = _.get(settings, 'brand.self.logo');
  
  const [loading, setLoading] = useState(false);
  const [invalidProof, setInvalidProof] = useState(false);
  const [info, setInfo] = useState({});

  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDocument = useCallback(id => {
    setLoading(true);
    ApiServices.get(`${ENDPOINT.BILL_PAYMENTS}/${id}`)
      .then(response => {
        setInfo(response.data);
        setLoading(false);
      })
      .catch(error => {
        if (error.response.status === 422) setInvalidProof(true);
      });
  });
  useEffect(() => {
    getDocument(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verify = data => {
    return data ? data : 'N/A';
  };

  const print = () => {
    let html = `
      <html>
        <head>
          <style>
            * {
              color: #3F4254;
              -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
              color-adjust: exact !important;                 /*Firefox*/
            }

            .divLogo {
              width: 100%;
              height: 60;
              background-color: ${primaryColor};
              border-radius: 5px;
              margin-right: 10px;
            }
            
            .divLogo img {
              margin-left: 10px;
            }

            .text-center {
              text-align: center;
            }

            table {
              width: 100%;
            }

            h3 {
              font-size: 24px;
            }

            td {
              font-size: 17px;
            }
          </style>
        </head>
        <body>
        <div class="divLogo">
            <img src="${logo}">
        </div>
    `;
    
    const print_screen = window.open('about:blank');
    document.getElementsByClassName('print').forEach(function (i) {
      html = html + i.innerHTML;
    })

    html = html + "</body></html>"
    print_screen.document.write(html);
    
    print_screen.window.print();
    // print_screen.window.close();
  };

  return (
    <>
      {/** begin::Modal Open Account */}
      <ModalNotice
        show={invalidProof ? true : false}
        onHide={() => {
          setInvalidProof(false);
          history.goBack();
        }}
        title="BILL.INVALID.TITLE"
        message="BILL.INVALID.DESCRIPTION"
      />
      {/** end::Modal Open Account */}
      <div id="print" className="col-xs-12 col-md-8 col-lg-8">
        {(() => {
          if (loading) {
            return (
              <Card>
                <CardBody>Carregando...</CardBody>
              </Card>
            );
          } else {
            return (
              <>
                <Card className="print">
                  <CardBody>
                    <h3>Código de Barras: {verify(info.bar_code)}</h3>
                  </CardBody>
                </Card>
                <Card className="print">
                  <CardBody>
                    <h3 className="text-center mb-4">
                      Comprovante de Pagamento
                    </h3>
                    <div
                      style={{
                        borderRadius: 20,
                        border: '1px solid #e1e1e8',
                        padding: '20px 20px 10px 20px',
                        marginBottom: '20px',
                      }}
                    >
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>Valor do boleto</td>
                            <td>{_.get(info,'value_origin', 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                          </tr>
                          <tr>
                            <td style={{ border: 'none' }}>Valor pago</td>
                            <td style={{ border: 'none' }}>{_.get(info,'value',0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                          </tr>
                          <tr>
                            <td>Data</td>
                            <td>
                              {verify(
                                dateFormatted(
                                  dateByTimestampFormatted(
                                    new Date(info?.created_at * 1000)
                                  )
                                )
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Vencimento</td>
                            <td>{verify(dateFormatted(info?.due_date))}</td>
                          </tr>
                          <tr>
                            <td>Status</td>
                            <td>
                              <FormattedMessage id={`TRANSFER.TYPE.${_.get(info,'status','').toUpperCase()}`} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
                <CardFooter>
                  <button
                    className="btn btn-secondary"
                    onClick={() => print()}
                  >
                    Imprimir
                  </button>
                </CardFooter>
              </>
            );
          }
        })()}
      </div>
    </>
  );
};

export default connect(({ settings }) => ({
  settings: settings.settings,
}), null)(PaymentBillPage);
