import { ENDPOINT } from "../../services/enums/EndPoint";
import ApiServices from "../../services/ApiServices";

const api = ApiServices;

/**
 * Retorna o resumo da movimentação de conta
 * por paginação
 * 
 * @param {number} page 
 */
export function getSummary() {
  return api.get(`${ENDPOINT.TRANSACTION_SUMMARY}`);
}

/**
 * Retorna o saldo da conta
 * 
 * @param {number} page 
 */
export function getBalance() {
  return api.get(`${ENDPOINT.ACCOUNT_BALANCE}`);
}
