import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../components";
import { Modal } from "react-bootstrap";

export default function CardPage({ show, onHide }) {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <div className="row">
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <p className="text-align-center">Oppppsssss!</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="timeline timeline-5 mt-3">
              Aguarde, brevemente está função estará disponível para você.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-primary btn-elevate"
              >
                Fechar
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        </div>          
      </Switch>
    </Suspense>
  );
}
