import { ENDPOINT } from "./enums/EndPoint";
import ApiServices from "./ApiServices";

/**
 * Verifica autorização
 * @param operation: []any
 */
function authorize({ operation }) {
    return ApiServices.post(`${ENDPOINT.AUTH_TRANSACTION}/${operation.id}?include=userStatement&_method=put`, {
        latitude: operation.latitude,
        longitude: operation.longitude,
        verification_code: operation.verification_code,
        transaction_password: operation.transaction_password
    });
}

const SecurityServices = {
    authorize
}
export default SecurityServices;