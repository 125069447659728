import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { InternalPage } from "./Internal";
import { ExternalPage } from "./External";
import { LotePage } from "./Lote";
import { LayoutSplashScreen, ContentRoute } from "../../components";

export default function TransactionPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from account root URL to /customers */
          <Redirect
            exact={true}
            from="/transferencia"
            to="/transferencia/ted"
          />
        }
        <ContentRoute exact={true} path="/transferencia/interna" component={InternalPage} />
        <ContentRoute exact={true} path="/transferencia/ted" component={ExternalPage} />  
        <ContentRoute exact={true} path="/transferencia/lote" component={LotePage} /> 
        <Redirect from="/transferencia" to="/error" />   
      </Switch>
    </Suspense>
  );
}
