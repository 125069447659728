import * as utils from './LocalStorageHelpers';

export const routes = [
  '/',
  '/cadastro',
  '/cadastro/conta-juridica',
  '/cadastro/conta-juridica/financeiro',
  '/cadastro/conta-fisica',
  '/cadastro/conta-fisica/financeiro',
  '/cadastro/conta-fisica/documento',
  '/cadastro/telefone',
  '/cadastro/endereco',
  '/cadastro/bancos',
  '/cadastro/senha',
  '/cadastro/documentos',
  '/alterar',
  '/alterar/senha',
  '/dashboard',
  '/notificacoes',
  '/auth',
  '/auth/login',
  '/conta',
  '/conta/meus-dados',
  '/conta/deposito/boleto',
  '/conta/deposito/boleto/:id',
  '/conta/deposito/ted',
  '/conta/limits',
  '/conta/resumo',
  '/conta/extrato',
  '/transferencia',
  '/transferencia/ted',
  '/transferencia/interna',
  '/transferencia/lote',
  '/pagamento',
  '/pagamento/automatico',
  '/pagamento/boleto',
  '/pagamento/boleto/:id',
  '/pagamento/consumo',
  '/pagamento/lote',
  '/bill_payments/:id',
  '/servicos',
  '/servicos/recarga-celular',
  '/conta/suporte',
  '/logout',
  '/error/error-v1',
  '/conta/comprovante',
  '/conta/favoritos',
  '/conta/pix',
  '/conta/pix/pagar',
  '/conta/pix/receber',
];

const localStorageLastLocationKey = 'metronic-lastLocation';

function acceptLocation(lastLocation) {
  if (
    lastLocation &&
    lastLocation.pathname &&
    lastLocation.pathname !== '/' &&
    lastLocation.pathname.indexOf('auth') === -1 &&
    lastLocation.pathname !== '/logout'
  ) {
    return true;
  }

  return false;
}

export function saveLastLocation(lastLocation) {
  if (acceptLocation(lastLocation)) {
    utils.setStorage(
      localStorageLastLocationKey,
      JSON.stringify(lastLocation),
      120
    );
  }
}

export function forgotLastLocation() {
  utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
  const defaultLocation = { pathname: '/', title: 'Dashboard' };
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  if (!localStorateLocations) {
    return { pathname: '/', title: 'Dashboard' };
  }

  try {
    const result = JSON.parse(localStorateLocations);
    return result;
  } catch (error) {
    console.error(error);
    return defaultLocation;
  }
}

export function getCurrentUrl(location) {
  return location.pathname.split(/[?#]/)[0];
}

export function checkIsActive(location, url) {
  const current = getCurrentUrl(location);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
}
