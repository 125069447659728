import React, { useMemo } from 'react';

import { BillLoadingDialog } from '../Bill/loading/BillLoadingDialog';
import { BillUIProvider } from '../Bill/BillUIContext';

import { ConsumerForm } from './form/ConsumerForm';
import { useSelector } from 'react-redux';
import SecurityTransaction from '../../SecurityTransaction';
import { useHtmlClassService } from '../../../_core/MetronicLayout';

export function ConsumerPage({ history }) {
  const { operation } = useSelector(state => state.bill);

  const billUIEvents = {
    openFetchBillDialog: () => {
      history.push(`/pagamento/consumo`);
    },
  };

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  return (
    <BillUIProvider billUIEvents={billUIEvents}>
      <BillLoadingDialog />
      <div className="col-md-8 col-xs-12 col-lg-8">
        {(() => {
          if (operation.length !== 0) {
            return (
              <SecurityTransaction
                layoutProps={layoutProps}
                operation={operation}
              />
            );
          }
          return <ConsumerForm layoutProps={layoutProps} />;
        })()}
        {/* <BillCard /> */}
      </div>
    </BillUIProvider>
  );
}
