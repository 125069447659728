import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../helpers";
import PaymentServices from '../../../../../../services/PaymentServices';

export function ButtonColumnFormatter(cellContent, row) {
    const removeFavorite = async (id) => {
        let info = await PaymentServices.removeFavoreds(row.id);

        if (info) {
            window.location.href = '/conta/favoritos';
        }
    };

    return (
        // <Link to={`boleto/${row.id}`}>
        <button onClick={() => removeFavorite(row.id)} className="btn btn-default"
        >
            <SVG
                src={toAbsoluteUrl("/media/svg/icons/Home/Trash.svg")}
            >
            </SVG>
        </button>
        // </Link>
    );
}
