import React from "react";
import { ExtractLoadingDialog } from "./extract-loading-dialog/ExtractLoadingDialog";
import { ExtractCard } from "./ExtractCard";
import { ExtractUIProvider } from "./ExtractUIContext";
import { Subaside } from "../../../components/subaside/Subaside";

export function ExtractPage({ history }) {
  const extractUIEvents = {
    openFetchExtractDialog: () => {
      history.push(`/conta/extrato/fetch`);
    }
  };

  return (
    <div className="row"> 
      <Subaside />
      <ExtractUIProvider extractUIEvents={extractUIEvents}>
        <ExtractLoadingDialog />
        <ExtractCard />   
      </ExtractUIProvider>
    </div>
  );
}
