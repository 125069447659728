import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as extract from '../../_redux/extract/extractRedux';

import { useExtractUIContext } from '../ExtractUIContext';
import { optionsTransactionTypes, optionsCardTypes } from '../ExtractUIHelpers';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../helpers';
import { currency } from '../../../../utils/currency.utils';
import { dateFormatted } from '../../../../utils/date.utils';

import { DatePickerField } from '../../../../../partials/controls';
import { prepareFilter } from './format';

import jsPDF from 'jspdf';
import base64 from '../../../../utils/base64.utils';

function ExtractFilter(props) {
  const { intl, requestExtract, results } = props;

  const [filter, setFilter] = React.useState([]);
  // Extract UI Context
  const extractUIContext = useExtractUIContext();
  const extractUIProps = useMemo(() => {
    return {
      setQueryParams: extractUIContext.setQueryParams,
      queryParams: extractUIContext.queryParams,
    };
  }, [extractUIContext]);
  // - - -

  const applyFilter = values => {
    const newQueryParams = prepareFilter(extractUIProps.queryParams, values);
    requestExtract(newQueryParams);
    setFilter(newQueryParams);
  };

  const type = {
    debit: 'Débito',
    credit: 'Crédito',
    '': '',
  };

  const transactionType = {
    BANK_TRANSFER_RECEIVED: 'TED Recebida',
    BANK_TRANSFER: 'Transferência bancária',
    BILL_PAYMENT: 'Pagamento de boleto',
    DEPOSIT: 'Depósito',
    INTERNAL_TRANSFER: 'Transferência interna',
    POS_SALE: 'Venda POS',
    PHONE_RECHARGE: 'Recarga de telefone',
    INTERNAL_DEBIT: 'Débito interno',
    INTERNAL_CREDIT: 'Crédito interno',
    LIQUIDATED: 'Liquidado',
    DENIED: 'Negado',
    PENDING: 'Pendente',
    CARD_PURCHASE: 'Compra no Cartão',
    PIX_TRANSFER: 'Transferência PIX',
    PIX_TRANSFER_RECEIVED: 'Transferência Pix Recebida',
    '': '',
  };

  const showDate = (inicio, fim) => {
    if (inicio && fim) {
      return `${dateFormatted(filter.filter.date_from)} à ${dateFormatted(
        filter.filter.date_to
      )}`;
    } else {
      return '';
    }
  };

  const salvarPdf = () => {
    if (results.length > 0) {
      let title = `Extrato de Conta`;

      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4',
      });

      var imgData = 'data:image/jpeg;base64,' + base64[window.location.host];

      pdf.addImage(imgData, 'JPEG', 0, 10, 140, 40);

      pdf.setFontSize(13);
      pdf.text(title, 140, 25);

      pdf.setFontSize(13);

      pdf.text(
        `Filtro: ${showDate(filter.filter.date_from, filter.filter.date_to)} ${
          filter.filter.transaction_type
            ? transactionType[filter.filter.transaction_type.toUpperCase()]
            : ''
        }`,
        140,
        40
      );

      pdf.setFontSize(11);

      var x = 10;
      var y = 80;
      var limitPerPage = 20;
      var totalItens = results.length;
      var totalPages = Math.round(totalItens / limitPerPage);
      var currentPage = 1;

      pdf.setFont('Times', 'bold');
      pdf.text('Tipo', 10, 65);
      pdf.text('Data', 50, 65);
      pdf.text('Valor', 110, 65);
      pdf.text('Descrição', 180, 65);

      pdf.setFont('Times', 'normal');

      for (var i = 0; i < totalItens; i++) {
        if (i % 2 === 0) {
          pdf.setFillColor('#f6f6f6');
        } else {
          pdf.setFillColor('#ffffff');
        }
        pdf.roundedRect(5, y - 10, 430, 25, 2, 2, 'F');

        pdf.setTextColor(0, 0, 0);

        pdf.text(type[results[i].type], x, y);
        pdf.text(results[i].formatted_date.replace(' - ', '\n'), x + 40, y);
        pdf.text(currency(results[i].value), x + 100, y);
        pdf.text(
          `${results[i].title.slice(0, 70)} ${
            results[i].title.length > 69 ? '...' : ''
          }\nTipo: ${
            transactionType[results[i].transaction_type.toUpperCase()]
          }`,
          x + 170,
          y
        );
        y += 25;

        if (i === 0 && results.length <= limitPerPage) {
          pdf.text(`Página ${currentPage}/${totalPages}`, 390, 620);
        }

        if (i !== 0 && i % limitPerPage === 0) {
          pdf.text(`Página ${currentPage}/${totalPages}`, 390, 620);
          y = 40;
          pdf.addPage();
          currentPage++;
          pdf.text(`Página ${currentPage}/${totalPages}`, 390, 620);
        }
      }

      pdf.save(`extrato.pdf`);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          date_from: '',
          date_to: '',
          type: '',
          transaction_type: '',
        }}
        onSubmit={values => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              {/** begin::DATA_INICIAL */}
              <div className="col-lg-2">
                <DatePickerField placeholder="dd/mm/yyyy" name="date_from" />
              </div>
              {/** end::DATA_INICIAL */}

              {/** begin::DATA_FINAL */}
              <div className="col-lg-2">
                <DatePickerField placeholder="dd/mm/yyyy" name="date_to" />
              </div>
              {/** end::DATA_FINAL */}

              <div className="col-lg-4">
                <select
                  className="form-control"
                  name="transaction_type"
                  onChange={e => {
                    setFieldValue('transaction_type', e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.transaction_type}
                >
                  <option value="">-- Transação --</option>
                  {optionsTransactionTypes.map((item, index) => (
                    <option value={item.id} key={index}>
                      {intl.formatMessage({
                        id: item.text,
                      })}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-lg-3">
                <select
                  className="form-control"
                  name="type"
                  onChange={e => {
                    setFieldValue('type', e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.type}
                >
                  <option value="">-- Tipo --</option>
                  {optionsCardTypes.map((item, index) => (
                    <option value={item.id} key={index}>
                      {intl.formatMessage({
                        id: item.text,
                      })}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <button type="submit" className="btn btn-sm btn-dark">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl('/media/svg/icons/Text/Filter.svg')}
                    />
                  </span>
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <div>
        <button className="btn btn-sm btn-light" onClick={salvarPdf}>
          <span className="svg-icon menu-icon">
            Exportar Extrato &nbsp;{' '}
            <SVG src={toAbsoluteUrl('/media/svg/files/pdf.svg')} />
          </span>
        </button>
      </div>
    </>
  );
}
export default injectIntl(
  connect(
    ({ extract }) => ({
      results: extract.results,
    }),
    extract.actions
  )(ExtractFilter)
);
