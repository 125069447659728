import React, { useState, useMemo, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonCustom } from '../../../../../components/button/ButtonCustom';
import { useHtmlClassService } from '../../../../../components';
import { connect } from 'react-redux';
import * as fromState from './_redux/actions';
import { useHistory } from 'react-router-dom';
import { phoneNumberFormatted } from '../../../../../utils/phone.utils';

function PhoneVerification(props) {
  const { registerPhone, accountType } = props;
  const history = useHistory();
  const intl = useIntl();
  const [time, setTime] = useState(90);
  const [send, isSend] = useState(false);
  props.setPercent(10);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const RegistrationSchema = Yup.object().shape({
    phone_number: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .phone(
        intl.formatMessage({
          id: 'FIELD.INVALID.PHONE',
        })
      ),
  });

  useEffect(() => {
    if (time !== 0 && send) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [send, time]);

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const nextStep = route => {
    history.push(`/cadastro/conta-${route}`);
  };

  const initialValues = {
    phone_number: '',
    number_one: '',
    number_two: '',
    number_three: '',
    number_four: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: values => {
      registerPhone(values);
      isSend(true);
      setTimeout(() => {
        nextStep(accountType ? 'fisica' : 'juridica');
        props.loadIsVerified();
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin mt-30" style={{ display: 'block' }}>
      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated 
				animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">Número do seu celular:</label>
          <input
            maxLength="15"
            placeholder="(00) 00000-0000"
            type="text"
            className={`form-control h-auto py-5 px-6 
						${getInputClasses('phone_number')}`}
            name="phone_number"
            {...formik.getFieldProps('phone_number')}
            onChange={e => {
              formik.setFieldValue(
                'phone_number',
                phoneNumberFormatted(e.target.value)
              );
            }}
          />

          {formik.touched.phone_number && formik.errors.phone_number ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phone_number}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}
        <div className="form-group d-flex flex-wrap flex-center">
          <div className="col-md-10">
            <ButtonCustom
              layoutProps={layoutProps}
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-6 py-4 my-3 mx-4"
              size="sm"
            >
              <FormattedMessage id="AUTH.REGISTER.NEXT" />
            </ButtonCustom>
          </div>
        </div>
      </form>
    </div>
  );
}

export default connect(
  ({ phone, physicalPerson }) => ({
    phone: phone.phone,
    isVerified: phone.isVerified,
    accountType: physicalPerson.initialized,
  }),
  fromState.actions
)(PhoneVerification);
