import React, { useMemo } from 'react';

// import { InternalUIProvider } from "./InternalUIContext";

import PixPagar from "./PixPagar";
// import InternalForm from './form/InternalForm';
import { Subaside } from '../../../../components/subaside/Subaside';
import SecurityTransaction from '../../../SecurityTransaction';
import { useSelector } from 'react-redux';
import { useHtmlClassService } from '../../../../_core/MetronicLayout';

export function PixPagarPage ({ history }){
    const { operation } = useSelector(state => state.internal);
    
    // const internalUIEvents = {
    //     openFetchInternalDialog: () => {
    //         history.push(`/transferencia/interna/fetch`);
    //     }
    // }

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colors: uiService.getColors()
        };
    }, [uiService]);


    return (
        <div className="row"> 
            <Subaside />
            {/* <InternalUIProvider internalUIEvents={internalUIEvents}> */}
                <div className="col-md-8 col-xs-12 col-lg-8">
                    {(() => {
                        if(operation.length !== 0) {
                            return <SecurityTransaction 
                                        layoutProps={layoutProps} 
                                        operation={operation} 
                                    />
                        }
                        return (<>
                                <PixPagar layoutProps={layoutProps} />
                            </>)
                    })()}
                     {/* <InternalCard /> */}
                </div>
            {/* </InternalUIProvider> */}
        </div>
    )
} 
