export const initialState = {
    zipcode: {}
}

export const types = {
    ZipCodeVerifyLoaded: "[Loaded] ZipCode Verify",
    ZipCodeVerify: "[Verify] ZipCode Verify",
    ZipCodeRegister: "[Register] ZipCode Register",
    ClearZipCode: "[Clear] ZipCode"
}

export const actions = {
    loadZipCode: verify => ({ 
        type: types.ZipCodeVerifyLoaded, 
        payload: { verify } 
    }),
    verifyZipCode: verify => ({ 
        type: types.ZipCodeVerify, 
        payload: { verify } 
    }),
    registerZipCode: register => ({ 
        type: types.ZipCodeRegister, 
        payload: { register } 
    }),
    clearZipCode: register => ({ 
        type: types.ClearZipCode, 
        payload: { register } 
    })
}