import React from 'react';

import { PhoneRechargeLoadingDialog } from './loading/PhoneRechargeLoadingDialog';
import { PhoneRechargeUIProvider } from './PhoneRechargeUIContext';

import PhoneRechargeForm from './form/PhoneRechargeForm';
import { useSelector } from 'react-redux';
import SecurityTransaction from '../../SecurityTransaction';

export function PhoneRechargePage({ history }) {
  const { operation } = useSelector(state => state.phone_recharge);

  const phoneRechargeUIEvents = {
    openFetchPhoneRechargeDialog: () => {
      history.push(`/servicos/recarga-celular`);
    },
  };

  return (
    <PhoneRechargeUIProvider phoneRechargeUIEvents={phoneRechargeUIEvents}>
      <PhoneRechargeLoadingDialog />
      <div className="col-md-8 col-xs-12 col-lg-8">
        {(() => {
          if (operation.length !== 0) {
            return <SecurityTransaction operation={operation} />;
          }
          return <PhoneRechargeForm />;
        })()}
        {/* <PhoneRechargeCard /> */}
      </div>
    </PhoneRechargeUIProvider>
  );
}
