import { put, takeLatest } from "redux-saga/effects";
import { initialState, types, actions } from "./actions";
import { getConfig } from '../../i18n/Metronici18n.js';
import * as balance from "../balance/actions";
import SecurityServices from "../../services/SecurityServices";
import * as auth from "../../app/pages/User/Auth/_redux/authRedux";
import * as internal from "../../app/pages/Transfer/_redux/internal/internalActions";
import * as bills from "../../app/pages/Payment/_redux/bill/billActions";
import * as phone from "../../app/pages/OtherService/_redux/phone-recharge/PhoneRechargeActions";

export const reducer = (state = initialState, action) => {
    switch (action.type) {

        case types.AuthTransactionLoaded: {
            const { results } = action.payload;
            return { ...state, auth_transaction: results, loadingAuthorize: true };
        }

        case types.AuthorizedLoaded: {
            return { ...state, loadingAuthorize: false };
        }

        case types.AuthorizeLoaded: {
            return { ...state, authorized: false };
        }

        default:
            return state;
    }
}

export function* saga() {
    yield takeLatest(types.AuthTransactionRequested, function* authTransactionRequested(action) {
        try {

            const { data } = yield SecurityServices.authorize(action.payload);
            localStorage.setItem('userStatement', JSON.stringify(data.userStatement));

            yield put(actions.fetchAuthTransaction(data));
            yield put(balance.actions.requestBalance());
            yield put(balance.actions.requestSummary());
        } catch (error) {
            yield put(internal.resetOperation([]));
            yield put(auth.actions.logout());
        }
    });

    yield takeLatest(types.AuthorizedLoaded, function* authTransactionRequested(action) {
        try {
            window.location.href = '/conta/comprovante';          
        } catch (error) {
            yield put(auth.actions.logout());
            yield alert(getConfig().selectedLang === 'pt' ? 'Você foi deslogado por errar a senha. Faça o login novamente e repita o processo com a senha de segurança correta.' : 'You have been logged out for making a wrong password. Login again and repeat the process with the correct security password.' );
        }
    });

    yield takeLatest(types.AuthorizedRequest, function* authTransactionRequested(action) {
        try {
            yield put(actions.fetchAuthLoad());
            yield put(internal.fetchInternalTransfer(1));
            yield put(phone.fetchPhoneRecharge(1));
            yield put(bills.fetchBillTransfer(1));
        } catch (error) {
        }
    });
}

