import React, { useMemo } from 'react';

import { ExternalLoadingDialog } from './loading/ExternalLoadingDialog';
import { ExternalUIProvider } from './ExternalUIContext';

import ExternalForm from './form/ExternalForm';
import { Subaside } from '../../../components/subaside/Subaside';
import { useSelector } from 'react-redux';
import SecurityTransaction from '../../SecurityTransaction';
import { useHtmlClassService } from '../../../_core/MetronicLayout';

export function ExternalPage({ history }) {
  const { operation } = useSelector(state => state.external);

  const externalUIEvents = {
    openFetchExternalDialog: () => {
      history.push(`/transferencia/ted`);
    },
  };

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  return (
    <div className="row">
      <Subaside />
      <ExternalUIProvider externalUIEvents={externalUIEvents}>
        <ExternalLoadingDialog />
        <div className="col-md-8 col-xs-12 col-lg-8">
          {(() => {
            if (operation.length !== 0) {
              return (
                <SecurityTransaction
                  layoutProps={layoutProps}
                  operation={operation.operation}
                />
              );
            }
            return <ExternalForm layoutProps={layoutProps} />;
          })()}
          {/* <ExternalCard /> */}
        </div>
      </ExternalUIProvider>
    </div>
  );
}
