import React from "react";
import {
  DepositStatusCssClasses
} from "../../DepositUIHelpers";
import { FormattedMessage } from "react-intl";

export function StatusColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      DepositStatusCssClasses[row.status]
    } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
    <FormattedMessage   
      id={`MY_ACCOUNT.DEPOSIT.STATUS.${row.status.toUpperCase()}`}
    />
    </span>
  );
}
