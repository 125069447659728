import React, { createContext, useContext, useMemo, useEffect } from "react";
import { HtmlClassService } from "./HTMLClassService";
import * as actions from "../../redux/config/settingsRedux";
import { connect } from "react-redux";


const HtmlClassServiceContext = createContext();

/**
 *
 *
 * @export
 * @returns Layout context from 'localStorage'
 */
export function useHtmlClassService() {
  return useContext(HtmlClassServiceContext);
}

export function withHtmlClassService(Component) {
  class WithHtmlClassService extends React.Component {
    static displayName = `WithHtmlClassService(${Component.displayName ||
      Component.name})`;

    static contextType = HtmlClassServiceContext;

    render() {
      return <Component {...this.props} htmlClassService={this.context} />;
    }
  }

  return WithHtmlClassService;
}

export const HtmlClassServiceConsumer = HtmlClassServiceContext.Consumer;

function MetronicLayoutProvider(props) {
  const { children, requestSettings, requestModules, settings } = props;

  useEffect(() => {
    requestSettings()
    requestModules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestSettings]);
  
  const hcs = useMemo(() => {
    const service = new HtmlClassService();
      service.setConfig(settings.settings);
      return service;
  }, [settings]);

  return (
    <HtmlClassServiceContext.Provider value={hcs}>
      {children}
    </HtmlClassServiceContext.Provider>
  );
}

export default connect(({ settings }) => ({ settings }), actions.actions)(MetronicLayoutProvider);
