
import { dateByTimestampFormatted } from "../../../../utils/date.utils";

export const prepareFilter = (queryParams, values) => {
	const { date_from, date_to, type, transaction_type } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};

	// Filter by data
	if(date_from && date_to) {
		filter.date_from = date_from !== "" ? dateByTimestampFormatted(date_from) : undefined;
		filter.date_to = date_to !== "" ? dateByTimestampFormatted(date_to) : undefined;
	}

	// Filter by type
	filter.model = type;
	if (type) {
		filter.type = type;
	}

	// Filter by transaction type
	filter.model = transaction_type;
	if (transaction_type) {
		filter.transaction_type = transaction_type;
	}
	filter.page = 1;
	
	newQueryParams.filter = filter;
	return newQueryParams;
};