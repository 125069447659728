import React, { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';
import { useHtmlClassService } from '../../../../_core/MetronicLayout';
import ModalNotice from '../../../../components/alert/ModalNotice';
import ErrorFields from '../../../../components/alert/ErrorFields';

const initialValues = {
  email: '',
};

function ForgotPassword(props) {
  const {
    intl,
    sendEmail,
    success,
    error_fields,
    fetchError,
    fetchSuccess,
  } = props;
  const [loading, setLoading] = useState(false);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const enableLoading = () => {
    setLoading(true);
  };

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Formato de E-mail errado')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        try {
          sendEmail(values.email);
        } catch (error) {
          setSubmitting(false);
        }
      }, 500);
    },
  });

  return (
    <>
      {/** begin::Modal Open Account */}
      <ModalNotice
        show={success && loading ? true : false}
        onHide={() => {
          fetchSuccess(false);
          setLoading(false);
        }}
        title="USER.EMAIL.TITLE.SUCCESS"
        message="MESSAGE.CUSTOM"
        name={success}
      />
      {/** end::Modal Open Account */}

      {/** begin::ModalError */}
      <ErrorFields
        error_fields={error_fields}
        show={!_.isEmpty(error_fields)}
        onHide={() => {
          fetchError('');
          setLoading(false);
        }}
      />
      {/** end::ModalError */}

      <div className="login-form login-forgot" style={{ display: 'block' }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.GENERAL.FORGOT_PASSWORD.TITLE" />
          </h3>
          <div className="text-muted font-weight-bold">
            <FormattedMessage id="AUTH.GENERAL.FORGOT_PASSWORD.SUBTITLE" />
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Digite seu e-mail"
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                'email'
              )}`}
              name="email"
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <div className="col-md-4">
              <ButtonCustom
                layoutProps={layoutProps}
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                size="sm"
                disabled={loading}
              >
                <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </ButtonCustom>
            </div>
            <Link to="/auth">
              <ButtonCustom
                layoutProps={layoutProps}
                id="kt_login_forgot_cancel"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                size="sm"
              >
                <FormattedMessage id="AUTH.GENERAL.CANCEL_SUBMIT" />
              </ButtonCustom>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    ({ auth }) => ({
      success: auth.success,
      error_fields: auth.error_fields,
    }),
    auth.actions
  )(ForgotPassword)
);
