import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../helpers";

export function DateColumnFormatter(cellContent, row) {
    const lineBreak = (date) => {
        let newDate = date.split(' - ');
        return (
            <div className="text-muted text-center d-flex flex-column">
                <span>{newDate[0]}</span>
                <span>
                    <span className="svg-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")} />
                    </span>
                    {newDate[1]}
                </span>
            </div>
        );
    }  
    return (
        lineBreak(row.formatted_date)
    );
}
