import React, { useState, useMemo, useEffect } from 'react';
import { useFormik } from 'formik';
import { useHtmlClassService } from '../../../../components';
import * as fromState from '../_redux/actions';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';
import { connect } from 'react-redux';
import { DocumentFile } from '../../../../components/document/DocumentFile';
import ErrorFields from '../../../../components/alert/ErrorFields';
import ModalNotice from '../../../../components/alert/ModalNotice';

function DocumentInformation(props) {
  const { error_fields } = props;
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const enableLoading = () => {
    setLoading(true);
  };

  useEffect(() => {
    const buttonDisabled =
      props.file_company_document === '' ||
      props.file_proof_of_residence === '' ||
      props.file_document_front === '' ||
      props.file_document_back === '';
    setDisabled(buttonDisabled);
  }, [
    props.file_company_document,
    props.file_document_back,
    props.file_document_front,
    props.file_proof_of_residence,
  ]);

  const initialValues = {
    file_company_document: props.file_company_document,
    file_proof_of_residence: props.file_proof_of_residence,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const value = {
        file_company_document: props.file_company_document,
        file_proof_of_residence: props.file_proof_of_residence,
        file_document_front: props.file_document_front,
        file_document_back: props.file_document_back,
      };
      if (props.documents) {
        props.updateDocuments(value);
      } else {
        props.registerDocuments(value);
      }
    },
  });

  const titles = name => {
    let data = {
      company_document: 'Comprovante de CNPJ',
      proof_of_residence: 'Comprovante de residência',
      document_front: 'RG ou CNH (Frente)',
      document_back: 'RG ou CNH (Verso)',
    };
    return data[name];
  };

  return (
    <>
      {/** begin::ModalError */}
      <ErrorFields
        error_fields={error_fields}
        show={error_fields !== '' ? true : false}
        onHide={() => {
          props.fetchErrors('');
          setLoading(false);
          props.setBoolean(false);
          window.location.reload(true);
        }}
      />
      {/** end::ModalError */}

      {/** begin::Modal Open Account */}
      <ModalNotice
        show={error_fields === '' && props.send ? true : false}
        onHide={() => {
          props.setBoolean(true);
          setLoading(false);
          window.location.reload(true);
        }}
        title="UPDATE.DOCUMENTS.TITLE"
        message="UPDATE.DOCUMENTS.SUCCESS"
      />
      {/** end::Modal Open Account */}
      <div className="login-form login-signin" style={{ display: 'block' }}>
        <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          onSubmit={formik.handleSubmit}
        >
          <div className="row">
            <h5
              style={{
                color: '#000',
                marginBottom: '10px',
              }}
            >
              Atenção: Os arquivos precisam estar na extensão .jpeg
            </h5>
            {(() => {
              if (props.documents && props.documents.results) {
                return props.documents.results.map((v, i) => {
                  return (
                    <DocumentFile
                      title={titles(v.type)}
                      approved={v.approved}
                      icon="/media/svg/icons/Register/Person-Comprovante.svg"
                      onChange={e => {
                        switch (v.type) {
                          case 'company_document':
                            v.approved = 4;
                            props.loadCompanyFile(e.target.files[0]);
                            break;
                          case 'proof_of_residence':
                            v.approved = 4;
                            props.loadProofFile(e.target.files[0]);
                            break;
                          case 'document_front':
                            v.approved = 4;
                            props.loadDocumentFront(e.target.files[0]);
                            break;
                          case 'document_back':
                            v.approved = 4;
                            props.loadDocumentBack(e.target.files[0]);
                            break;

                          default:
                            break;
                        }
                      }}
                    >
                      <p
                        style={{
                          color: v.approved === 1 ? '' : '#F11F52',
                        }}
                      >
                        {v.comment}
                      </p>
                    </DocumentFile>
                  );
                });
              }

              return (
                <>
                  <div className="row ml-1">
                    <DocumentFile
                      title="RG ou CNH"
                      subtitle="(Frente)"
                      icon="/media/svg/icons/Register/Person-Comprovante.svg"
                      approved={props.file_document_front !== '' ? 4 : 3}
                      onChange={e => {
                        props.loadDocumentFront(e.target.files[0]);
                      }}
                    />
                    <div className="modelDocument">
                      <h5 className="mt-2">Modelo CNH Frente</h5>
                      <img
                        width="150"
                        alt="imagem"
                        src="media/images/cnh_frente.jpg"
                      />
                    </div>
                  </div>

                  <div className="row ml-1">
                    <DocumentFile
                      title="RG ou CNH"
                      subtitle="(Verso)"
                      icon="/media/svg/icons/Register/Person-Comprovante.svg"
                      approved={props.file_document_back !== '' ? 4 : 3}
                      onChange={e => {
                        props.loadDocumentBack(e.target.files[0]);
                      }}
                    />
                    <div className="modelDocument">
                      <h5 className="mt-2">Modelo CNH Verso</h5>
                      <img
                        width="150"
                        alt="imagem"
                        src="media/images/cnh_verso.jpg"
                      />
                    </div>
                  </div>

                  <DocumentFile
                    title="Comprovante de endereço"
                    subtitle="até 90 dias"
                    icon="/media/svg/icons/Register/Person-Comprovante.svg"
                    approved={props.file_proof_of_residence !== '' ? 4 : 3}
                    onChange={e => {
                      props.loadProofFile(e.target.files[0]);
                    }}
                  />
                  <DocumentFile
                    title="Comprovante de CNPJ"
                    subtitle="Com CPF"
                    icon="/media/svg/icons/Register/Person-CNPJ.svg"
                    approved={props.file_company_document !== '' ? 4 : 3}
                    onChange={e => {
                      props.loadCompanyFile(e.target.files[0]);
                    }}
                  />
                </>
              );
            })()}
          </div>
          <div className="row mt-30">
            <div className="col-md-3"></div>
            <div className="col-md-5">
              <ButtonCustom
                onClick={() => {}}
                disabled={disabled}
                layoutProps={layoutProps}
                size="sm"
                type="submit"
              >
                Enviar
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </ButtonCustom>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default connect(
  ({ register }) => ({
    file_company_document: register.file_company_document,
    file_proof_of_residence: register.file_proof_of_residence,
    file_document_front: register.file_document_front,
    file_document_back: register.file_document_back,
    error_fields: register.error_fields,
    send: register.send,
  }),
  fromState.actions
)(DocumentInformation);
