import React, { useEffect, useState } from 'react';
import { DepositLoadingDialog } from './loading/DepositLoadingDialog';
import { DepositUIProvider } from './DepositUIContext';
import { Subaside } from '../../../components/subaside/Subaside';
import { Card, CardBody } from '../../../../partials/controls';
import { makeStyles } from '@material-ui/core';

import UserServices from '../../../../services/UserServices';

import { cnpjMask, cpfMask } from '../../../utils/mask_cpf_cnpj.utils';
import { accountMask } from '../../../utils/currency.utils';

import Clipboard from 'clipboard-copy';

const useStyles = makeStyles(theme => ({
  accountBox: {
    marginBottom: '15px',
  },
  box: {
    border: '1px solid #E1E1E8',
    borderRadius: '10px',
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '10px',
  },
  title: {
    color: '#4C4E67',
    fontSize: '14px',
    width: '40%',
  },
  subTitle: {
    color: '#4C4E67',
    fontWeight: 'bold',
    fontSize: '13px',
    width: '50%',
    textAlign: 'right',
  },
  button: {
    width: '300px',
    height: '37px',
    border: '1px solid #4C1BA9',
    borderRadius: '10px',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '45px',
  },
  buttonText: {
    color: '#4C1BA9',
    fontSize: '14px',
  },
}));

export function DepositTedPage({ history }) {
  const [accountInfo, setAccountInfo] = useState([]);

  const depositUIEvents = {
    openFetchCustomersDialog: () => {
      history.push(`/conta/deposito/boleto/fetch`);
    },
  };

  const loadInfo = async () => {
    let info = await UserServices.getAccountProvider();
    setAccountInfo(info.data.results);
  };

  const copyData = accountInfo => {
    const document =
      accountInfo.document.length > 11
        ? cnpjMask(accountInfo.document)
        : cpfMask(accountInfo.document);
    let string = `Banco: ${accountInfo.bank}\n`;
    string += `Favorecido: ${accountInfo.receiver_name}\n`;
    string += `CPF/CNPJ: ${document}\n`;
    string += `Agência sem dígito: ${accountInfo.branch.slice(0, 4)}\n`;
    string += `Conta com dígito: ${accountMask(accountInfo.number)}\n`;

    Clipboard(string);

    setTimeout(() => {
      alert('Dados copiados!');
    }, 500);
  };

  useEffect(() => {
    loadInfo();
  }, []);

  const classes = useStyles();

  return (
    <div className="row">
      <Subaside />
      <DepositUIProvider depositUIEvents={depositUIEvents}>
        <DepositLoadingDialog />
        <div className="col-md-8 col-xs-12 col-lg-8">
          <Card className="col-xs-12 col-xl-12 col-md-12">
            <CardBody>
              <h4>Depósito via TED</h4>

              {accountInfo && accountInfo.length === 0 && <p>Sem dados</p>}

              {accountInfo &&
                accountInfo.map((acc, index) => {
                  return (
                    <div key={index} className={classes.accountBox}>
                      <div className={classes.box}>
                        <div className={classes.row}>
                          <span className={classes.title}>Banco</span>
                          <span className={classes.subTitle}>{acc.bank}</span>
                        </div>
                        <div className={classes.row}>
                          <span className={classes.title}>Favorecido</span>
                          <span className={classes.subTitle}>
                            {acc.receiver_name}
                          </span>
                        </div>
                        <div className={classes.row}>
                          <span className={classes.title}>CPF/CNPJ</span>
                          <span className={classes.subTitle}>
                            {acc.document.length > 11
                              ? cnpjMask(acc.document)
                              : cpfMask(acc.document)}
                          </span>
                        </div>
                        <div className={classes.row}>
                          <span className={classes.title}>
                            Agência sem dígito
                          </span>
                          <span className={classes.subTitle}>
                            {acc.branch.slice(0, 4)}
                          </span>
                        </div>
                        <div className={classes.row}>
                          <span className={classes.title}>
                            Conta com dígito
                          </span>
                          <span className={classes.subTitle}>
                            {accountMask(acc.number)}
                          </span>
                        </div>
                      </div>

                      <div
                        className={classes.button}
                        onClick={() => copyData(acc)}
                      >
                        <span className={classes.buttonText}>Copiar Dados</span>
                      </div>
                    </div>
                  );
                })}
            </CardBody>
          </Card>
        </div>
      </DepositUIProvider>
    </div>
  );
}
