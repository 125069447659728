/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import * as auth from '../_redux/authRedux';
import UserServices from '../../../../../services/UserServices';
import * as fromPhysical from '../../Register/PhysicalPerson/_redux/actions';
import * as fromLegal from '../../Register/LegalPerson/_redux/actions';

import { useHtmlClassService } from '../../../../_core/MetronicLayout';
import { ButtonCustom } from '../../../../components/button/ButtonCustom';
import { Link } from 'react-router-dom';
import { clearStorage } from '../../../../../helpers';
import { bindActionCreators } from 'redux';

const initialValues = {
  email: '',
  password: '',
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    password: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      UserServices.login(values.email, values.password)
        .then(({ data: { access_token } }) => {
          disableLoading();
          props.actions.login(access_token);
        })
        .catch(() => {
          disableLoading();
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: 'AUTH.VALIDATION.INVALID_LOGIN',
            })
          );
        });
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="mb-5 mb-lg-10">
        <h3
          className="font-size-h1"
          style={{ color: `${layoutProps.colors.primary}` }}
        >
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.LOGIN.SUBTITLE" />
        </p>
        <a
          className="font-weight-bold"
          style={{ color: `${layoutProps.colors.secondary}` }}
        >
          <FormattedMessage id="AUTH.GENERAL.NO_ACCOUNT" />
        </a>
        <a
          onClick={() => {
            clearStorage();
            props.actions.clearPhysicalPerson();
            props.actions.clearLegalPerson();
            props.history.push('/cadastro');
          }}
          className="ml-1 font-weight-bold"
          style={{ color: `${layoutProps.colors.primary}` }}
        >
          <FormattedMessage id="AUTH.GENERAL.OPEN_ACCOUNT" />
        </a>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div></div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="E-mail"
            type="email"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              'email'
            )}`}
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Senha"
            type="password"
            className={`form-control h-auto py-5 px-6 ${getInputClasses(
              'password'
            )}`}
            name="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
          <div className="text-right">
            <Link
              to="/alterar/senha"
              className="font-weight-bold"
              style={{ color: `${layoutProps.colors.primary}` }}
            >
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
          </div>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <div className="col-md-5">
            <ButtonCustom
              layoutProps={layoutProps}
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className="btn font-weight-bold px-9 py-4 my-3"
              size="sm"
            >
              <FormattedMessage id="AUTH.LOGIN.BUTTON" />
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </ButtonCustom>
          </div>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(
  connect(
    ({ auth }) => ({ auth }),
    dispatch => {
      return {
        actions: bindActionCreators(
          Object.assign(
            {},
            auth.actions,
            fromPhysical.actions,
            fromLegal.actions
          ),
          dispatch
        ),
      };
    }
  )(Login)
);
