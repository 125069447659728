import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import UserServices from '../../../../../services/UserServices';
import { HTTPCodes } from '../../../../../services/enums/HTTPCodes';
import { removeStorage } from '../../../../../helpers/LocalStorageHelpers';
import { actions as fromRegister } from '../../Register/_redux/actions';

export const actionTypes = {
  Login: '[Login] Action',
  LoginForRegistration: '[Login] After Register',
  Logout: '[Logout] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] User Loaded',
  UserAddressRequested: '[Request Address] Action',
  UserAddressLoaded: '[Load Address] Address Loaded',
  UpdateEmail: '[Update Email] Email',
  UpdateAddress: '[Update Address] Address',
  ErrorLoaded: '[Error] Loaded',
  SendEmail: '[Password] Send Email',
  SuccessLoaded: '[Success] Loaded',
  UserPhoneLoaded: '[Load Phone] Phone Loaded',
};

const initialAuthState = {
  user: {
    person: {},
  },
  access_token: undefined,
  totalCount: 0,
  address: [],
  account: [],
  phone: {},
  financial: {},
  device: {},
  error_fields: '',
  success: '',
  success_email: '',
  limits: {},
};

export const reducer = persistReducer(
  { storage, key: 'v706-demo1-auth', whitelist: ['access_token'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { access_token } = action.payload;

        return { ...state, access_token };
      }

      case actionTypes.LoginForRegistration: {
        const { access_token } = action.payload;

        return { ...state, access_token };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { login } = action.payload;
        return {
          ...state,
          user: login.user,
          account: login.account,
          phone: login.phone,
          financial: login.financialSecurity,
          device: login.device,
          error_fields: '',
          success_email: true,
          limits: login.limits,
        };
      }

      case actionTypes.UserAddressLoaded: {
        const { address } = action.payload.address;
        return { ...state, address, error_fields: '' };
      }

      case actionTypes.UserPhoneLoaded: {
        const phone = action.payload.phone;
        phone.sent = true;
        return { ...state, phone, error_fields: '' };
      }

      case actionTypes.ErrorLoaded: {
        const { error } = action.payload;
        return { ...state, error_fields: error };
      }

      case actionTypes.SuccessLoaded: {
        const { success } = action.payload;
        return { ...state, success: success };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: access_token => ({
    type: actionTypes.Login,
    payload: { access_token },
  }),
  loginForRegister: access_token => ({
    type: actionTypes.LoginForRegistration,
    payload: { access_token },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fetchUser: login => ({ type: actionTypes.UserLoaded, payload: { login } }),
  fetchUserAddress: address => ({
    type: actionTypes.UserAddressLoaded,
    payload: { address },
  }),
  fetchUserAccount: account => ({
    type: actionTypes.UserAccountLoaded,
    payload: { account },
  }),
  updateEmail: user => ({ type: actionTypes.UpdateEmail, payload: { user } }),
  updateAddress: address => ({
    type: actionTypes.UpdateAddress,
    payload: { address },
  }),
  fetchUserPhone: phone => ({
    type: actionTypes.UserPhoneLoaded,
    payload: { phone },
  }),
  fetchError: error => ({
    type: actionTypes.ErrorLoaded,
    payload: { error },
  }),
  sendEmail: email => ({ type: actionTypes.SendEmail, payload: { email } }),
  fetchSuccess: success => ({
    type: actionTypes.SuccessLoaded,
    payload: { success },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
    yield removeStorage('persist:register-docs');
  });

  yield takeLatest(
    actionTypes.LoginForRegistration,
    function* loginRegisterSaga() {
      yield put(actions.requestUser());
      yield put(fromRegister.loadDocuments({}));
    }
  );

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    try {
      const { data: login } = yield UserServices.getAccount();
      yield put(actions.fetchUser(login));

      const { data: address } = yield UserServices.getAddress();
      yield put(actions.fetchUserAddress(address));
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.UpdateAddress, function* updateAddress(action) {
    try {
      const { data: address } = yield UserServices.changeAddress(
        action.payload
      );
      yield put(actions.fetchUserAddress(address));
    } catch (err) {
      if (err.response.status === HTTPCodes.UNKNOWN_SERVER_ERROR) {
        yield put(
          actions.fetchError([
            { idTranslate: 'MODAL.ERROR.UNAVAILABLE_SERVICE' },
          ])
        );
      } else if (err.response.status === HTTPCodes.TOO_MANY_REQUESTS) {
        yield put(
          actions.fetchError([
            {
              idTranslate: 'MODAL.ERROR.TOO_MANY_REQUESTS',
            },
          ])
        );
      } else {
        const {
          data: { field_errors },
        } = err.response;
        yield put(actions.fetchError(field_errors.address));
      }
    }
  });

  yield takeLatest(actionTypes.UpdateEmail, function* updateEmail(action) {
    try {
      const { data: user } = yield UserServices.changeEmail(action.payload);
      yield put(actions.fetchUser(user));
    } catch (err) {
      if (err.response.status === HTTPCodes.UNKNOWN_SERVER_ERROR) {
        yield put(
          actions.fetchError([
            { idTranslate: 'MODAL.ERROR.UNAVAILABLE_SERVICE' },
          ])
        );
      } else if (err.response.status === HTTPCodes.TOO_MANY_REQUESTS) {
        yield put(
          actions.fetchError([
            {
              idTranslate: 'MODAL.ERROR.TOO_MANY_REQUESTS',
            },
          ])
        );
      } else {
        const {
          data: { field_errors },
        } = err.response;
        yield put(actions.fetchError(field_errors.email));
      }
    }
  });

  yield takeLatest(actionTypes.SendEmail, function* resetPassword(action) {
    try {
      const { data } = yield UserServices.sendEmail(action.payload);
      yield put(actions.fetchSuccess(data.message));
    } catch (err) {
      if (err.response.status === HTTPCodes.UNKNOWN_SERVER_ERROR) {
        yield put(
          actions.fetchError([
            { idTranslate: 'MODAL.ERROR.UNAVAILABLE_SERVICE' },
          ])
        );
      } else if (err.response.status === HTTPCodes.TOO_MANY_REQUESTS) {
        yield put(
          actions.fetchError([
            {
              idTranslate: 'MODAL.ERROR.TOO_MANY_REQUESTS',
            },
          ])
        );
      } else {
        const {
          data: { field_errors },
        } = err.response;
        yield put(actions.fetchError(field_errors.email));
      }
    }
  });

}
