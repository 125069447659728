import React, { useState } from 'react';

import {
    Card,
    CardHeader,
    CardBody
} from '../../../../../partials/controls';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { injectIntl } from "react-intl";
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as auth from "../../../../pages/User/Auth/_redux/authRedux";
import ErrorFields from '../../../../components/alert/ErrorFields';
import ModalNotice from '../../../../components/alert/ModalNotice';

function EmailForm(props) {
    const { intl, updateEmail, user, error_fields, fetchError, success_email } = props;
    const [loading, setLoading] = useState(false);

    // Schema de validação dos campos
    const Schema = Yup.object().shape({
        email: Yup.string()
            .min(1, "Minimum 3 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            )
    });
    // - - -

    const enableLoading = () => {
        setLoading(true);
    };

    const desabledLoading = () => {
        setLoading(false);
    };

    // Inicializando e validando formulário
    const formik = useFormik({
        initialValues: {
            email: user.email,
            email_verify: user.email
        },
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setTimeout(() => {
                try {
                    updateEmail(values)
                } catch (error) {
                    setSubmitting(false);
                }
            }, 1000)
        },
    });
    // - - - 

    return (<>
        {/** begin::ModalError */}
        <ErrorFields
            error_fields={error_fields}
            show={(error_fields !== '') ? (true) : (false)}
            onHide={() => {
                desabledLoading()
                fetchError('')
            }}
        />
        {/** end::ModalError */}

        {/** begin::Modal Open Account */}
        <ModalNotice
            show={(success_email && loading) ? (true) : (false)}
            onHide={() => {
                setLoading(false)
            }}
            title="USER.EMAIL.TITLE.SUCCESS"
            message="USER.EMAIL.MESSAGE.SUCCESS"
        />
        {/** end::Modal Open Account */}

        <Card className="col-xs-12 col-xl-12 col-md-12">
            <CardHeader
                title="Alterar meu email"
            />
            <CardBody>
                <form
                    onSubmit={formik.handleSubmit}>

                    <div className="row">
                        {/** begin::Valor*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="email">Novo email:</label>
                                <input
                                    type="text"
                                    className="form-control h-auto py-4 px-6"
                                    name="email"
                                    {...formik.getFieldProps("email")}
                                />
                            </div>
                        </div>
                        {/** end::Valor*/}

                        {/** begin::Valor*/}
                        <div className="col-md-6 col-lg-6 col-xs-12">
                            <div className="form-group fv-plugins-icon-container">
                                <label htmlFor="email_verify">Confirme o seu novo email:</label>
                                <input
                                    placeholder="R$ 0,00"
                                    type="text"
                                    className="form-control h-auto py-4 px-6"
                                    name="email_verify"
                                    {...formik.getFieldProps("email_verify")}
                                />
                            </div>
                        </div>
                        {/** end::Valor*/}
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-md-offset-2">
                            <Button
                                style={{
                                    backgroundColor: `${props.layoutProps.colors.primary}`
                                }}
                                type="submit"
                                size="lg"
                                block
                            >
                                Alterar meu email
                                {loading && <span className="ml-3 spinner spinner-white"></span>}
                            </Button>
                        </div>
                    </div>

                </form>
            </CardBody>
        </Card>
    </>);
}

export default injectIntl(connect(
    ({ auth }) => ({ 
        user: auth.user, 
        error_fields: auth.error_fields,
        success_email: auth.success_email
    }),
auth.actions)(EmailForm));
