/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useHistory, Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../helpers';
import { connect } from 'react-redux';
import * as auth from '../../../pages/User/Auth/_redux/authRedux';
import { FormattedMessage } from 'react-intl';
import { QRCode } from 'react-qr-svg';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import ModalNotice from '../../alert/ModalNotice';
import MessageAlert from '../../alert/MessageAlert';
import { ButtonCustom } from '../../button/ButtonCustom';
import { useIdleTimer } from 'react-idle-timer';
import { cpfMask, cnpjMask } from '../../../utils/mask_cpf_cnpj.utils';
import { accountMask } from '../../../utils/currency.utils';
import names from '../../../utils/name.utils';
import { timeLogout } from '../../../utils/utils';
import Clipboard from 'clipboard-copy';
import {
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';

function QuickUser(props) {
  const history = useHistory();
  const { user, account, success, sendEmail, fetchSuccess } = props;
  let [loadPassword, setLoading] = useState(false);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const logoutClick = () => {
    const toggle = document.getElementById('kt_quick_user_toggle');
    if (toggle) {
      toggle.click();
    }
    history.push('/logout');
  };

  const handleOnIdle = event => {
    logoutClick();
  };

  useIdleTimer({
    timeout: 1000 * 60 * parseInt(timeLogout[window.location.host]),
    onIdle: handleOnIdle,
    onActive: () => {},
    onAction: () => {},
    debounce: 500,
  });

  const montarString = () => {
    let string = `Olá, esses são os meus dados ${
      names[window.location.host]
    } para transferências internas P2P.\n`;
    string += `Nome: ${user.name}\n`;
    string += `Conta: ${accountMask(account.number)}\n`;

    if (user.person.cpf) string += `CPF: ${cpfMask(user.person.cpf)}\n`;
    if (user.person.cnpj) string += `CNPJ: ${cnpjMask(user.person.cnpj)}\n`;

    return string;
  };

  const copyData = async () => {
    let string = await montarString();

    Clipboard(string);

    setTimeout(() => {
      alert('Dados copiados para a área de transferência!');
    }, 500);
  };

  return (
    <>
      {/** begin::Modal Success */}
      <MessageAlert
        show={success ? true : false}
        onHide={() => {
          fetchSuccess('');
        }}
        message={success}
      />
      {/** end::Modal Success */}
      <div
        id="kt_quick_user"
        className="offcanvas offcanvas-right offcanvas p-10"
      >
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            <FormattedMessage id="USER.PROFILE.TITLE" />
          </h3>
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-light"
            id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted" />
          </a>
        </div>

        <div className="offcanvas-content pr-5 mr-n5">
          <div className="d-flex align-items-center mt-5">
            <div className="symbol symbol-100 mr-5">
              <div className="symbol-label">
                <span className="svg-icon-lg svg-icon-primary">
                  <QRCode
                    level="Q"
                    style={{ width: 65 }}
                    value={JSON.stringify({
                      owner: user.name,
                      account: account.number,
                    })}
                  />
                </span>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  width: '100px',
                  justifyContent: 'space-around',
                  marginTop: '5px',
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    width: '24px',
                  }}
                  onClick={copyData}
                >
                  <span
                    style={{
                      color: '#181818',
                      fontSize: '12px',
                    }}
                  >
                    <SVG
                      src={toAbsoluteUrl('/media/svg/icons/New/icon-copy.svg')}
                    ></SVG>
                  </span>
                </div>

                {account.number && (
                  <WhatsappShareButton
                    url={`Olá, esses são os meus dados ${
                      names[window.location.host]
                    } para transferências internas P2P.\nNome: ${
                      user.name
                    }\nConta: ${accountMask(account.number)}\n${
                      user.person.cpf ? 'CPF: ' + cpfMask(user.person.cpf) : ''
                    }${
                      user.person.cnpj
                        ? 'CNPJ: ' + cnpjMask(user.person.cnpj)
                        : ''
                    }`}
                    title=""
                  >
                    <WhatsappIcon type="button" size={28} round={false} />
                  </WhatsappShareButton>
                )}

                {account.number && (
                  <TelegramShareButton
                    url={`Olá, esses são os meus dados ${
                      names[window.location.host]
                    } para transferências internas P2P.\nNome: ${
                      user.name
                    }\nConta: ${accountMask(account.number)}\n${
                      user.person.cpf ? 'CPF: ' + cpfMask(user.person.cpf) : ''
                    }${
                      user.person.cnpj
                        ? 'CNPJ: ' + cnpjMask(user.person.cnpj)
                        : ''
                    }`}
                    title=""
                  >
                    <TelegramIcon type="button" size={28} round={false} />
                  </TelegramShareButton>
                )}
              </div>
            </div>
            <div className="d-flex flex-column">
              <a
                href="#"
                className="font-weight-bold font-size-h5 text-dark-75 text-hover-dark"
              >
                {user.name}
              </a>
              <div className="text-muted mt-1">
                {(user.person.cnpj && cnpjMask(user.person.cnpj)) ||
                  (user.person.cpf && cpfMask(user.person.cpf))}
              </div>
              <div className="navi mt-2">
                <a href="#" className="navi-item">
                  <span className="navi-link p-0 pb-2">
                    <span className="navi-icon mr-1">
                      <span className="svg-icon-lg svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            '/media/svg/icons/Communication/Mail-notification.svg'
                          )}
                        ></SVG>
                      </span>
                    </span>
                    <span className="navi-text text-muted text-hover-dark">
                      {user.email}
                    </span>
                  </span>
                </a>
              </div>

              <ButtonCustom
                layoutProps={layoutProps}
                onClick={logoutClick}
                size="sm"
              >
                <FormattedMessage id="USER.PROFILE.LOGOUT" />
              </ButtonCustom>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5" />

          <div className="navi navi-spacer-x-0 p-0">
            <Link to="/conta/meus-dados" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-dark">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/General/Notification2.svg'
                        )}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="USER.PROFILE.MY_DATA" />
                  </div>
                  <div className="text-muted">
                    <FormattedMessage id="USER.PROFILE.MANAGE_DATA" />
                  </div>
                </div>
              </div>
            </Link>

            {/** begin::Modal Change Password */}
            <ModalNotice
              show={loadPassword}
              onHide={() => {
                setLoading(false);
              }}
              onSuccess={() => {
                sendEmail(user.email);
                setLoading(false);
              }}
              title="USER.PROFILE.CHANGE_PASSWORD"
              message="USER.PROFILE.CHANGE_PASSWORD.MESSAGE"
              name={user.email}
              success="true"
              layoutProps={layoutProps}
            />
            {/** end::Modal Change Password */}

            <Link to="#" className="navi-item" onClick={() => setLoading(true)}>
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-dark">
                      <SVG
                        src={toAbsoluteUrl('/media/svg/icons/Home/Key.svg')}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="USER.PROFILE.PASSWORD_ACCESS" />
                  </div>
                  <div className="text-muted">
                    <FormattedMessage id="USER.PROFILE.CHANGE_PASSWORD" />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  ({ auth }) => ({
    user: auth.user,
    success: auth.success,
    account: auth.account,
  }),
  auth.actions
)(QuickUser);
