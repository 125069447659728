import React, { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { ButtonCustom } from '../../../../../components/button/ButtonCustom';
import { useHtmlClassService } from '../../../../../components';
import * as fromState from './_redux/actions';

function ZipCodeVerification(props) {
  const { verifyZipCode, zipcode, registerZipCode } = props;
  const history = useHistory();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  props.setPercent(60);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colors: uiService.getColors(),
    };
  }, [uiService]);

  const nextStep = () => {
    history.push('/cadastro/bancos');
  };

  const RegistrationSchema = Yup.object().shape({
    zip_code: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    city: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    state: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    street_name: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    number: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    district: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  let initialValues = {
    zip_code: zipcode.zipCode,
    city: zipcode.city,
    state: zipcode.state,
    country: zipcode.country,
    street_name: zipcode.street,
    number: zipcode.number,
    additional_notes: zipcode.additional_notes,
    district: zipcode.district,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      registerZipCode(values);
      nextStep();
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated 
				animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: CEP */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">
            CEP
            {loading && <span className="ml-3 spinner spinner-black"></span>}
          </label>
          <input
            type="text"
            onBlurCapture={e => {
              setLoading(true);
              verifyZipCode(e.target.value);
              setTimeout(() => {
                setLoading(false);
              }, 2000);
            }}
            className={`form-control h-auto py-5 px-6 
						${getInputClasses('zip_code')}`}
            name="zip_code"
            {...formik.getFieldProps('zip_code')}
            onChange={e => {
              formik.setFieldValue('zip_code', e.target.value);
            }}
          />

          {formik.touched.zip_code && formik.errors.zip_code ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.zip_code}</div>
            </div>
          ) : null}
        </div>
        {/* end: CEP */}

        {/* begin: Rua */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">Rua</label>
          <input
            type="text"
            className={`form-control h-auto py-5 px-6 
                        ${getInputClasses('street_name')}`}
            name="street_name"
            {...formik.getFieldProps('street_name')}
          />

          {formik.touched.street_name && formik.errors.street_name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.street_name}</div>
            </div>
          ) : null}
        </div>
        {/* end: Rua */}

        {/* begin: Numero */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">Número</label>
          <input
            type="text"
            className={`form-control h-auto py-5 px-6 
                        ${getInputClasses('number')}`}
            name="number"
            {...formik.getFieldProps('number')}
          />

          {formik.touched.number && formik.errors.number ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.number}</div>
            </div>
          ) : null}
        </div>
        {/* end: Numero */}

        {/* begin: Complemento */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">Complemento</label>
          <input
            type="text"
            className={`form-control h-auto py-5 px-6 
                        ${getInputClasses('additional_notes')}`}
            name="additional_notes"
            {...formik.getFieldProps('additional_notes')}
          />
        </div>
        {/* end: Complemento */}

        {/* begin: Bairro */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">Bairro</label>
          <input
            type="text"
            className={`form-control h-auto py-5 px-6 
                        ${getInputClasses('district')}`}
            name="district"
            {...formik.getFieldProps('district')}
          />

          {formik.touched.district && formik.errors.district ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.district}</div>
            </div>
          ) : null}
        </div>
        {/* end: Bairro */}

        {/* begin: Cidade */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">Cidade</label>
          <input
            type="text"
            className={`form-control h-auto py-5 px-6 
                        ${getInputClasses('city')}`}
            name="city"
            {...formik.getFieldProps('city')}
          />

          {formik.touched.city && formik.errors.city ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.city}</div>
            </div>
          ) : null}
        </div>
        {/* end: Cidade */}

        {/* begin: Estado */}
        <div className="form-group fv-plugins-icon-container">
          <label className="text-muted">Estado</label>
          <select
            name="state"
            className="form-control h-auto py-5 px-6"
            {...formik.getFieldProps('state')}
          >
            <option value="">Selecione</option>
            <option value="AC">Acre</option>
            <option value="AL">Alagoas</option>
            <option value="AP">Amapá</option>
            <option value="AM">Amazonas</option>
            <option value="BA">Bahia</option>
            <option value="CE">Ceará</option>
            <option value="DF">Distrito Federal</option>
            <option value="ES">Espírito Santo</option>
            <option value="GO">Goiás</option>
            <option value="MA">Maranhão</option>
            <option value="MT">Mato Grosso</option>
            <option value="MS">Mato Grosso do Sul</option>
            <option value="MG">Minas Gerais</option>
            <option value="PA">Pará</option>
            <option value="PB">Paraíba</option>
            <option value="PR">Paraná</option>
            <option value="PE">Pernambuco</option>
            <option value="PI">Piauí</option>
            <option value="RJ">Rio de Janeiro</option>
            <option value="RN">Rio Grande do Norte</option>
            <option value="RS">Rio Grande do Sul</option>
            <option value="RO">Rondônia</option>
            <option value="RR">Roraima</option>
            <option value="SC">Santa Catarina</option>
            <option value="SP">São Paulo</option>
            <option value="SE">Sergipe</option>
            <option value="TO">Tocantins</option>
          </select>

          {formik.touched.state && formik.errors.state ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.state}</div>
            </div>
          ) : null}
        </div>
        {/* end: Estado */}

        <div className="form-group d-flex flex-wrap flex-center">
          <div className="col-md-10">
            <ButtonCustom
              layoutProps={layoutProps}
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-6 py-4 my-3 mx-4"
              size="sm"
            >
              <FormattedMessage id="AUTH.REGISTER.NEXT" />
            </ButtonCustom>
          </div>
        </div>
      </form>
    </div>
  );
}

export default connect(
  ({ zipcode }) => ({
    zipcode: zipcode.zipcode,
  }),
  fromState.actions
)(ZipCodeVerification);
