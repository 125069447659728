import * as requestFromServer from './crud';
import { put, takeLatest } from "redux-saga/effects";
import { initialState, types, actions } from "./actions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from 'redux-persist';

export const reducer = persistReducer(
    { 
        storage, 
        key: "register-zipcode", 
        whitelist: ["zipcode"] 
    },
    (state = initialState, action) => {
    switch (action.type) {

        case types.ZipCodeVerifyLoaded: {
            const { verify } = action.payload;
            return { ...state, zipcode: verify };
        }

        case types.ClearZipCode: {
            return { zipcode: {} };
        }

        default:
            return state;
    }
})

export function* saga() {
    yield takeLatest(types.ZipCodeRegister, function* registerZipCode(action) {
        let { register } = action.payload;

        register = {
            zipCode: register.zip_code,
            city:  register.city,
            state: register.state,
            country: register.country,
            street: register.street_name,
            number: register.number,
            district: register.district,
            additional_notes: register.additional_notes
        }

        yield put(actions.loadZipCode(register));
    });

    yield takeLatest(types.ZipCodeVerify, function* verifyZipCode(action) {
        const { verify } = action.payload;
        try {
            let { data } = yield requestFromServer.verifyZipCode({ zipcode: verify});
            yield put(actions.loadZipCode(data));
        } catch (error) {
            
        }
    });
}