import { ENDPOINT } from "../../../../../services/enums/EndPoint";
import ApiServices from "../../../../../services/ApiServices";

const api = ApiServices;

/**
 * Retorna o extrato da movimentação de conta
 * por paginação
 * 
 * @param {number} page 
 */
export function getAllExtract(param) {
  return api.get(ENDPOINT.STATEMENT, {
    params: {
      page: param.pageNumber,
      transaction_type: param.filter.transaction_type,
      type: param.filter.type,
      date_from: param.filter.date_from,
      date_to: param.filter.date_to
    }
  });
}
