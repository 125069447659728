import React, { Suspense } from "react";
import { LayoutSplashScreen, ContentRoute } from "../../components";

import { BillPage } from "./Bill";
import { ConsumerPage } from "./Consumer";
import { Subaside } from "../../components/subaside/Subaside";
import AutomaticDebitPage from "./AutomaticDebit/AutomaticDebitPage";
import PaymentBillPage from "./PaymentBillPage";

export default function PaymentPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
        <div className="row"> 
          <Subaside />
          <ContentRoute exact path="/pagamento/automatico" component={AutomaticDebitPage} />
          <ContentRoute exact path="/pagamento/boleto" component={BillPage} />
          <ContentRoute exact path="/pagamento/boleto/:id" component={PaymentBillPage} />
          <ContentRoute exact path="/pagamento/consumo" component={ConsumerPage} />
        </div>
    </Suspense>
  );
}
