import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

export function ButtonCustom(props) {
  const [hover, setHover] = useState(false);

  const style = {
    normal: {
      background: props.secondary
        ? props.layoutProps.colors.secondary
        : props.layoutProps.colors.primary,
    },
    hover: {
      background: props.secondary
        ? props.layoutProps.colors.primary
        : props.layoutProps.colors.secondary,
    },
  };

  return (
    <Button
      disabled={props.disabled}
      id={props.id}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        ...style.normal,
        ...(hover ? style.hover : null),
      }}
      onClick={props.onClick}
      type={props.type !== '' && props.type ? props.type : 'submit'}
      size={props.size ? props.size : 'lg'}
      block
      className={props.className}
    >
      {props.children}
    </Button>
  );
}
