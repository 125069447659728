export const appStoreURI = () => {
  let uris = URIS[window.location.host];
  return uris.ios;
};

export const playStoreURI = () => {
  let uris = URIS[window.location.host];
  return uris.android;
};

export const URIS = Object.freeze({
  'localhost:3000': {
    ios: '',
    android: '',
  },
  'jump.rm4.com.br': {
    ios: '',
    android: '',
  },
});
