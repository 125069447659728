import React from "react";
import { Modal } from "react-bootstrap";

export default function MessageAlert(props) {
  const { show, onHide, message, title, children } = props;

  return (
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <p className="text-align-center">
                {title}
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="timeline timeline-5 mt-3">
              {message}
            </div>
            {children}
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-default btn-elevate"
              >
                Fechar
              </button>
            </div>
          </Modal.Footer>
        </Modal>
  );
}
