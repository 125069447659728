import React, { useMemo } from 'react'
import { toAbsoluteUrl } from '../../../../helpers';
import SVG from "react-inlinesvg";
import { ButtonCustom } from '../../../components/button/ButtonCustom'
import { currency } from '../../../utils/currency.utils';
import { dateFormatted, dateStringFormatted, now } from '../../../utils/date.utils';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { statusColor } from './AutomaticUIHelpers';

export function AutomaticDebit(props) {
	const { debits, authorize } = props;

	const uiService = useHtmlClassService();

	const layoutProps = useMemo(() => {
		return {
			colors: uiService.getColors()
		};
	}, [uiService]);

	return (
		<div className="col-xs-12 col-xl-6 col-md-6 border m-5">

			<div className="row p-4">
				<h4>{debits.description}</h4>
			</div>

			<div className="row">
				<div className="col-md-6">
					<p>Data: </p>
				</div>
				<div className="col-md-6 text-right">
					<span>{dateFormatted(debits.dates.results[0].date)}</span>
				</div>
			</div>

			<div className="row border-bottom">
				<div className="col-md-6">
					<p>Total: </p>
				</div>
				<div className="col-md-6 text-right">
					<p>{currency(debits.total_value)} <br/>
					<span className="text-muted">em {debits.total_dates}x</span></p>
				</div>
			</div>

			{debits.dates.results ? (
				debits.dates.results.map((v, i) => {
					return (
						<div
							key={i} 
							className="row pt-2"
							style={{ color: (v.date === now()) ? 
								statusColor['current_date'] : 
								statusColor[v.status] 
							}}
						>
							<div className="col-md-6">
								<p>{i+1}ª&nbsp;&nbsp;{dateStringFormatted(v.date)}</p>
							</div>
							<div className="col-md-6 text-right">
								<span>{currency(debits.value)}</span>
							</div>
						</div>)
				})
			) : ('')}

			
				{(debits.status === 'liquidated' || 
					debits.status === 'authorized') ? (
					<div className="row text-center" style={{ backgroundColor: statusColor['payment_authorized']}}>
						<p className="ml-10 mt-5 text-center">
							<SVG 
								src={toAbsoluteUrl("/media/svg/icons/Register/Register-Approved.svg")}
							>
							</SVG> 
						<span className="ml-2">Pagamento autorizado</span>
						</p>
					</div>
					) : (
						<div className="row text-center">
							<ButtonCustom
								layoutProps={layoutProps}
								size="lg"
								onClick={() => {
									authorize(debits.id)
								}}
							>
								Autorizar pagamento
							</ButtonCustom>
						</div>
					)
				}
		</div>
	)
} 
