import React, { useMemo } from "react";
import { DepositLoadingDialog } from "./loading/DepositLoadingDialog";
import { DepositUIProvider } from "./DepositUIContext";
import { DepositCard } from "./DepositCard";
import DepositForm from "./form/DepositForm";
import { Subaside } from "../../../components/subaside/Subaside";
import { useHtmlClassService } from "../../../_core/MetronicLayout";

export function DepositPage({ history }) {
  const depositUIEvents = {
    openFetchCustomersDialog: () => {
      history.push(`/conta/deposito/boleto/fetch`);
    }
  }

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
      return {
          colors: uiService.getColors()
      };
  }, [uiService]);

  return (
    <div className="row"> 
      <Subaside />
      <DepositUIProvider depositUIEvents={depositUIEvents}>
        <DepositLoadingDialog />
        <div className="col-md-8 col-xs-12 col-lg-8">
          <DepositForm layoutProps={layoutProps} />
          <DepositCard />
        </div>
      </DepositUIProvider>
    </div>
  );
}
