import React, { useState } from "react";
import { Card } from "../../../partials/controls";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../helpers";

export const Document = (props) => {
    const [hover, setHover] = useState(false);
    
    const style = {
        normal: {
            border: '1px solid #ededed',
            backgroundColor: (props.backgroundColor) ? (props.backgroundColor) : ('#fff')
        },
        selected: {
            border: '1px solid #ededed',
            backgroundColor: '#61d39a'
        }
    };

    return (
        <Card className="col-xs-5 col-xl-5 col-md-5 mr-5 customRule"
            onClick={() => {
                setHover((hover) ? (false) : (true));
            }}
            style={{
                ...style.normal,
                ...(props.selected ? style.selected : null),
            }}
        >
            <div className="card-header border-0 pt-5">
                <h3 className="card-title font-weight-bolder">
                    <SVG 
                        src={toAbsoluteUrl(props.icon)}
                    >
                    </SVG> 
                </h3>                
            </div>
            <div>
                <h5 className="ml-10 mt-2">
                    {props.title}
                </h5>
            </div>
            <div>
                <p className="ml-10 text-muted font-weight-bold">
                    {(props.selected) ? ('') : (props.subtitle)}
                </p>
            </div>
            <div className="card-body border-0 pt-5">
                {props.children}
                {(props.selected) ? (
                    <p className="mt-10">
                        <SVG 
                            src={toAbsoluteUrl("/media/svg/icons/Register/Register-Approved.svg")}
                        >
                        </SVG> 
                        &nbsp;Imagens selecionadas
                    </p>
                ) : ('')}
            </div>
        </Card>
    );
}