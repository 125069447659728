import axios from 'axios';
import { DOMAINS } from './domains';
// import { useHistory } from "react-router-dom";

let jwt = require('jsonwebtoken');
let client = axios;

/**
 * Inicializa as configurações do Header.
 * Setando o host do cliente, com base na url,
 * e interceptando o token.
 * @param store
 * @return {void}
 */
const setup = store => {
  // Seta o domínio de acordo com o host
  // let domain = 'https://talpagamentos.bancoarbi.com.br/api';
  let domain = 'https://contajump.hml.bancoarbi.com.br/api';
  client.defaults.baseURL = domain;

  client.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response.status === 401) {
        localStorage.clear();
      }

      return Promise.reject(error);
    }
  );

  client.interceptors.request.use(
    config => {
      const {
        auth: { access_token },
      } = store.getState();

      if (access_token) {
        config.headers.Authorization = `Bearer ${access_token}`;
        if (_isTokenExpired(access_token)) {
          store.dispatch({ type: '[Logout] Action' });
        }
      }
      config.headers.common['Accept-Language'] = 'pt-BR';

      return config;
    },
    err => Promise.reject(err)
  );
};

/**
 * Verifica se o token já expirou
 *
 * @param {string} token
 * @return {boolean}
 */
const _isTokenExpired = token => {
  if (token) {
    const decodedToken = jwt.decode(token);
    return decodedToken && decodedToken.exp * 1000 < new Date().getTime();
  }
  return false;
};

/**
 * @param {string} URL
 * @param {any[]} params
 * @return Promise
 */
const get = (URL, params = null) => {
  return client.get(URL, params);
};

/**
 * @param {string} URL
 * @param {any[]} params
 * @return Promise
 */
const post = (URL, params, config = '') => {
  return client.post(URL, params, config);
};

/**
 * @param {string} URL
 * @param {any[]} params
 * @return Promise
 */
const remove = (URL, params) => {
  return client.delete(URL, params);
};

/**
 * @param {string} URL
 * @param {any[]} params
 * @return Promise
 */
const put = (URL, params) => {
  return client.put(URL, params);
};

const ApiServices = {
  setup,
  get,
  post,
  put,
  remove,
};

export default ApiServices;
