import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../helpers';


export function NavLinkCustom(props) {
    const intl = useIntl();
    const [hover, setHover] = useState(false);

    const style = {
        normal: {
            background: props.layoutProps.colors.primary,
        },
        hover: {
            background: props.layoutProps.colors.secondary
        }
    };

    return (
        <NavLink
            className="menu-link menu-toggle"
            to={props.page}
            onMouseEnter={() => {
                setHover(true);
            }}
            onMouseLeave={() => {
                setHover(false);
            }}
            style={{
                ...style.normal,
                ...(hover ? style.hover : null),
            }}
        >
            <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl(props.svg)} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: props.title })}</span>
            {props.children}
        </NavLink>
    )
}